import { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { Link, useActionData, useLocation, useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import ModalView from "../common/Modal/ModalView";
import { sendRewardEmail } from "../../Api/sendRewardEmail/sendRewardEmail";
import { cursortowait, cursortodefault } from "../../services/chatGPTService";
import ClaimRewardPopup from "../ClaimRewardPopup/ClaimRewardPopup";
import ClaimRewardSuccessPopup from "../ClaimRewardSuccessPopup/ClaimRewardSuccessPopup";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

function classaddforresponsive() {
  var elemcls = document.getElementById("clsaddresp");
  elemcls.classList.add("responsive-visible-menu");
}

export const Header = ({ HeaderName }) => {
  const [isModalPopOpen, setIsModalPopOpen] = useState(false);
  const [isSuccessModalPopOpen, setIsSuccessModalPopOpen] = useState(false);
  const navigate = useNavigate();
  const videoCount = localStorage.getItem('video_count')
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
const [path, setPath] = useState(false);

useEffect(() => {
  if (location.pathname === '/rerender') {
    setPath(true);
  } else {
    setPath(false);
  }
}, [location.pathname]);
  return (
    <>
      {user ? (
        <header>
          <div
            className="header-container"
            style={{ marginLeft: "257px", marginBottom: "30px" }}
          >
            {/* style={userData ? { marginLeft: "257px", marginBottom: "30px" } : {}} */}

            <div className="page-title">
              {/* <button type="button" className="btn btn-menu">
              <span className="material-symbols-outlined icon"> menu </span>
            </button> */}
              {/* <h2>Dashboard</h2> */}
              <button
                type="button"
                class="btn btn-menu"
                onClick={classaddforresponsive}
              >
                <span class="material-symbols-outlined icon"> menu </span>
              </button>
              <h2>{HeaderName}</h2>
            </div>

            <ul className="top-menu">
              <div className="yatter-nav-menu">
                <li className="!mr-0">
                  <a href="mailto:info@yatter.ai" target="_blank">
                    Contact us
                  </a>
                </li>
                <li className="!mr-2.5">
                  <a href="https://yatter.ai/privacy-policy/" target="_blank">
                    Our Policy
                  </a>
                </li>
              </div>
                  {/* { user?.claim_reward != "true"  &&   <li className="get-paid-wrap !mr-5">
                  <button type="button" style={{ display: "none" }} className="get-paid-btn" onClick={async() =>  {
                    if (videoCount >= 5 ){
                            user?.ID && (await mixpanel.identify(user?.ID));
                            await mixpanel.track("Reward claim clicked in header", {
                              Email: user?.data?.user_email,
                              "User Id": user?.data?.ID || "",
                              "User Type": user?.orders?.order_type,
                            });
                            setIsModalPopOpen(true)
                          }
                          }}>
                    <div className="get-paid-inner">
                      <img className="dollar-coin" src={require("../../images/dollar-coin.svg").default} alt="dollar" />
                      Get <span className="hideTitle">&nbsp;paid</span> <span className="font-bold ml-1">$5</span>
                    </div>
                  </button>
                  
                  <div className="custom-tooltip text-white">
                   {videoCount < 5 ? <p className="font-bold mb-2 f-14">Almost there!</p>
                   : <p className="font-bold mb-2 f-14">Get your reward!</p>
                        }
                    <p className="m-0 f-14">You've created <span className="font-bold">{videoCount || '0'}/5</span> Yatter videos</p>
                  </div>
                </li>
                        } */}
              {/* <li
                onClick={async () => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("video_id");
                  localStorage.removeItem("video_url");
                  localStorage.removeItem("video_count");
                  await mixpanel.reset();
                  navigate("/");
                }}
                style={{ cursor: "pointer", marginRight: "24px" }}
              >
                <Link to="/">Logout</Link>
              </li> */}
              <li className="logged-user">
                <Link to="/settings" className="user-name">
                  A
                </Link>
              </li>
            </ul>
          </div>
          <ClaimRewardPopup
      isOpenPop={isModalPopOpen}
      setIsOpenPop={setIsModalPopOpen}
      setIsSuccessModalPopOpen={setIsSuccessModalPopOpen}
      isSuccessModalPopOpen={isSuccessModalPopOpen}
      />
      <ClaimRewardSuccessPopup
      isOpenPop={isSuccessModalPopOpen}
      setIsOpenPop={setIsSuccessModalPopOpen}
      />
        </header>
      ) : (
        <header style={{ position: "initial" }}>
          <div className="headerCustom">
            <img
              src="images/logo.png"
              alt="Yatter"
              style={{ height: "60px", cursor: "pointer" }}
              onClick={() => navigate("/")}
            />

            <div className="topheadernonloggedin">
              <div className="nonloggedleft"><h3 className="mb-4">{path ? "Edit your script, images, and audio:" : "Making your professional video"}</h3></div>
              <div className="nonloggedrightbtn"><button type="button" class="btn btn-gradient" onClick={() => {
                    localStorage.removeItem("video_url");
                    navigate("/login");
                  }}>
                  Log in/Sign up</button></div>
            </div>



          {/*  <ul className="top-menu">
              
            
              <li className="signuptop" id="signuptopid">
                <Link
                  onClick={() => {
                    localStorage.removeItem("video_url");
                    navigate("/signup");
                  }}
                  to="/signup"
                >
                  Sign Up
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    localStorage.removeItem("video_url");
                    navigate("/login");
                  }}
                  to="/login"
                >
                  Log In
                </Link>
              </li>
                  
            </ul>
                    */}
            {/* <button
                onClick={() => {
                  localStorage.removeItem("user");
                  navigate("/signup");
                }}
                style={{ cursor: "pointer", marginRight: "24px" }}
              >
                Sign Up
              </button>

            <button
                onClick={() => {
                  localStorage.removeItem("user");
                  navigate("/login");
                }}
                style={{ cursor: "pointer", marginRight: "24px" }}
              >
                Log In
              </button> */}
          </div>
        </header>
      )}
    </>
  );
};
