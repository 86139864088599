import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import { cursortodefault, cursortowait } from "../../services/chatGPTService";
import { signup } from "../../Api/Auth/signup";
import { updateVideo } from "../../Api/updateVideo/updateVideo";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import { login } from "../../Api/Auth/login";
import { useGoogleLogin } from "@react-oauth/google";
import { googleAuth } from "../../Api/googleAuth/googleAuth";
import { loginWithGoogle } from "../../Api/googleAuth/loginWithGoogle";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const FreeTrialPopuplongtext = ({
  isOpenPop,
  setIsOpenPop,
  children,
  setIsFreeTrialSuccessPopupOpen,
  setUser,
  videoGenerating,
  //videoUrl,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const videoUrl = localStorage.getItem("created-video-url");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const downloadFile = () => {
    const downloadPath = `https://staging.yatter.io/webs/video/index.php?v=${videoUrl.substring(
      videoUrl.lastIndexOf("/") + 1
    )}`;
    window.open(downloadPath, "_blank");
    setIsOpenPop(false);
    // navigate("/");
    // setVideoDownloadPopup(false)
    // setIsFreeTrialPopupOpen(true)
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    // if (formData.username.trim() === "") {
    //   errors.username = "Email is required.";
    // }

    // if (formData.password.trim() === "") {
    //   errors.password = "Password is required.";
    // }
    // if (selectedOption.trim() === "") {
    //   errors.selectedOption = "Please select where you heard about us";
    // }
    // if (Object.keys(errors).length > 0) {
    //   setFormErrors(errors);
    //   return;
    // }
    try {
      // Call the signup function with the form data
      cursortowait();
      const response = await signup(formData);
      // Handle successful signup (you can redirect to login page or show a success message)
      console.log(
        "Signup successful:",
        response,
        "response?.data",
        response?.data
      );
      if (response?.data) {
        console.log("response?.data?.ID", response?.data?.ID);
        const userId = response?.data?.ID;
        const videoId = localStorage.getItem("video_id");
        //  setShowModal(true)
        // const data = await updateVideo(userId, videoId);
        // if (data == "successfully updated") {
        // navigate("/confirmation");
        /*let detailsu=response.data;
              navigate("/confirmation", { state: { detailsu } });*/
        userId && (await getUSerVideoCountHandle({ userid: userId }));
        const userResponse = await login(formData);
        console.log("userResponse", userResponse);
        if (userResponse?.data?.data) {
          localStorage.setItem("user_id", userResponse?.data?.ID);
          await getUSerVideoCountHandle({ userid: userResponse?.data?.ID });
          const userData = { ...userResponse.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          await mixpanel.identify(userData?.data?.ID);
          await mixpanel.people.set({
            $email: userData?.data?.user_email,
            $name: userData?.data?.user_email,
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Sign Up to get your video", {
            Email: userData?.data?.user_email,
            "Sign Up Platform": "Website Form",
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Free trial activated?", {
            Email: userData?.data?.user_email,
            "Free trial": "Yes",
          });
          localStorage.removeItem("video_id");
          localStorage.removeItem("video_url");
          setIsOpenPop(false);
          // setIsFreeTrialSuccessPopupOpen(true);
          navigate("/dashboard");
          // toast.success("Free trial activated");
          console.log("Login success");
          // window.location.reload();
        }
        // }
        // else {
        //   console.log(data);
        // }
        //navigate("/login");
      } else if (response?.message === "User already exists.") {
        // toast.error("User already exists.");
        const userResponse = await login(formData);
        console.log("userResponse", userResponse);
        if (userResponse?.data?.data) {
          localStorage.setItem("user_id", userResponse?.data?.ID);
          const userData = { ...userResponse.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          const userId = userData?.ID;
          const videoId = localStorage.getItem("video_id");
          // const data = await updateVideo(userId, videoId);
          // if (data == "successfully updated") {
          userId && (await getUSerVideoCountHandle({ userid: userId }));
          await mixpanel.identify(userData?.data?.ID);
          await mixpanel.people.set({
            $email: userData?.data?.user_email,
            $name: userData?.data?.user_email,
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Sign Up to get your video", {
            Email: userData?.data?.user_email,
            "Sign Up Platform": "Website Form",
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Free trial activated?", {
            Email: userData?.data?.user_email,
            "Free trial": "Yes",
          });
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          localStorage.removeItem("video_id");
          localStorage.removeItem("video_url");
          navigate("/dashboard");
          // toast.success("Free trial activated");
          setIsOpenPop(false);
          // setIsFreeTrialSuccessPopupOpen(true);
          // window.location.reload();
          // } else {
          //   console.log({ error: data });
          // }
        }
      }
    } catch (error) {
      toast.error(error);
    }
    cursortodefault();
  };
  const googleSignup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      cursortowait();
      console.log({ codeResponse });
      // if (selectedOption.trim() === "") {
      //   return toast.error("Please select where you heard about us");
      // }
      const data = await googleAuth(codeResponse);
      if (data && data?.email) {
        console.log({ profile: data });
        const body = {
          email: data?.email,
          name: data?.name,
          signuplast: "yes",
        };
        const response = await loginWithGoogle(body);
        // Handle successful signup (you can redirect to login page or show a success message)
        console.log(
          "Signup successful:",
          response,
          "response?.data",
          response?.data
        );
        if (response?.data) {
          console.log("response?.data?.ID", response?.data?.ID);
          const userId = response?.data?.ID;
          const videoId = localStorage.getItem("video_id");
          localStorage.setItem("user_id", userId);
          //  setShowModal(true)
          // const data = await updateVideo(userId, videoId);
          // if (data === "successfully updated") {
          userId && (await getUSerVideoCountHandle({ userid: userId }));
          console.log("userResponse", response);
          const userData = { ...response?.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          await mixpanel.identify(userData?.data?.ID);
          await mixpanel.people.set({
            $email: userData?.data?.user_email,
            $name: userData?.data?.user_email,
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Sign Up to get your video", {
            Email: userData?.data?.user_email,
            "Sign Up Platform": "Google",
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Free trial activated?", {
            Email: userData?.data?.user_email,
            "Free trial": "Yes",
          });
          localStorage.removeItem("video_id");
          localStorage.removeItem("video_url");
          navigate("/dashboard");
          // toast.success("Free trial activated");
          setIsOpenPop(false);
          // setIsFreeTrialSuccessPopupOpen(true);
          console.log("Login success");
          // window.location.reload();
          // } else {
          //   console.log(data);
          // }
        }
      }
      cursortodefault();
    },
    onError: (error) => toast.error(error),
  });

  //always keep it at end
  if (!isOpenPop) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
      style={{ zIndex: 2147483648, padding: "10px" }}
    >
      <div
        className="reserve-my-spot-instantly-popup custom-modal success-modal "
        // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
        style={{ backgroundColor: "white" }}
      >
        {/* {!videoGenerating && (
          <button
            type="button"
            class="btn-close-icon"
            onClick={() => {
              setIsOpenPop(false);
            }}
          >
            <span class="material-icons"> close </span>
          </button>
        )} */}
        <div className="">
          <div className="mb-6 text-center">
            <div className="mb-6">
              <img
                src={require("../../images/logo.png")}
                className="mx-auto"
                style={{ height: 60 }}
                alt="Yatter AI"
              />
            </div>
            <h1 className="mb-4">
            {/*  Your video is in your library.*/}
             Create longer AI videos.
              {/* Create longer videos instantly */}
              <br />
              Access your account:
            </h1>
            <p className="mb-0">
              <span className="font-bold">Don't have one?</span> Create one in
              less than 10 seconds:
            </p>
          </div>

          <div onClick={() => googleSignup()}>
            <button
              type="button"
              className="btn btn-black-ouline btn-text-icon w-full rounded-btn"
            >
              <img
                className="mr-2"
                src={require("../../images/google.svg").default}
                alt="Google"
              />{" "}
              Sign up with Google
            </button>
          </div>

          <div className="or-divider text-center">
            <span>OR</span>
          </div>

          <form className="mb-6" onSubmit={handleFormSubmit}>
            <div className="input-group">
              <input
                type="username"
                className="input-control"
                placeholder="Enter your email"
                name="username"
                value={formData?.username}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-group">
              <input
                type={passwordVisible ? "text" : "password"}
                className="input-control"
                placeholder="Enter your password"
                name="password"
                value={formData?.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="btn-icon show-pwd"
                onClick={togglePasswordVisibility}
              >
                <span className="material-icons text-gray f-20">
                  visibility
                </span>
              </button>
            </div>
            <div className="input-group text-center mb-0">
              <button type="submit" className="btn btn-gradient">
                Sign up now
              </button>
            </div>
          </form>

          {/* {videoUrl &&  (
            <div className="text-center">
              Download my video again.{" "}
              <Link
                // to={"/rerender"}
                className="text-blue underline"
                onClick={downloadFile}
              >
                Click here
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
export default FreeTrialPopuplongtext;
