import React from "react";
import Layout from "../../components/Layout/Layout";

const AffiliateProgramRegistered = () => {
  return (
    <Layout HeaderName="Affiliate Program">
      <div className="page-container">
        <div className="page-wrapper">
          <div className="card-wrap affiliate-program">
            <div className="details-wrap text-center">
              <div className="flex justify-center mb-4">
                <span className="premium-badge">
                  <img
                    className="premium-icon"
                    src="images/premium.svg"
                    alt="premium"
                  />{" "}
                  This Offer Is <b className="ml-2">Locked</b>
                </span>
              </div>

              <h1 className="mb-6 italic">
                <span className="text-red font-bold">Earn $10</span> on{" "}
                <span className="underline">EVERY</span> New User
              </h1>

              <div className="mb-6">
                <h3 className="mb-2 italic font-bold">Want To Join In?</h3>
                <h3 className="italic font-normal">
                  Our Exclusive Affiliate Program will let you earn{" "}
                  <span className="font-bold">Serious Money</span> with No EXTRA
                  work!
                </h3>
              </div>

              <div className="container-xs border p-6 rounded-xl mb-6 text-center">
                <img
                  className="mb-4 mx-auto"
                  src="images/check-gradient.svg"
                  alt="Check"
                />
                <h4>You've already applied to the Premium Affiliate Program</h4>
              </div>

              <div>
                <h3 className="font-bold text-gray mt-6">
                  *This Offer is ONLY Available to{" "}
                  <span className="gradient-text">Premium Members.</span>*
                </h3>
              </div>
            </div>

            <div className="theme-card">
              <h5 className="font-bold mb-4">
                The Affiliate Programs give you:
              </h5>
              <ul className="list-wrapper text-left">
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span>
                    <span className="f-14 font-normal">
                      Earn passive income using referrals
                    </span>
                  </span>
                </li>
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span>
                    <span className="f-14 font-normal">
                      Get an exclusive spot in our growing community
                    </span>
                  </span>
                </li>
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span>
                    <span className="f-14 font-normal">
                      Get early bird access to new features
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AffiliateProgramRegistered;
