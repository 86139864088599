import React, { useContext, useState } from "react";
import { VideoGenerationContext } from "../../VideoGenerationContext";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";

const ExpirePopup = ({ onPayment }) => {
  const { resetContext } = useContext(VideoGenerationContext);
  const navigate = useNavigate();

  return (
    <div class="custom-modal success-modal md">
      <div>
        <div class="text-center mb-6">
          <h1 class="mb-4">Your free trial has ended...</h1>
          <h4 class="font-medium mb-4">
            You have lost access to all your current and future videos.
          </h4>
        </div>
        <div class="text-center">
          <h4 class="font-medium mb-4">
            Regain instant access for only <strike>$14.99</strike>{" "}
            <span class="font-semibold">$9.99/month</span>
          </h4>
        </div>
        <div
          class="text-center mb-7"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            type="button"
            class="btn btn-gradient premium-btn"
            style={{ display: "flex", alignItems: "center", gap: "7px" }}
            onClick={onPayment}
          >
            <span class="icon">
              <img
                src="images/premium.svg"
                alt="Premium"
                style={{ width: 30, height: 30 }}
              />
            </span>
            Upgrade and gain access in 1 min
          </button>
        </div>
        <div class="p-4 bg-light-gradient mb-6 rounded-xl">
          <h5 class="f-18 mb-4">
            Premium offers the easiest way to make videos:
          </h5>
          <ul class="list-wrapper text-left">
            <li class="list-item">
              <span class="material-symbols-outlined check-icon">task_alt</span>{" "}
              <span>
                Make beautiful videos{" "}
                <span class="font-bold">without any watermarks</span>
              </span>
            </li>
            <li class="list-item">
              <span class="material-symbols-outlined check-icon">task_alt</span>{" "}
              <span>
                Access <span class="font-bold">unlimited AI images</span>
              </span>
            </li>
            <li class="list-item">
              <span class="material-symbols-outlined check-icon">task_alt</span>{" "}
              <span>
                Make quick videos{" "}
                <span class="font-bold">without years of experience</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="text-center f-14">
          Any questions? Send us an email at{" "}
          <a class="font-semibold" href="mailto:info@yatter.ai">
            info@yatter.ai
          </a>
        </div>

        {/* //new code */}
        <div className="flex justify-center mb-2">
          <button
            type="button"
            className="btn btn-black-ouline btn-text-icon btn-sm"
            onClick={async () => {
              // localStorage.removeItem("user");
              // localStorage.removeItem("video_id");
              // localStorage.removeItem("video_url");
              // localStorage.removeItem("video_count");
              // localStorage.removeItem("user_script");
              resetContext();
              localStorage.clear();
              Cookies.remove("scripttxt");
              await mixpanel.reset();
              navigate("/");
            }}
          >
            <span className="material-symbols-outlined mr-2,mt-11">
              {" "}
              logout{" "}
            </span>
            Logout
          </button>
        </div>
        {/* //end new code */}
      </div>
    </div>
  );
};

export default ExpirePopup;