import React, { useRef, useEffect } from "react";
import { sendRewardEmail } from "../../Api/sendRewardEmail/sendRewardEmail";
import { cursortodefault, cursortowait } from "../../services/chatGPTService";
import mixpanel from "mixpanel-browser";
import { getUser } from "../../Api/getUser/getUser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const ClaimRewardPopup = ({
  isOpenPop,
  setIsOpenPop,
  isSuccessModalPopOpen,
  setIsSuccessModalPopOpen,
}) => {
  const popupRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Clicked outside the popup, close the popup
        setIsOpenPop(false);
      }
    };

    if (isOpenPop) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpenPop, setIsOpenPop]);

  if (!isOpenPop) return null;

  const videoCount = localStorage.getItem("video_count");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    cursortowait();
    const email = event.target.elements.email.value;
    const res = await sendRewardEmail({ useremail: user?.data?.user_email, userid: user?.ID, paypalemail: email });
    if (res?.email === "Email sent successfully") {
      const singleUser = await getUser(user.ID);
      if(singleUser?.ID){
      console.log({singleUser})
      localStorage.setItem("user", JSON.stringify(singleUser));
      setIsOpenPop(false);
      user?.ID && (await mixpanel.identify(user?.ID));
      await mixpanel.track("Reward claimed", {
        Email: user?.data?.user_email,
        "User Id": user?.data?.ID || "",
        "User Type": user?.orders?.order_type,
      });
      setIsSuccessModalPopOpen(true);
    } else{
      console.log({error: singleUser})
    }
    } else {
      console.log({ error: res });
    }

    cursortodefault();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"  style={{ zIndex: 7 }}>
      <div
        ref={popupRef}
        className="reserve-my-spot-instantly-popup"
        style={{ backgroundColor: "white" }}
      >
        <div className="text-center">
          <img
            className="mx-auto mb-6"
            src={require("../../images/dollar-icon.svg").default}
            alt="dollar"
          />

          <div className="mb-6">
            <h1 className="mb-6 text-center">Claim Your Reward!</h1>
            <p className="f-18 mb-0">
              Insert your PayPal email. If you've created five yatter videos or more we'll send you $5 directly!
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="input-group text-left">
              <p className="mb-2">Your PayPal Email</p>
              <input
                type="email"
                name="email"
                className="input-control mb-2"
                placeholder="Enter here"
                required
              />
              <span className="text-gray flex items-center font-normal f-14">
                <span className="material-symbols-outlined f-18 mr-1"> help </span>
                Make sure your PayPal email is correct.
              </span>
            </div>

            <div>
              <button type="submit" className="btn btn-gradient mb-6">
                Submit
              </button>
              <br />
              <button
                type="button"
                className="text-gray"
                onClick={() => setIsOpenPop(false)}
              >
                Later
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClaimRewardPopup;
