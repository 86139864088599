
export default function ModalView({ isOpenPop, closePopupModal, children, increaseWidth }) {
    if (!isOpenPop) return null;
    const popupStyle = {
      backgroundColor: "white",
    };
    if (increaseWidth) {
      popupStyle.maxWidth = increaseWidth;
    }
    return (
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
        style={{ zIndex: 6, padding: "10px" }}
      >
        <div
          className="reserve-my-spot-instantly-popup"
          // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
          style={popupStyle}
        >
          {children}
        </div>
      </div>
    );
  }