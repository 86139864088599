import axios from "axios";
export const googleAuth = async (userData) => {
    try {
        console.log('User Data::', userData);
        const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userData.access_token}`, {
            headers: {
                Authorization: `Bearer ${userData.access_token}`,
                Accept: 'application/json'
            }
        });
        
        return response.data;
    } catch (error) {
        console.error(error);
        return null; 
    }
};