import axios from "axios";

export const getUSerVideos = async (userData) => {
    console.log("coming in api")
  try {
    const response = await axios.post(
      `https://staging.yatter.io/webs/get_customer_record.php`,
      userData
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
