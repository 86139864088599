import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function HandleRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extract the orderid from the current URL
        const pathname = location.pathname;
        const orderidMatch = pathname.match(/\/explore\/([^/]+)/);
        const orderid = orderidMatch ? orderidMatch[1] : null;

        if (orderid) {
            // Navigate to the explore page with the orderid
            // navigate(`/explore/${orderid}`, { replace: true });
            navigate(`/explore/orderId=/${orderid}`, { replace: true });
        } else {
            // If no orderid is found, navigate to a default route
            navigate('/explore', { replace: true });
        }
    }, [location, navigate]);

    return null; // Optionally, render a loading state or null
}

export default HandleRedirect;
