import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FreeTrialSuccessPopup = ({ isOpenPop, setIsOpenPop, children }) => {
  const navigate = useNavigate();
  const onClose = () => {
    setIsOpenPop(false);
    navigate("/dashboard");
    toast.success("Free trial activated");
  };
  //always keep it at end
  if (!isOpenPop) return null;
  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
      style={{ zIndex: 6 }}
    >
      <div
        className="reserve-my-spot-instantly-popup"
        // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
        style={{ backgroundColor: "white" }}
      >
        {/* <div class="custom-modal md custom-left"> */}
        <div>
          <div class="head text-center">
            <img
              className="logo mx-auto mb-4"
              src="images/check-gradient.svg"
              alt="Check"
            />
            <h2 class="text-red mb-2" style={{ textAlign: "center" }}>
              Thanks for your interest!
            </h2>
            <p className="font-medium">
              We're actually testing how many people are interested in signing
              up to the free trial!
            </p>
            <br />
            <p className="font-medium">
              As a gift, we want to give you unlimited access to Premium for the
              time being. And when we do start charging, you can enter your
              credit information if you want to keep using Premium.
            </p>{" "}
          </div>

          <div class="body">
            <div
              class="mb-0"
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button type="button" class="btn btn-gradient" onClick={onClose}>
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FreeTrialSuccessPopup;
