import React, { useContext, useState } from "react";
import Layout from "../../components/Layout/Layout";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { VideoGenerationContext } from "../../VideoGenerationContext";
import ModalView from "../../components/common/Modal/ModalView";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const Settings = () => {
  const navigate = useNavigate();
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setIsPasswordVisible(prevState => !prevState);
  // };
  const { resetContext } = useContext(VideoGenerationContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [userplantype, Setuserplantype] = useState(user?.orders?.order_type);
  console.log("user", user);
  const [showpopup, setShowpopup] = useState(false);
  const [showDiscontinue, setShowDiscontinue] = useState(false);

  const closePopModal = () => setShowpopup(false);
  const closeDiscontinuePopModal = () => setShowDiscontinue(false);

  const handleCancelSub = () => {
    setShowpopup(false)
   // window.location.href = `https://yatter.ai/wp-sso-cancel-subscription.php?key=${user?.data.user_email}&orderid=${user?.orders.order_id}`;
   window.location.href=`https://yatter.ai/wp-sso-cancel-subscription.php?key=ruhul7777@gmail.com&orderid=2281`;
    //setShowDiscontinue(true)
  }

  const handlePayment = () => {
    // window.location.href = 'https://yatter.ai/checkout/?add-to-cart=1284';
    window.location.href = `https://yatter.ai/wp-sso-payment.php?key=${user?.data.user_email}`;
  };

  return (
    <>
      <Layout HeaderName="Settings">

        <div className="page-container">
          <div className="page-wrapper">
            <div className="flex justify-end mb-6">
              <button
                type="button"
                className="btn btn-black-ouline btn-text-icon btn-sm"
                onClick={async () => {
                  // localStorage.removeItem("user");
                  // localStorage.removeItem("video_id");
                  // localStorage.removeItem("video_url");
                  // localStorage.removeItem("video_count");
                  // localStorage.removeItem("user_script");
                  resetContext();
                  localStorage.clear();
                  Cookies.remove("scripttxt");
                  await mixpanel.reset();
                  navigate("/");
                }}
              >
                <span className="material-symbols-outlined mr-2"> logout </span>
                Logout
              </button>
            </div>
            <div className="card-wrap pb-0">
              <div className="card-head">
                <h2>Your Personal Info</h2>
              </div>

              <div className="info-block-wrap three">
                <div className="info-block">
                  <label>Email</label>
                  <span className="value">{user?.data?.user_email}</span>
                </div>
                <div className="info-block">
                  <label>Mobile No.</label>
                  <span className="value">{user?.phone}</span>
                </div>
                <div className="info-block">
                  <label>Password</label>
                  <span className={`value flex item-center f-18`}>
                    {/* <span className={`value flex item-center f-18 ${isPasswordVisible ? 'visible-password' : ''}`}> */}
                    {/* {isPasswordVisible ? user?.data?.user_pass : '********'}{" "} */}
                    ********{" "}
                    {/* <span className="material-icons f-20 text-gray ml-2">
                      {" "}
                      visibility{" "}
                    </span>*/}{" "}
                  </span>
                </div>
              </div>
            </div>
            {userplantype != 'free' &&
              <div class="card-wrap mt-8" >
                <div class="card-head">
                  <h2>Your premium subscription:</h2>
                </div>

                <div class="flex justify-between">
                  <p class="flex flex-wrap items-center"><span class="mr-2">You've subscribed to</span> <span
                    class="flex items-center">
                    <img
                      src="images/premium.svg"
                      alt="Premium"
                      style={{
                        width: 22,
                        marginRight: 6
                      }}
                    // style="width: 22px;
                    //  margin-right: 6px;"
                    /> <span class="font-bold">Premium</span></span></p>
                  <button type="button" class="btn btn-black-ouline" onClick={() => setShowpopup(true)}>Cancel my subscription</button>
                </div>
              </div>

            }
          </div>
        </div>
        {/* {
          userplantype === 'free' &&
          <div>uoiddd</div>
        } */}
      </Layout>
      <ModalView isOpenPop={showpopup} closePopupModal={closePopModal}>
        {/* <div class="reserve-my-spot-instantly-popup"> */}
        <div class="text-center">
          <div class="mb-3">
            <span class="material-symbols-outlined f-60 text-red">help</span>
          </div>

          <div class="mb-6">
            <h1 class="mb-6 text-center">Are you sure?</h1>
            <p class="f-18 mb-0">You want to cancel your premium access...<br />
              <span class="font-bold">You will lose access to all current and future videos.</span></p>
          </div>

          <div>
            <button type="button" class="btn btn-gradient mb-5" onClick={() => setShowpopup(false)}>Keep all my AI videos</button>
            <br />
            <button type="button" class="btn btn-black-ouline" onClick={() => handleCancelSub()}>Yes, Discontinue</button>
          </div>

        </div>
        {/* </div> */}
      </ModalView>
      <ModalView isOpenPop={showDiscontinue} closePopupModal={closeDiscontinuePopModal}>

        <div class="custom-modal success-modal md">
          <div>
            <div class="text-center mb-6">
              <h1 class="mb-4">You've cancelled your Premium subscription</h1>
            </div>

            <div class="text-center">
              <h4 class="font-medium mb-4">Regain instant access for only <strike>$14.99</strike> <span class="font-semibold">$9.99/month</span></h4>
            </div>

            <div class="text-center mb-7" style={{ display: "flex", justifyContent: "center" }}>
              <button
                type="button"
                class="btn btn-gradient premium-btn"
                style={{ display: "flex", alignItems: "center" }}
                onClick={handlePayment}
              >
                <span class="icon"><img src="images/premium.svg" alt="Premium" /></span>
                Upgrade and gain access in 1 min
              </button>
            </div>

            <div class="p-4 bg-light-gradient mb-6 rounded-xl">
              <h5 class="f-18 mb-4">Premium offers the easiest way to make videos:</h5>
              <ul class="list-wrapper text-left">
                <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Make beautiful videos <span class="font-bold">without any watermarks</span></span></li>
                <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Access <span class="font-bold">unlimited AI images</span></span></li>
                <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Make quick videos <span class="font-bold">without years of experience</span></span></li>
              </ul>
            </div>

            <div class="text-center f-14">
              Any questions? Send us an email at <a class="font-semibold" href="mailto:info@yatter.ai">info@yatter.ai</a>
            </div>
          </div>
        </div>
      </ModalView>
    </>
  );
};

export default Settings;
