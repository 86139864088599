import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import "./App.css";
import "./css/custom.css";
import { Route, Routes, useLocation } from "react-router-dom";
import GetScript from "./pages/GetScript/getscript";
import GetImages from "./pages/GetImages/GetImages";
import GetVideo from "./pages/GetVideo/GetVideo";
import GetAudio from "./pages/GetAudio/GetAudio";
import Ready from "./pages/Ready/ready";
import Dashboard from "./pages/Dashboard/Dashboard";
import AffiliateProgram from "./pages/AffiliateProgram/AffiliateProgram";
import PricingPlan from "./pages/PricingPlan/PricingPlan";
import FreePlan from "./pages/FreePlan/FreePlan";
import Settings from "./pages/Settings/Settings";
import AffiliateProgramRegistered from "./pages/AffiliateProgramRegistered/AffiliateProgramRegistered ";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import PrivateRoutes from "./utils/PrivateRoutes";
import ForgotPassword from "./pages/ForgetPassword/ForgotPassword";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Confirmation from "./pages/Confirmation/Confirmation";
import ReadyVideoPopup from "./components/ReadyVideoPopup/ReadyVideoPopup";
import ViralVideos from "./pages/ViralVideos/ViralVideos";
import Validateemail from "./pages/Validateemail/Validateemail";
import Rerender from "./pages/Rerender/Rerender";
import { getUserVideoCount } from "./Api/getUserVideoCount/getUserVideoCount";
import { getUSerVideoCountHandle } from "./Helpers/getUSerVideoCountHandle";
import BusinessInfo from "./pages/BusinessInfo/BusinessInfo";
import VideoLandingPage from "./pages/VideoLandingPage/OldVideoLandingPage";
import NewVideoLandingPage from "./pages/VideoLandingPage/NewVideoLandingPage";
import ShortVideoLandingPage from "./pages/VideoLandingPage/ShortVideoLandingPage";
import { VideoGenerationProvider } from "./VideoGenerationProvider ";
import Explore from "./pages/Explore/Explore";
import GetNewImages from "./pages/GetNewImages/GetNewImages";
import HandleRedirect from "./pages/HandleRedirect/HandleRedirect";
import UploadImage from "./pages/UploadImages/UploadImage";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
/*
function App() {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [generatedEssay, setGeneratedEssay] = useState('');
  const handleFormSubmit = async (formData) => {
    setLoading(true); // Start loading
    try {
      // const response = await generateEssay(formData);
      const response = await generateImage(formData);
      // const response = await maskImage(formData);
      // return console.log('resp', response);
      //console.log("in app");
      //console.log(response);
      if (response) {
        setLoading(false); // Start loading
        return setImage(response)
       } else {
        return console.log('error');
      }
    } catch (error) {
      console.error('Error handling form submission:', error);
    }
  };

  return (

    <Router>
      <Switch>
        <Route path="/screen1" component={Screen1} />
        <Route path="/screen2" component={Screen2} />
      </Switch>
    </Router>   
  );
}
*/
function App() {
  // const data = { message: 'Hello from Screen 1!' };
  //const [AudioSrc, setAudioSrc] = useState('');

  /*const handleFormSubmit1 = async (formData) => {
     const dataa= await generateVoice (formData);
     if(dataa)
     {
    // console.log(dataa);
     const audioBlob = dataa;
     const blob = new Blob([dataa], { type: 'text/plain' });
     const audioUrl = URL.createObjectURL(blob);
     //setAudioSrc(audioUrl);
     console.log('- audio url -');
     console.log(audioUrl);
     return audioUrl;
     }
  }*/
  //handleFormSubmit1('in it too');
  //const audiores=GetAudiofromWebService('here you got');
  // setAudioSrc(GetAudiofromWebService('here you got'));
  const [userplantype, Setuserplantype] = useState("");
  const history = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (user) {
      Setuserplantype(user?.orders?.order_type);
    }
    // const singleUser = async () => {
    //   try {
    //     const user = await getUser(userId.ID);
    //     Setuserplantype(user?.orders?.order_type);
    //   } catch (error) {
    //     toast.error(error)
    //   }
    // };
    // singleUser();
  }, []);
  console.log({
    page: window.location.pathname,
    search: window.location.search,
  });
  useEffect(() => {
    const trackPath = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const utmSource = searchParams.get("utm_source");
      await mixpanel.track("View Page", {
        "Page Name": window.location.pathname,
        "UTM Source": utmSource,
      });
    };
    trackPath();
  }, [history]);

  useEffect(() => {
    user && getUSerVideoCountHandle({ userid: user?.ID });
  }, [user]);
  return (
    <div>
      <Routes>
        {/* Protection */}
        <Route element={<PrivateRoutes />}>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route
            path="/affiliate-program"
            element={
              userplantype === "free" ? (
                // <AffiliateProgram />
                <AffiliateProgramRegistered />
              ) : (
                <AffiliateProgramRegistered />
              )
            }
          />
          <Route
            path="/affiliate-program-registered"
            element={<AffiliateProgramRegistered />}
          />
          <Route path="/pricing-plan" element={<PricingPlan />} />
          <Route path="/free-plan" element={<FreePlan />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/viral-videos" element={<ViralVideos />} />
        </Route>
        {/* Public */}
        <Route path="/get-script" element={<GetScript />} />
        <Route path="/get-images" element={<GetImages />} />
        <Route path="/get-new-images" element={<GetNewImages />} />
        <Route path="/get-audio" element={<GetAudio />} />
        <Route path="/get-video" element={<GetVideo />} />
        <Route path="/ready" element={<Ready />} />
        <Route path="/" element={<GetScript />} />
        <Route path="/#birthday" element={<GetScript />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/validateemail" element={<Validateemail />} />
        <Route path="/rerender" element={<Rerender />} />
        <Route path="/business-info" element={<BusinessInfo />} />
        {/* <Route path="/video-landing-page"     element={<VideoLandingPage/>} /> */}
        <Route path="/video-landing-page" element={<NewVideoLandingPage />} />
        <Route
          path="/video-landing-page-short"
          element={<ShortVideoLandingPage />}
        />
        <Route
          path="/dashboard"
          element={
            // <VideoGenerationProvider>
            <Dashboard />
            // </VideoGenerationProvider>
          }
        />
        {/* <Route
          path="/get-new-images"
          element={
            <GetNewImages />
          }
        /> */}
        <Route
          path="/uploadImages"
          element={
            <UploadImage />
          }
        />
        <Route
          path="/explore"
          element={
            <Explore />
          }
        />
        {/* <Route path="/explore/:orderId" element={<Explore />} /> */}
        <Route path="/explore/orderId=/:orderid" element={<Explore />} />
        <Route path="*" element={<HandleRedirect />} />

      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
