import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FreeTrialPopup from "../../components/FreeTrialPopup/FreeTrialPopup";
import FreeTrialSuccessPopup from "../../components/FreeTrialSuccessPopup/FreeTrialSuccessPopup";

const ShortVideoLandingPage = () => {
  const location = useLocation();
  const MobileVideo = JSON.parse(localStorage.getItem("mobileImg"));
  const user = JSON.parse(localStorage.getItem("user"));
  // const videoUrl =     location?.state?.forreadyvideo || localStorage.getItem('created-video-url')
  const videoUrl = localStorage.getItem("created-video-url");
  const navigate = useNavigate();
  const [isFreeTrialPopupOpen, setIsFreeTrialPopupOpen] = useState(false);
  const [expandedBlocks, setExpandedBlocks] = useState({});
  const [isFreeTrialSuccessPopupOpen, setIsFreeTrialSuccessPopupOpen] =
    useState(false);

  const toggleBlock = (index) => {
    setExpandedBlocks((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const faqData = [
    {
      question: "What if I’m not satisfied?",
      answer: [
        "Out of thousands of active subscriptions, less than 0.1% were not happy.",
        'That being said, if you want to cancel, you can do so directly from your account. To cancel your subscription, simply click "Downgrade".',
      ],
    },
    {
      question: "Is this the fastest way to make videos?",
      answer: [
        "Thousands of active customers fell in love with Yatter because of how easy it is to make videos about anything.",
        "From our side, we have never heard of a faster tool than Yatter. Most video tools out there require some editing skills to fully use them. Yatter does not.",
      ],
    },
    {
      question: "I’m sold, where can I unlock Premium now!",
      answer: [
        "That’s great! All you need to do is go to our Pricing page and upgrade immediately. We always have a solid 30-day guarantee to make sure you are 100% confident.",
      ],
    },
    {
      question: "Is this for me?",
      answer: [
        "1. Are you a beginner with video making/editing?",
        "2. Do you want to edit for hours on end? Or just have a tool help you and save hours of your day?",
        "3. Do you want to do research and find all your footage manually? Or just have a tool help you directly?",
        "Simply put, if you want to make quick videos, without burning your day away editing - then Yatter is perfect for you.",
      ],
    },
    {
      question: "Is it true that you offer over $2000 of value with Premium?",
      answer: [
        "Yes! When you upgrade to Premium, we have 3 benefits that you unlock instantly.",
        "Simply put, if you purchase these individually they can cost upwards of $2000!",
      ],
    },
    {
      question: "What is your 30-money back guarantee?",
      answer: [
        "Our guarantee is super simple and let’s you purchase with confidence.",
        "Meaning if you are not 100% satisfied within the first 30 days of upgrading, you can downgrade and get all your money back immediately.*",
        "*This only applies to the yearly plan",
      ],
    },
  ];

  const handletobottomClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const downloadFile = () => {
    const downloadPath = `https://staging.yatter.io/webs/video/index.php?v=${videoUrl.substring(
      videoUrl.lastIndexOf("/") + 1
    )}`;
    window.open(downloadPath, "_blank");
  };

  const handleFreeTrialPopup = () => {
    !user && setIsFreeTrialPopupOpen(true);
  };

  return (
    <div className="downloaded-video-page">
      <div>
        <div className="header-container custom-header-container">
          <div className="mail-link">
            <a href="mailto:info@yatter.io">
              <span className="material-symbols-outlined f-24 mr-2">mail</span>
              info@yatter.io
            </a>
          </div>

          <img
            className="brand-logo cursor-pointer"
            src={require("../../images/logo.png")}
            alt="Yatter"
            onClick={() => navigate("/")}
          />

          <span className="openai-tag theme">
            Powered by{" "}
            <img
              className="ml-2"
              src={require("../../images/openai.svg").default}
              alt="OpenAI"
            />
          </span>
        </div>
      </div>

      <div className="section-wrap-lg overflow-hidden">
        <img
          className="shape-triangle-1"
          src={require("../../images/shape-triangle.svg").default}
          alt="shape"
        />
        <img
          className="shape-triangle-2"
          src={require("../../images/shape-triangle-2.svg").default}
          alt="shape"
        />
        <img
          className="shape-circle"
          src={require("../../images/shape-circle.svg").default}
          alt="shape"
        />
        <img
          className="shape-circle-lg"
          src={require("../../images/shape-circle-lg.svg").default}
          alt="shape"
        />

        <div className="container-xxl">
          <div className="flex justify-center mb-4">
            <span className="badge-theme lg">
              <p>
                <span className="font-bold">Pro tip:</span> Make sure you keep
                this tool next time you need{" "}
                <span className="font-bold">a video under 5 mins</span>
              </p>
            </span>
          </div>

          <div className="section-title xxl">
            <h1 className="title-text">
              <span className="text-red">At this point, 80%</span> of users
              start using the{" "}
              <span className="underline">14-Day FREE TRIAL</span>
            </h1>
          </div>
        </div>

        <div className="container-xxl">
          <div className="video-ready-block">
            <div className="video-view-wrap left-block">
              <div className="text-center">
                <div
                  className="video-view video-play-btn"
                  style={{ width: MobileVideo == 0 ? "100%" : "220px" }}
                >
                  <video
                    controls
                    style={{ width: MobileVideo == 0 ? "100%" : "" }}
                  >
                    <source src={videoUrl} type="video/mp4" />
                  </video>
                </div>

                <div className="flex justify-center items-center">
                  <button type="button" className="btn btn-gray btn-text-icon">
                    <span className="material-symbols-outlined mr-2">
                      {" "}
                      download{" "}
                    </span>
                    Download Now
                  </button>
                </div>

                {/* <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-gradient premium-btn"
                  >
                    <span className="icon">
                      <img
                        src={require("../../images/premium.svg").default}
                        alt="Premium"
                      />
                    </span>
                    Edit now
                  </button>
                </div> */}
              </div>
            </div>

            <div className="right-block text-center bg-light-gradient box-shadow-1">
              <h1 className="mb-4 font-bold">
                Creating & editing videos is a huge pain. Keep using this tool:
              </h1>
              <h3 className="text-red mb-4">
                You're 1 step away from unlocking:
              </h3>

              <ul className="list-wrapper text-left mb-6">
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span className="font-semibold">
                    Longer videos with{" "}
                    <span className="font-bold">3+ million</span> stock images
                  </span>
                </li>
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span className="font-bold">
                    A library of 10+ human-like voices and 15+ high quality
                    songs
                  </span>
                </li>
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span className="font-semibold">
                    <span className="font-bold">Removing the watermark</span>{" "}
                    and adding your own personal touch
                  </span>
                </li>
                <li className="list-item">
                  <span className="material-symbols-outlined check-icon">
                    task_alt
                  </span>{" "}
                  <span className="font-semibold">
                    Editing all your videos{" "}
                    <span className="font-bold">with 1 click</span>
                  </span>
                </li>
                <li className="list-item">
                  <span>... and 10+ features!</span>
                </li>
              </ul>

              <div className="text-center" onClick={handleFreeTrialPopup}>
                <button
                  type="button"
                  className="btn btn-gradient btn-xxl w-full mb-6"
                >
                  Enable Your Free Trial Instantly
                  <br />
                  <span className="f-16 font-regular">
                    Then Only <strike>$9,99</strike> $5.99/month
                  </span>
                </button>

                <div>
                  <span className="f-14 font-bold">
                    <span className="material-icons f-20 text-orange align-middle mr-1">
                      verified_user
                    </span>{" "}
                    <span className="uppercase underline">100% risk free.</span>{" "}
                    All risk is on our side.
                  </span>
                </div>
              </div>

              <div className="text-center">
                <img
                  className="mx-auto height-60"
                  src={require("../../images/certificates-logo.png")}
                  alt="Certificate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-wrap-lg !pt-0">
        <div className="container-xxl">
          <div className="section-title xxl">
            <h1 className="title-text">
              <span className="text-red">Geeky</span> Details
            </h1>
          </div>

          <div className="geeky-section">
            <div className="geeky-feedback">
              <div className="feature-feedback">
                <div className="flex items-center mb-4">
                  <img
                    className="mr-3"
                    src={require("../../images/bg-music-icon.svg").default}
                    alt="BG music"
                  />
                  <div>
                    <h2>15+</h2>
                    <h4 className="font-medium">
                      High quality background music
                    </h4>
                  </div>
                </div>
                <div className="advice-points">
                  <p className="mb-4">
                    "Most tools have so many songs it becomes impossible to
                    choose. Yatter is super simple for non-professionals like
                    myself"
                  </p>
                  <div className="flex items-center">
                    <img
                      className="mr-3"
                      src={require("../../images/geeky-user-1.png")}
                      alt="user"
                    />
                    <div>
                      <h5>
                        Katie
                        <br />{" "}
                        <span className="italic font-medium">35 yrs old</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-feedback">
                <div className="flex items-center mb-4">
                  <img
                    className="mr-3"
                    src={require("../../images/ai-voice-icon.svg").default}
                    alt="BG music"
                  />
                  <div>
                    <h2>10+</h2>
                    <h4 className="font-medium">High quality AI voices</h4>
                  </div>
                </div>
                <div className="advice-points">
                  <p className="mb-4">
                    "Sometimes, I just need a quick voice that actually sounds
                    like a human. Yatter’s AI voices are amazing!"
                  </p>
                  <div className="flex items-center">
                    <img
                      className="mr-3"
                      src={require("../../images/geeky-user-2.png")}
                      alt="user"
                    />
                    <div>
                      <h5>
                        Matt
                        <br />{" "}
                        <span className="italic font-medium">44 yrs old</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* geeky-feedback */}

            <div className="geeky-img">
              <img
                src={require("../../images/video-in-device.png")}
                alt="video in device"
              />
            </div>

            <div className="geeky-feedback">
              <div className="feature-feedback">
                <div className="flex items-center mb-4">
                  <img
                    className="mr-3"
                    src={require("../../images/watermark-icon.svg").default}
                    alt="BG music"
                  />
                  <div>
                    <h2>No more watermark</h2>
                    <h4 className="font-medium">
                    Add your personal touch
                    </h4>
                  </div>
                </div>
                <div className="advice-points">
                  <p className="mb-4">
                  “I don’t know about others, but if I’m creating a video - I need a professional one - without any watermark! 
                  </p>
                  <div className="flex items-center">
                    <img
                      className="mr-3"
                      src={require("../../images/geeky-user-3.png")}
                      alt="user"
                    />
                    <div>
                      <h5>
                        Ava
                        <br />{" "}
                        <span className="italic font-medium">29 yrs old</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-feedback">
                <div className="flex items-center mb-4">
                  <img
                    className="mr-3"
                    src={require("../../images/stock-footage-icon.svg").default}
                    alt="BG music"
                  />
                  <div>
                    <h2>3+ Million</h2>
                    <h4 className="font-medium">Stock footage and images</h4>
                  </div>
                </div>
                <div className="advice-points">
                  <p className="mb-4">
                  “We actually received 4x more leads by using stock images and videos from Yatter. Yatter’s library is pretty damn cool!” 
                  </p>
                  <div className="flex items-center">
                    <img
                      className="mr-3"
                      src={require("../../images/geeky-user-4.png")}
                      alt="user"
                    />
                    <div>
                      <h5>
                        Tyler
                        <br />{" "}
                        <span className="italic font-medium">40 yrs old</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* geeky-feedback */}
          </div>
          {/* geeky-section */}
        </div>
      </div>

      <div className="section-wrap-lg top-head-section" id="top-feature">
        <div className="head-video">
          <img
            src={require("../../images/section-head-img-1.jpg")}
            alt="Image"
          />
        </div>

        <div className="container-xxl">
          <div className="top-head-title">
            <div className="section-title xxl">
              <h1 className="title-text">
                <span>
                  Upgrade now or unlock a full{" "}
                  <span className="text-red">14-day free trial</span>
                </span>
              </h1>
              <h5 className="sub-title" style={{ fontWeight: "700" }}>
                Either way, you'll get{" "}
                <span className="text-red">
                  over $3000+ worth of benefits instantly!
                </span>
              </h5>
            </div>
          </div>
        </div>

        <div className="container-xxl">
          <div className="card-wrap">
            <div className="section-title lg">
              <h1 className="title-text">
                Next time you need a video, make sure you have this tool ready:
              </h1>
            </div>

            <div className="plans-btn-wrap">
              <Link className="plans-btn-block" onClick={handleFreeTrialPopup}>
                <span className="most-popular-tag">
                  <span className="material-symbols-outlined f-20 mr-1">
                    {" "}
                    star_border{" "}
                  </span>
                  <span className="font-medium f-14">Most Popular</span>
                </span>

                <span className="text-tag bottom">
                  <span className="f-14 font-regular bg-white px-2">
                    Then Only <strike>$9,99</strike>{" "}
                    <span className="font-extrabold text-red">$5.99/month</span>
                  </span>
                </span>

                <img
                  src={require("../../images/free-trial-icon.svg").default}
                  alt="Free Trial"
                />
                <h4>Enable my 14 day trial</h4>
                <span className="material-icons"> east </span>
              </Link>
              {/* plans-btn-block */}

              <Link className="plans-btn-block bg-light-gradient">
                <span className="text-tag top">
                  <span className="f-14 font-regular bg-white px-2">
                    <span className="font-bold text-red">Coming Soon</span>
                  </span>
                </span>

                <img
                  src={require("../../images/premium-circle.svg").default}
                  alt="premium"
                />
                <h4>Upgrade to Premium</h4>
                {/* <span className="material-icons"> east </span> */}
              </Link>
              {/* plans-btn-block */}
            </div>
            {/* upgrade-btn-wrap*/}

            <div className="text-center mt-5">
              <img
                className="mx-auto height-60"
                src={require("../../images/certificates-logo.png")}
                alt="Certificate"
              />
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="footer-wrap">
          <div className="left">
            <p>© 2024 Yatter. All rights reserved</p>
          </div>
        </div>
      </footer>
      <FreeTrialPopup
        isOpenPop={isFreeTrialPopupOpen}
        setIsOpenPop={setIsFreeTrialPopupOpen}
        setIsFreeTrialSuccessPopupOpen={setIsFreeTrialSuccessPopupOpen}
      />
      <FreeTrialSuccessPopup
        isOpenPop={isFreeTrialSuccessPopupOpen}
        setIsOpenPop={setIsFreeTrialSuccessPopupOpen}
      />
    </div>
  );
};

export default ShortVideoLandingPage;
