import axios from "axios";
import Cookies from "js-cookie";
const apiKey = process.env.REACT_APP_API_KEY;
// const apiUrl = 'https://api.openai.com/v1/chat/completions';
// const apiUrl = 'https://api.openai.com/v1/dalle-generate'; //error
const apiUrl = "https://api.openai.com/v1/images/generations";
// const apiUrl = 'https://api.openai.com/v1/images/edits';
const apiUrlStb = "https://staging.yatter.io/webs/getstabilityimageapi.php";
const apiUrlSingle =
  "https://staging.yatter.io/webs/getstabilityimagesingle.php";
const apivURL = "https://api.openai.com/v1/audio/speech";
//const [audioSrc, setAudioSrc] = useState('1');
const promises = [];
const resdata = [];
const genImage = [];

const resType = true;
// const { Configuration, OpenAIApi } = require("openai");
const OpenAI = require("openai");
// console.log({ apiKey });
// const configuration = new Configuration({
//     apiKey: process.env.REACT_APP_OPENAI_API_KEY,
//   });
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_API_KEY,
  dangerouslyAllowBrowser: true,
});
// const openai = new OpenAI(apiKey);
// const openai = new OpenAIApi(Configuration);

export const generateImageSingle = async (formData) => {
  resdata.length = 0;
  promises.length = 0;
  // console.log(formData.question);
  try {
    // const mergerequest = formData.question + " it's about " + formData.qabout + " Do not include any text inside the images";
    const mergerequest = formData.question + "";
    console.log("mergerequest", formData);
    if (resType) {
      for (let i = 0; i < 1; i++) {
        promises.push(
          axios.post(apiUrlSingle + "?t=" + mergerequest+ "&mobileimage=" + localStorage.getItem("mobileImg"), {
            textscript: mergerequest,
            mobileimage: localStorage.getItem("mobileImg")
          })
        );
      } // for end
    } else {
      for (let i = 0; i < 1; i++) {
        promises.push({
          data: {
            created: 1707660487,
            data: [
              {
                url: "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png",
              },
            ],
          },
        });
      }
    }

    const responses = await Promise.all(promises);
    for (let i = 0; i < responses.length; i++) {
      const resdddata = responses[i].data;
      for (let j = 0; j < resdddata.length; j++) {
        resdata.push(resdddata[j].data[0]);
      }
    }
    //   Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
    //  Cookies.set('scripttxt', formData.question);
    return resdata;
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }
};

export const generateImagedStb = async (formData) => {
  resdata.length = 0;
  promises.length = 0;
  // console.log(formData.question);
  try {
    let mergerequest = formData.question;
    console.log("mergerequestmergerequest", mergerequest);
    // mergerequest =     await splitScriptIntoSections(mergerequest)
    // const mergerequest = formData.question + " it's about " + formData.qabout + " Do not include any text inside the images";
    // const mergerequest = formData.question + "";
    // console.log("mergerequest", formData, apiKey);
    if (resType) {
      for (let i = 0; i < 1; i++) {
        promises.push(
          //   axios.post(apiUrlStb + "?t=" + mergerequest, {
          axios.post(apiUrlStb + "?t=", {
            textscript: mergerequest,
            mobileimage: localStorage.getItem("mobileImg")
          })
        );
      } // for end
    } else {
      for (let i = 0; i < 1; i++) {
        promises.push({
          data: {
            created: 1707660487,
            data: [
              {
                url: "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png",
              },
            ],
          },
        });
      }
    }

    const responses = await Promise.all(promises);
    // console.log("responses in chatgpt", responses)
    for (let i = 0; i < responses.length; i++) {
      const resdddata = responses[i].data;
      for (let j = 0; j < resdddata.length; j++) {
        resdata.push(resdddata[j].data[0]);
        console.log(resdddata[j].data[0].vidtext);
        console.log("dta");
        Cookies.set('scripttxt', resdddata[j].data[0].vidtext, { path: '/', domain: '.yatter.io' });
        Cookies.set("scripttxt", resdddata[j].data[0].vidtext);
      }
    }
    
    //Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
    // Cookies.set("scripttxt", formData.question);
    console.log("resdata in chatgpt", resdata)
    return resdata;
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }
};

export const splitScriptIntoSections = async (formData) => {
  const sections = [];
  const maxTokens = 256; // Max tokens for each request
  const parentScript = formData.question;

  const prompt = `Can you divide this text into five sections. text = ${parentScript}, do not make separate headings for each sections just write 5 lines and do not give serial number or anything else just write 5 lines.`;

  try {
    // const sections = await splitScriptIntoSections(parentScript);
    // console.log("sections", sections);
    const response = await openai.completions.create({
      model: "gpt-3.5-turbo-instruct",
      //   engine: "text-davinci-003",
      prompt: prompt,
      max_tokens: maxTokens,
      n: 1,
    });
    console.log("response", response);
    if (response && response?.choices) {
      //   response.data.choices.forEach((choice) => {
      //     sections.push(choice.text.trim());
      //   });
      let mergerequest = response?.choices[0].text
      .split("\n")
      .filter((line) => line.trim() !== "")
      .map((line) => line.replace(/^\d+\.\s*/, ""));
    mergerequest = mergerequest?.map((line) => {
      const parts = line.split(":");
      return parts.length > 1 ? parts[1].trim() : line.trim();
    });
    console.log("mergerequestmergerequest", mergerequest);
      return mergerequest;
    }
  } catch (error) {
    console.error("Error splitting script:", error);
    return null;
  }
};

export const generateImaged3 = async (formData) => {
  resdata.length = 0;
  promises.length = 0;
  // console.log(formData.question);
  try {
    // const mergerequest = formData.question + " it's about " + formData.qabout + " Do not include any text inside the images";
    const mergerequest =
      formData.question + " Do not include any text inside the images";
    // console.log("mergerequest", formData, apiKey);
    if (resType) {
      for (let i = 0; i < 1; i++) {
        promises.push(
          axios.post(
            apiUrl,
            {
              model: "dall-e-2",
              prompt: mergerequest,
              size: "1024x1024",
              n: 1,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
              },
            }
          )
        );
      } // for end
    } else {
      // resdata.length = 0;
      // const promises = [];
      for (let i = 0; i < 3; i++) {
        promises.push(
          /** rr :: start **/
          {
            data: {
              created: 1707660487,
              data: [
                {
                  url: "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png",
                },
              ],
            },
          }
          /** rr :: end **/
          /** rr :: start remove**/
          /*axios.post(
                        apiUrl,
                        {
                            model: 'dall-e-2',
                            prompt: mergerequest,
                            size: "512x512",
                            n: 1
        
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${apiKey}`
                            },
                        }
                    )*/
          /** rr :: end remove**/
        );
      }
    }

    const responses = await Promise.all(promises);
    //const data = await Promise.all(responses.map(res => res.json()));

    // console.log('results are ');
    // console.log(responses);

    for (let i = 0; i < responses.length; i++) {
      resdata.push(responses[i].data.data[0]);
    }

    //console.log('res  are ');
    //console.log(resdata);
    Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
     Cookies.set("scripttxt", formData.question);
    return resdata;

    /* Promise.all(promises)
             .then(response => {
                 console.log('results are ');
             console.log(response);
             return response;
             })
             .catch(error => {
                 console.error('Error generating image:', error);
                 return null;
         });*/
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }

  /*
    try {
        const response = await axios.post(
            apiUrl,
            {
                model: 'dall-e-3',
                prompt: formData.question,
                size: "1024x1024",
                n: 1

            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
            }
        );
    //console.log(response);
        if (response?.data) {

           // return response?.data?.data?.[0]?.url
           Cookies.set('scripttxt', formData.question);
           return response?.data?.data
        }


    } catch (error) {
        console.error('Error generating image:', error);
        return null;
    }*/
};

export const generateImage = async (formData) => {
  // console.log(formData);
  resdata.length = 0;
  genImage.length = 0;
  try {
    const mergerequest =
      formData.question + " Do not include any text inside the images";
    // const mergerequest = formData.question + " it's about " + formData.qabout + " Do not include any text inside the images";
    if (resType) {
      const response = await axios.post(
        apiUrl,
        {
          model: "dall-e-2",
          prompt: mergerequest,
          size: "1024x1024",
          n: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      //console.log(response);
      if (response?.data) {
        // return response?.data?.data?.[0]?.url
        // Cookies.set('scripttxt', formData.question);
        // Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
        return response?.data?.data;
      }
    } else {
      // const response = '';
      genImage.push(
        {
          // "data": {
          created: 1707660487,
          data: [
            {
              url: "https://img.freepik.com/free-photo/luxurious-car-parked-highway-with-illuminated-headlight-sunset_181624-60607.jpg",
              // "url": "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png"
              // "url": "https://yatter.ai/wp-content/uploads/2024/02/Official-Yatter-Logo.png"
            },
          ],
        }
        // }
      );
      const respons = await Promise.all(genImage);
      console.log("eeeeeee", respons[0]?.data[0]);
      // for (let i = 0; i < response.length; i++) {

      resdata.push(respons[0]?.data[0]);
      console.log("sanpo", formData);

      // Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });

      // Cookies.set('scripttxt', formData.question);
      return resdata;
      // if (response?.data) {

      //     // return response?.data?.data?.[0]?.url
      //     Cookies.set('scripttxt', formData.question);
      //     return response?.data?.data
      // }
    }
    // //console.log(response);
    // if (response?.data) {

    //     // return response?.data?.data?.[0]?.url
    //     Cookies.set('scripttxt', formData.question);
    //     return response?.data?.data
    // }

    console.log("res  are ", resdata);
  } catch (error) {
    console.error("Error generating image:", error);
  }
  return null;
};

export const generateVoice = async (formData) => {
  // console.log(formData.question);

  try {
    const response = await axios.post(
      apivURL,
      {
        model: "tts-1",
        //  prompt: formData.question,
        input: "Do it now and again",
        voice: "onyx",
        speed: 1,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    // console.log(response.data);
    if (response?.data) {
      // return response?.data?.data?.[0]?.url
      /* const audioBlob = response.data;
             const audioUrl = URL.createObjectURL(audioBlob);*/
      //setAudioSrc(audioUrl);
      return response?.data;
    }
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }
};

export const cursortowait = async () =>
{
  document.body.classList.add('cursortowait');
}

export const cursortodefault = async () =>
{
  document.body.classList.remove('cursortowait');
}
