import React from "react";

const ClaimRewardSuccessPopup = ({ isOpenPop, setIsOpenPop }) => {
  if (!isOpenPop) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
      style={{ zIndex: 6 }}
    >
      <div
        className="reserve-my-spot-instantly-popup"
        // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
        style={{ backgroundColor: "white" }}
      >
        <div className="text-center">
          <img
            className="mx-auto mb-6"
            src={require("../../images/check-gradient.svg").default}
            alt="check"
          />

          <div className="mb-6">
            <h1 className="mb-4 text-center uppercase">Success!</h1>
            <p className="f-18 mb-0">
              We'll review your submission and send you $5 as soon as it's
              approved!
            </p>
          </div>

          <button
            type="button"
            className="btn btn-gradient"
            onClick={() => {
              setIsOpenPop(false);
              // window.location.reload();
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClaimRewardSuccessPopup;
