import React from 'react';

const MetaTags = ({ keyword, description }) => {
 // document.title = title;
  document.querySelector('meta[name="description"]').setAttribute('content', description);
  document.querySelector('meta[name="keywords"]').setAttribute('content', keyword);

  return null;
};

export default MetaTags;