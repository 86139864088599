import React from "react";
import Layout from "../../components/Layout/Layout";
import userImage from "../../images/user3.png";
import { Footer } from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import MetaTags from "../../components/MetaTags/MetaTags";

const ViralVideos = () => {
  const navigate = useNavigate();
  const handlePlay = (index) => {
    const video = document.getElementById(`video_${index}`);
    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
  };
  return (
    <Layout HeaderName="Dashboard" hideFooter={true}>
      <MetaTags keyword="Text to video AI" description="With Yatter's advanced text to video, you can create viral videos on YouTube! See how these creators have done theirs!" />
      <div className="page-container">
        <div className="page-wrapper">
          <div className="card-wrap">
            <div className="card-head">
              <h2 className="mb-3">Top 3 videos made with Yatter by normal people</h2>

              <p className="text-gray font-semibold mb-3">
              You don't need to be a professional video maker to get amazing awareness and more that 1 million views!<br/> All these videos were created with Yatter.
              </p>
            </div>

            <div className="viral-videos-wrap mt-12">
              <div className="viral-video-block">
                <div className="video-block">
                  <div className="stats">
                    <span className="rank">#3</span>
                    <span className="views">
                      3M+ <span>Views</span>
                    </span>
                  </div>
                  
                  <div class="video-inner" style={{ width: "auto", height: "auto"}}>
                      <button
                        type="button"
                        class="btn-circle play"
                        onClick={() => handlePlay(3)}
                      >
                        <span class="material-icons icon"> play_arrow </span>
                      </button>
                      <video id={`video_3`} style={{  height: "auto"}}>
                        <source src={require("../../images/ViralVideos/creepy-clown.mp4")} type="video/mp4" />
                      </video>
                    </div>
                </div>

                <div className="video-info">
                  <div className="mb-4">
                    <span className="tag-theme">Mystery & Horror</span>
                  </div>
                  <h2 className="mb-4">This Creepy Clown Watches Everyone...</h2>
                  <div>
                    <div className="flex mb-3">
                      <img
                        className="user-img-48 mr-3"
                        src={require("../../images/viral-user-3.png")}
                        alt="Creator"
                      />
                      <div>
                        <p className="font-semibold mb-1">Adam G.</p>
                        <span className="f-14 text-gray">
                          Member since January 2023
                        </span>
                      </div>
                    </div>
                    <div className="inline-info mb-3">
                      <span>100+ video created</span>
                      <span>Active 5 days/week</span>
                    </div>
                    <div>
                      <span className="tag-theme f-14 font-regular">
                        Earned <span className="font-semibold">$3,024</span>{" "}
                        from this video
                      </span>
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-black-ouline btn-sm"
                        onClick={() => navigate("/")}
                      >
                        Create my own
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="viral-video-block">
                <div className="video-block">
                  <div className="stats">
                    <span className="rank">#2</span>
                    <span className="views">
                      4M+ <span>Views</span>
                    </span>
                  </div>
                  <div class="video-inner" style={{ width: "auto", height: "auto"}}>
                      <button
                        type="button"
                        class="btn-circle play"
                        onClick={() => handlePlay(2)}
                      >
                        <span class="material-icons icon"> play_arrow </span>
                      </button>
                      <video id={`video_2`} style={{  height: "auto"}}>
                        <source src={require("../../images/ViralVideos/anime-story.mp4")} type="video/mp4" />
                      </video>
                    </div>
                
                </div>

                <div className="video-info">
                  <div className="mb-4">
                    <span className="tag-theme">Anime</span>
                  </div>
                  <h2 className="mb-4">Kai and Hana Save a Queen!</h2>
                  <div>
                    <div className="flex mb-3">
                      <img
                        className="user-img-48 mr-3"
                        src={require("../../images/viral-user-2.png")}
                        alt="Creator"
                      />
                      <div>
                        <p className="font-semibold mb-1">Joanna L.</p>
                        <span className="f-14 text-gray">
                          Member since January 2023
                        </span>
                      </div>
                    </div>
                    <div className="inline-info mb-3">
                      <span>200+ video created</span>
                      <span>Active 5 days/week</span>
                    </div>
                    <div>
                      <span className="tag-theme f-14 font-regular">
                        Earned <span className="font-semibold">$3,989</span>{" "}
                        from this video
                      </span>
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-black-ouline btn-sm"
                        onClick={() => navigate("/")}
                      >
                        Create my own
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="viral-video-block">
                <div className="video-block">
                  <div className="stats">
                    <span className="rank">#1</span>
                    <span className="views">
                      5M+ <span>Views</span>
                    </span>
                  </div>
                  <div class="video-inner" style={{ width: "auto", height: "auto"}}>
                      <button
                        type="button"
                        class="btn-circle play"
                        onClick={() => handlePlay(1)}
                      >
                        <span class="material-icons icon"> play_arrow </span>
                      </button>
                      <video id={`video_1`} style={{  height: "auto"}}>
                        <source src={require("../../images/ViralVideos/kung-fu-panda.mp4")} type="video/mp4" />
                      </video>
                    </div>
                </div>

                <div className="video-info">
                  <div className="mb-4">
                    <span className="tag-theme">New Movies</span>
                  </div>
                  <h2 className="mb-4">Kung Fu Panda 4. In 30 Seconds!</h2>
                  <div>
                    <div className="flex mb-3">
                      <img
                        className="user-img-48 mr-3"
                        src={require("../../images/viral-user-1.png")}
                        alt="Creator"
                      />
                      <div>
                        <p className="font-semibold mb-1">David L.</p>
                        <span className="f-14 text-gray">
                          Member since January 2023
                        </span>
                      </div>
                    </div>
                    <div className="inline-info mb-3">
                      <span>100+ video created</span>
                      <span>Active 6 days/week</span>
                    </div>
                    <div>
                      <span className="tag-theme f-14 font-regular">
                        Earned <span className="font-semibold">$2,389</span>{" "}
                        from this video
                      </span>
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-black-ouline btn-sm"
                        onClick={() => navigate("/")}
                      >
                        Create my own
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default ViralVideos;
