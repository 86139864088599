import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import ModalView from "../../components/common/Modal/ModalView";
import MetaTags from "../../components/MetaTags/MetaTags";

import ExpirePopup from "../../components/WarningPopup/ExpirePopup";
import OneDaypopup from "../../components/WarningPopup/oneDayPopup";
import { VideoGenerationContext } from "../../VideoGenerationContext";
const Explore = () => {
  const { videoGenerations } = useContext(VideoGenerationContext);

  const navigate = useNavigate();
  // const itemsResponse = data?.data;

  // const items = itemsResponse?.map((item) => item.data[0]);
  // useEffect(() => {
  //     if (Object.keys(videosData).length > 4) {
  //         const videoUrls = Object.values(videosData);
  //         // Set the first 4 videos
  //         setFirstFourVideos(videoUrls.slice(0, 4));
  //         // Set the remaining videos after  6 seconds
  //         const timeout = setTimeout(() => {
  //             setRemainingVideos(videoUrls.slice(4));
  //             setDelayedRender(true);
  //         }, 6000);

  //         return () => clearTimeout(timeout);
  //     } else {
  //         const videoUrls = Object.values(videosData);
  //         setFirstFourVideos(videoUrls);
  //     }
  // }, [videosData]);

  const [cancelToken, setCancelToken] = useState(null);
  const allData = JSON.parse(localStorage.getItem("alldata"));
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const newUSer = localStorage.getItem("newuser");
  // console.log(location.state);

  // const data = location?.state?.items || [];
  const updatedValue = location?.state?.updatedArray || location?.state?.items;
  console.log("videoGenerations", videoGenerations);
  const user = JSON.parse(localStorage.getItem("user"));
  const initialPopupValue = localStorage.getItem("oneDayPopupvalue");
  const [oneDaypopup, setOneDaypopup] = useState(
    initialPopupValue !== null ? JSON.parse(initialPopupValue) : true
  );
  const [showExpirepopup, setShowExpirepopup] = useState(true);
  const [showCongpopup, setShowCongpopup] = useState(true);
  // const [videoGenerations, setVideoGenerations] = useState({});

  const days = localStorage.getItem("days");
  const hours = localStorage.getItem("hours");
  const min = localStorage.getItem("min");
  console.log("days", days, hours, min);

  const closeOneDayPopupModal = () => setOneDaypopup(false);
  const closeExpirePopup = () => setShowExpirepopup(false);
  const closeCongPopup = () => setShowCongpopup(false);
  //new

  const handleOneDaypopup = () => {
    console.log("close");
    setOneDaypopup(false);
    localStorage.setItem("oneDayPopupvalue", false);
  };
  const renderVideoBlocks = (videos, IsInitialVideos) => {
    return videos?.map((videoPath, index) => {
      const downloadPath = `https://staging.yatter.io/webs/video/index.php?v=${videoPath.substring(
        videoPath.lastIndexOf("/") + 1
      )}`;
      console.log("videoPath", videoPath);
      const videoName = videoPath.substring(
        videoPath.lastIndexOf("/") + 1,
        videoPath.lastIndexOf(".mp4")
      );

      return (
        <div className="video-block" key={`video_${videoPath}`}>
          <MetaTags
            keyword="AI video creator"
            description="Create amazing AI videos for free. Just use our powerful AI video creator. Yatter is the #1 text-to-video tool for everyone!"
          />
          <div className="video-inner">
            <button
              type="button"
              className="btn-circle play"
              onClick={(e) => {
                e.stopPropagation();
                const videoElement = document.getElementById(
                  `video_${videoPath}`
                );
                if (videoElement.paused || videoElement.ended) {
                  // If video is paused or ended, play it
                  if (videoElement.requestFullscreen) {
                    videoElement.requestFullscreen();
                  } else if (videoElement.mozRequestFullScreen) {
                    videoElement.mozRequestFullScreen();
                  } else if (videoElement.webkitRequestFullscreen) {
                    videoElement.webkitRequestFullscreen();
                  } else if (videoElement.msRequestFullscreen) {
                    videoElement.msRequestFullscreen();
                  }
                  videoElement.play(); // Play the video
                } else {
                  // If video is playing, pause it
                  videoElement.pause(); // Pause the video
                }

                // Function to handle pausing video when exiting fullscreen mode
                const handleFullscreenChange = () => {
                  if (
                    !document.fullscreenElement &&
                    !document.webkitIsFullScreen &&
                    !document.mozFullScreen &&
                    !document.msFullscreenElement
                  ) {
                    videoElement.pause(); // Pause the video when exiting fullscreen mode
                  }
                };

                // Add event listener for fullscreen change
                document.addEventListener(
                  "fullscreenchange",
                  handleFullscreenChange
                );
                document.addEventListener(
                  "webkitfullscreenchange",
                  handleFullscreenChange
                );
                document.addEventListener(
                  "mozfullscreenchange",
                  handleFullscreenChange
                );
                document.addEventListener(
                  "MSFullscreenChange",
                  handleFullscreenChange
                );
              }}
            >
              {/* <button
                  type="button"
                  className="btn-circle play"
                  onClick={(e) => {
                    // e.stopPropagation();
                    // handlePlay(videoPath);
                    handleVideoPopup(videoPath)
                  }}
                > */}
              <span className="material-icons icon">play_arrow</span>
            </button>
            <video id={`video_${videoPath}`}>
              <source src={videoPath} type="video/mp4" />
            </video>
          </div>
          <div className="video-meta">
            <h5
              className="name"
              style={{ width: "180px", wordBreak: "break-all" }}
            >
              {videoName}
            </h5>
            {/* <a
                  className="btn-download-flat"
                  href={downloadPath}
                  target="_blank"
                >
                  <span className="material-symbols-outlined mr-1">download</span>
                  Download
                </a> */}
          </div>
          <div className="video-action flex">
            <a
              type="button"
              className="btn-download-flat w-full !cursor-pointer"
              href={downloadPath}
              target="_blank"
            >
              {/* <span className="material-symbols-outlined mr-1"> download </span>{" "}
                            Export */}
            </a>
            {
              index === 0 &&
                allData &&
                IsInitialVideos &&
                // <button
                //     type="button"
                //     className="btn-download-flat w-full cursor-pointer"
                //     onClick={() => navigate("/rerender")}
                // >
                {
                  /* <span className="material-symbols-outlined mr-1"> edit </span>{" "}
                                Edit */
                }
              // </button>
            }
          </div>
        </div>
      );
    });
  };
  // const fullLink = 'https://staging.yatter.io/webs/video/index.php?v=' + 'Video2.mp4'

  const handleDownload = (url) => {
    const fullLink = "https://staging.yatter.io/webs/video/index.php?v=";
    const filename = url.split("/").pop();
    console.log("filename", filename);
    const link = document.createElement("a");
    link.href = `${fullLink}/${filename}`; // Path to your video file
    link.download = "sample-video.mp4"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePayment = () => {
    // window.location.href = 'https://yatter.ai/checkout/?add-to-cart=1284';
    window.location.href = `https://yatter.ai/wp-sso-payment.php?key=${user?.data.user_email}`;
  };

  function getCurrentURL() {
    // const searchParams = new URLSearchParams(window.location.search);
    return window.location.pathname;
  }
  const hashURl = getCurrentURL();

  // const orderIdStartIndex = url.indexOf('/explore/orderId=/');
  // const orderIdStartIndex = url.indexOf(hashURl);
  const orderIdStartIndex = hashURl.indexOf("/explore/orderId=/");
  let orderIds = "";

  if (orderIdStartIndex !== -1) {
    // Extract orderId from the URL
    // orderIds = url.slice(orderIdStartIndex + '/explore/orderId=/'.length);
    orderIds = hashURl.slice(orderIdStartIndex + "/explore/orderId=/".length);
  }
  console.log("hashURl", hashURl, orderIds);

  return (
    <>
      <Layout HeaderName="Explore" hideFooter={true}>
        <div class="page-container">
          <div class="page-wrapper">
            {/* <div class="video-status lg mb-6">
                        <div class="ai-icon-animation sm mr-2">
                            <img class="ai-icon-1" src={require("../../images/ai-icon.svg").default} alt="AI Icon" />
                            <img class="ai-icon-2" src={require("../../images/ai-icon.svg").default} alt="AI Icon" />
                            <img class="ai-icon-3" src={require("../../images/ai-icon.svg").default} alt="AI Icon" />
                            <img class="ai-icon-4" src={require("../../images/ai-icon.svg").default} alt="AI Icon" />
                        </div>
                        <span>Your video is at 83%...</span>
                    </div> */}

            {/* <div class="video-status lg mb-6">
                        <span class="material-symbols-outlined f-24 mr-2 gradient-text"> check_circle </span>
                        <span>Your new video is ready <a href="#" class="font-semibold underline">Click here to view </a></span>
                    </div> */}
            {/* /new code  */}
            <div className="video_progress">
              {user &&
                newUSer &&
                Object.entries(videoGenerations).map(
                  ([videoId, videoData], index) => {
                    // Ensure videoData has a valid progress property
                    const progress = parseFloat(videoData?.progress);

                    // Check if progress is a valid number within the range
                    if (!isFinite(progress) || progress < 0 || progress > 100) {
                      console.error(
                        `Invalid progress value for videoId ${videoId}:`,
                        progress
                      );
                      return null;
                    }
                    return (
                      <>
                        {index === 0 && (
                          <>
                            {progress !== 100 && (
                              <div key={videoId}>
                                <div className="ai-icon-animation mx-auto mb-2">
                                  <img
                                    className="ai-icon-1"
                                    src={
                                      require("../../images/ai-icon.svg")
                                        .default
                                    }
                                    alt="AI Icon"
                                  />
                                  <img
                                    className="ai-icon-2"
                                    src={
                                      require("../../images/ai-icon.svg")
                                        .default
                                    }
                                    alt="AI Icon"
                                  />
                                  <img
                                    className="ai-icon-3"
                                    src={
                                      require("../../images/ai-icon.svg")
                                        .default
                                    }
                                    alt="AI Icon"
                                  />
                                  <img
                                    className="ai-icon-4"
                                    src={
                                      require("../../images/ai-icon.svg")
                                        .default
                                    }
                                    alt="AI Icon"
                                  />
                                </div>
                                <div className="text-center">
                                  <h2 className="font-normal">
                                    {progress.toFixed(0)}%
                                  </h2>
                                </div>

                                <p
                                  style={{ textAlign: "center" }}
                                  className="font-normal mt-2 f-14 center"
                                >
                                  We are finalizing your video...
                                </p>
                                {updatedValue && (
                                  <div
                                    className="flex justify-center mt-4"
                                    onClick={() => {
                                      if (cancelToken) {
                                        console.log("cancel");
                                        cancelToken.cancel();
                                        navigate("/rerender");
                                      }
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-gradient"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                            {progress === 100 && (
                              <div className="video-status lg mb-6">
                                <span className="material-symbols-outlined f-24 mr-2 gradient-text">
                                  check_circle
                                </span>
                                <span>
                                  Your new video is ready{" "}
                                  <Link
                                    to="/dashboard"
                                    className="font-semibold underline"
                                    onClick={() =>
                                      localStorage.removeItem("newuser")
                                    }
                                  >
                                    Click here to view
                                  </Link>
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    );
                  }
                )}
              {!user && (
                <div className="video-block">
                  <div className="video-inner"></div>
                </div>
              )}
            </div>

            {/* new code end */}
            <div>
              <h2 class="flex items-center mb-8">
                <span class="material-symbols-outlined f-30 gradient-text mr-2">
                  {" "}
                  grade{" "}
                </span>{" "}
                <span>Top Yatter Creations</span>
              </h2>

              <div class="explore-videos-wrap">
                <div class="video-grid portrait">
                  <div class="video-card">
                    {/* new code */}
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video1");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>

                    {/* <button type="button" class="btn-circle play"><span class="material-icons icon">play_arrow</span></button> */}
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video1");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video1"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video3(mobile).mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Welcome to my travel diary</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video3(mobile).mp4"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video1(mobile).mp4' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>

                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video2`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>{" "}
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video2");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video2"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video1(mobile).mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6> Small business promotion</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video1(mobile).mp4"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video2(mobile).mp4' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>
                </div>

                <div class="video-grid landscape">
                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video3`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video3");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video3"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video2.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Welcome to our pottery business</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video2.mp4"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video3(mobile).mp4' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>

                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video4`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video4");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video4"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video1.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Welcome to our real estate property</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video1.mp4"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video3.mp4' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>
                </div>

                <div class="video-grid portrait">
                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video5`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>{" "}
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video5");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video5"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video2(mobile).mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Craving delicious food</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video2(mobile).mp4"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video1(mobile).mp4' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>

                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video6`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>{" "}
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video6");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video6"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video4(mobile).mov"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Welcome to my small cocktail business</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video4(mobile).mov"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video2(mobile).mp4' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>
                </div>

                <div class="video-grid landscape">
                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video7`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>{" "}
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video7");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video7"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video3.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Welcome to bakery shop</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video3.mp4"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video4(mobile).mov' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>

                  <div class="video-card">
                    <button
                      type="button"
                      className="btn-circle play"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById(`video8`);
                        if (videoElement.paused || videoElement.ended) {
                          // If video is paused or ended, play it
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                          videoElement.play(); // Play the video
                        } else {
                          // If video is playing, pause it
                          videoElement.pause(); // Pause the video
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                    >
                      <span className="material-icons icon">play_arrow</span>
                    </button>{" "}
                    <video
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        const videoElement = document.getElementById("video8");
                        if (!videoElement.paused) {
                          videoElement.pause(); // Pause the video if it's currently playing
                        } else {
                          videoElement.play(); // Play the video if it's paused
                          if (videoElement.requestFullscreen) {
                            videoElement.requestFullscreen();
                          } else if (videoElement.mozRequestFullScreen) {
                            videoElement.mozRequestFullScreen();
                          } else if (videoElement.webkitRequestFullscreen) {
                            videoElement.webkitRequestFullscreen();
                          } else if (videoElement.msRequestFullscreen) {
                            videoElement.msRequestFullscreen();
                          }
                        }

                        // Function to handle pausing video when exiting fullscreen mode
                        const handleFullscreenChange = () => {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            videoElement.pause(); // Pause the video when exiting fullscreen mode
                          }
                        };

                        // Add event listener for fullscreen change
                        document.addEventListener(
                          "fullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "webkitfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "mozfullscreenchange",
                          handleFullscreenChange
                        );
                        document.addEventListener(
                          "MSFullscreenChange",
                          handleFullscreenChange
                        );
                      }}
                      id="video8"
                    >
                      {/* <source src="images/short-1.mp4" type="video/mp4" /> */}
                      <source
                        src="https://staging.yatter.io/webs/video/Video4.mov"
                        type="video/mp4"
                      />
                    </video>
                    <div class="flex items-center justify-between p-2">
                      <h6>Brain development and wellness</h6>
                      <button
                        type="button"
                        class="btn btn-flat"
                        onClick={() => {
                          handleDownload(
                            "https://staging.yatter.io/webs/Video4.mov"
                          );
                        }}
                      >
                        <span class="material-icons text-red"> download </span>
                      </button>
                      {/* <a href='https://staging.yatter.io/webs/video/index.php?v=Video4.mov' target='_blank'>
                                            <span class="material-icons text-red"> download </span>
                                        </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {days > 0 && days < 7 && (
        <ModalView
          isOpenPop={oneDaypopup}
          closePopupModal={closeOneDayPopupModal}
        >
          {/* <div className=""> */}

          <OneDaypopup onClick={handleOneDaypopup} onPayment={handlePayment} />
          {/* </div> */}
          {/* <div>HIO</div> */}
        </ModalView>
      )}
      {user?.orders?.order_type === "free" &&
        days == 0 &&
        hours == 0 &&
        min == 0 && (
          <ModalView
            isOpenPop={showExpirepopup}
            closePopupModal={closeExpirePopup}
          >
            <ExpirePopup onPayment={handlePayment} />
          </ModalView>
        )}
      {hashURl == `/explore/orderId=/${orderIds}` && (
        <ModalView isOpenPop={showCongpopup} closePopupModal={closeCongPopup}>
          <div class="custom-modal md">
            <div>
              <div class="text-center mb-6">
                <img
                  class="mb-4 mx-auto"
                  src={require("../../images/confetti-ocon-2.svg").default}
                  alt="Confetti"
                />
                <h1 class="text-green uppercase mb-4">Congratulations!</h1>
                <h2 class="font-bold mb-6">
                  You've regained access to Yatter AI.
                </h2>
                <p class="f-20 mb-6">
                  Every time you'll need a video, Yatter AI can help:
                </p>
              </div>

              <div class="flex justify-center">
                <button
                  type="button"
                  class="btn btn-gradient btn-text-icon mb-2"
                  onClick={() => navigate("/explore")}
                >
                  <span class="material-icons f-24 mr-2">auto_awesome</span>Keep
                  Exploring
                </button>
              </div>
            </div>
          </div>
        </ModalView>
      )}
    </>
  );
};

export default Explore;
