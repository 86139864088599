import axios from "axios";

export const getUser = async (id) => {
   
  try {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/yatter-customer/v1/customer_detail?customer_id=${id}` );
    return response?.data;
  } catch (error) {
    return error;
  }
};
