import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/Modal/Modal";
// import { resetPassword } from "../../Api/resetPassword/resetPassword";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [newPasswordPasswordVisible, setNewPasswordPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = (name) => {
    if(name === 'newPassword'){
      setNewPasswordPasswordVisible((prevVisible) => !prevVisible);
    } else {
      setPasswordVisible((prevVisible) => !prevVisible);
    }
  }
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };
  const handlePasswordReset = async () => {
    const errors = {};
    if (formData.newPassword.trim() === "") {
      errors.newPassword = "This field is required.";
    }  
    if (formData.confirmPassword.trim() === "") {
      errors.confirmPassword = "This field is required.";
    }
  
    if (formData.newPassword !== formData.confirmPassword) {
      errors.newPassword = "New password and confirm password do not match.";
      errors.confirmPassword = "New password and confirm password do not match.";
    }  
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // toast.success("Password reset successfully")
    setIsModalOpen(true)
    // navigate('/login')
    // try {
    //   const data = await resetPassword(email);
  
    //   if (data?.success) {
    //     setIsModalOpen(!isModalOpen);
    //     setEmail('')
    //   } else if (data?.error) {
    //     toast.error(data.error);
    //     setEmail('')
    //   }
    // } catch (error) {
    //   console.error("An error occurred while resetting password:", error);
    // }
  };
  
  // const handleForgot = () => {
  //   setIsModalOpen(true);
  // };
  return (
    <>
      <div className="authentication-wrap">
        <div className="mb-6 text-center">
          <img
            className="logo mx-auto"
            src="../../images/logo.png"
            alt="Yatter"
          />
        </div>

        <div
          className="authentication-block"
          style={{ marginTop: "70px", marginBottom: "70px" }}
        >
          <video className="video-carousel" autoPlay muted loop>
            <source
              src={require("../../images/yatter-video-carousel-3.mp4")}
              type="video/mp4"
            />
          </video>

          <div className="authentication-card text-center">
            <div className="head">
              <h1 className="text-red uppercase">Reset Your Password</h1>
              <h4 style={{margin: '24px 0 0'}}>
                Create an new password for your yatter account below
              </h4>
            </div>

            <div className="body">
              <form action="#">
                <div className="input-group">
                  <input
                    type={newPasswordPasswordVisible ? 'text' : 'password'}
                    name="newPassword"
                    className="input-control"
                    placeholder="Enter your password"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                  />
                {formErrors.newPassword && (
                  <p style={{ color: "red", marginTop: '-1.25rem', textAlign: 'left', color: 'red' }}>{formErrors.newPassword}</p>
                )}
                 <button type="button" className="btn-icon show-pwd" onClick={() => togglePasswordVisibility('newPassword')}>
                  <span className="material-icons text-gray f-20">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
                </div>
                <div className="input-group">
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="confirmPassword"
                    className="input-control"
                    placeholder="Confirm your password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  {formErrors.confirmPassword && (
                  <p style={{ color: "red", marginTop: '-1.25rem', textAlign: 'left', color: 'red' }}>{formErrors.confirmPassword}</p>
                )}
                <button type="button" className="btn-icon show-pwd" onClick={() => togglePasswordVisibility('confirmPassword')}>
                  <span className="material-icons text-gray f-20">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
                </div>

                <div className="input-group mb-0">
                  <button
                    type="button"
                    className="btn btn-gradient"
                    onClick={handlePasswordReset}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p className="f-18 mb-0 font-medium">
            Remembered your password?{" "}
            <button
              className="font-bold text-red"
              onClick={() => navigate("/login")}
            >
              Login here
            </button>
          </p>
        </div>

        <div className="perks-wrap">
          <div className="perk-block">
            <span className="material-icons icon gradient-text f-24 mr-2">
              {" "}
              thumb_up_alt{" "}
            </span>
            <span>
            Selected by hundreds of{" "}
              <span className="font-bold">video experts</span>
            </span>
          </div>
          <div className="perk-block">
            <span className="material-icons icon gradient-text f-24 mr-2">
              {" "}
              star{" "}
            </span>
            <span>
              <span className="font-bold">4.9 stars</span> based on 1,309
              reviews
            </span>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        title="Great!"
        body="We have sent a password reset link to your email. <br> Please check your inbox to reset your password."
        btn_text="Go to login"
        onClick={() => {
          setIsModalOpen(false);
          navigate("/login");
        }}
      /> */}
      <Modal
        isOpen={isModalOpen}
        title="Success!"
        body="Your password reset successfully!"
        btn_text="Go to Login"
        onClick={() => {
          setIsModalOpen(false);
          navigate("/login");
        }}
      />
    </>
  );
};

export default ResetPassword;
