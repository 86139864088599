import React from "react";

export default function Modal({ isOpen, title, body, btn_text, onClick }) {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "1000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <div class="custom-modal md">
          <div class="head">
            <img
              className="logo mx-auto mb-4"
              src="images/check-gradient.svg"
              alt="Check"
            />
            <h1 class="text-red uppercase mb-2" style={{ textAlign: "center" }}>
              {title}
            </h1>
            <h4
              dangerouslySetInnerHTML={{ __html: body }}
              style={{ textAlign: "center" }}
            />{" "}
          </div>

          <div class="body">
            <div
              class="mb-0"
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button type="button" class="btn btn-gradient" onClick={onClick}>
                {btn_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
