import React, {useEffect} from "react";
import Layout from "../../components/Layout/Layout";
import userImage from "../../images/user3.png";
import { Footer } from "../../components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import {postconfirmationEmail} from "../../services/SendImageService";

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state);
  const userId = location?.state?.detailsu.ID; // Accessing data passed from Screen 1
  const userEmail=location?.state?.detailsu.user_email;

  const emaildata2 = {
    email: userEmail,
    userId: userId,
  };

  const sendconfirmationEmail = async (emaildata) => {
    console.log("emaildata", emaildata);
    const resp = await postconfirmationEmail(emaildata);
    console.log("resp", resp);
    if (resp?.status === 200) {
      // localStorage.removeItem("Email");
     
      
    } else {
      console.log("error", resp);
    
      
    }
  };

 const HandleEmailing = async (emaildata) => {
    sendconfirmationEmail(emaildata);
    document.getElementById('resendemailconfirm').style.display = "block";
 }


  useEffect(() => {
    const emaildata = {
        email: userEmail,
        userId: userId,
      };
      sendconfirmationEmail(emaildata);
    }, []);


  return (
    <Layout HeaderName="Email Confirmation" hideFooter={true}>
      <div className="page-container">
        <div className="page-wrapper">

        <div class="card-wrap">
          <div class="mb-6 text-center">
            <span class="material-symbols-outlined gradient-text"style={{fontSize:"80px"}}> mark_email_read </span>
          </div>

          <div class="mb-6 text-center">
            <h1 class="mb-6">Last step: Confirm your email</h1>
            <p class="f-24">As a fully FREE AI tool, we need to make sure robots aren't invading our site!</p>
          </div>

          <div class="text-center">
            <div class="highlight-block rounded p-6 mb-6">
              <p class="f-20 mb-2">Please check your email and confirm your email address</p>
              <p class="f-20 mb-0 font-bold">{userEmail}</p>
            </div>
            <p class="f-18">Didn't received confirmation link? <button type="button" className="font-bold text-red" onClick={() => HandleEmailing(emaildata2)}>Resend</button></p>
          </div>

          <div class="flex justify-center mt-4" id="resendemailconfirm" style={{display:"none"}}>
            <div class="short-limit-info">
              <span class="material-icons"> check_circle </span>
              Email confirmation request resent successfully.
            </div>
          </div>
          
      </div>
          
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default Confirmation;
