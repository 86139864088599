import { OpenAI } from "openai";
import React, { useState, useEffect, useContext, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import {
  generateImaged3,
  splitScriptIntoSections,
} from "../services/chatGPTService";
import { json, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { toast } from "react-toastify";
// import { writeFile, readFile } from 'fs-'
// import fs from 'fs';
// import openai from 'openai';
import mixpanel, { time_event } from "mixpanel-browser";
import { getAiScriptapi } from "../Api/getAiScriptapi/getAiScriptapi";
import ImagesIcon from "../images/images.svg";
import ModalView from "./common/Modal/ModalView";
import { VideoGenerationContext } from "../VideoGenerationContext";
import { supportSubmit } from "../services/SendImageService";
import ExpirePopup from "./WarningPopup/ExpirePopup";
import OneDayPopup from "./WarningPopup/oneDayPopup";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

function formatMilliseconds(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutes}:${paddedSeconds}`;
}

const InputForm = ({
  onSubmit,
  setShowCheckMark,
  showCheckMark,
  isEdit,
  setIsEdit,
  showPremiumTip,
  setShowPremiumTip,
  setIsFreeTrialPopupOpen,
}) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const form = new FormData();

  const oneDayPopupvalue = localStorage.getItem('oneDayPopupvalue')

  const filereader = new FileReader();
  const [loading, setLoading] = useState(false);
  const [isTextAreaBlank, setIsTextAreaBlank] = useState(false);
  const [isUserTextAreaBlank, setIsUserTextAreaBlank] = useState(false);
  const [isAiTextAreaBlank, setIsAiTextAreaBlank] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isQabout, setisQabout] = useState(false);
  const [isError, setIsError] = useState(false);
  const [image, setImage] = useState([]);
  const [erroroc, seterroroc] = useState(false);
  const [showAiScript, setShowAiScript] = useState(false);
  const [uploadSelfProgress, setUploadSelfProgress] = useState(0);
  const [isModalPopOpen, setIsModalPopOpen] = useState(false);
  const [isImageModalPopOpen, setIsImageModalPopOpen] = useState(false);
  const [showAiInput, setShowAiInput] = useState(false);
  const [showUserPromptPopup, setShowUserPromptPopup] = useState(false);
  const [saveUserPrompt, setSaveUserPrompt] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [message, setMessage] = useState('');
  const [reply, setReply] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [oneDaypopup, setOneDaypopup] = useState(true);
  const initialPopupValue = localStorage.getItem('oneDayPopupvalue');
  const [oneDaypopup, setOneDaypopup] = useState(
    initialPopupValue !== null ? JSON.parse(initialPopupValue) : true
  );
  const [showExpirepopup, setShowExpirepopup] = useState(true);
  // const [isToggled, setIsToggled] = useState(true);

  const tooltipRef = useRef(null);

  const wordLimit = user ? 600 : 400;
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const { setUser } = useContext(VideoGenerationContext);

  const days = localStorage.getItem('days')
  const hours = localStorage.getItem('hours')
  const min = localStorage.getItem('min')
  // console.log('days', days, hours, min, days == 0 && hours == 0 && min == 0 ? true : false, typeof (days));
  const closePopModal = () => setIsModalPopOpen(false);
  const closeOneDayPopupModal = () => setOneDaypopup(false);
  const closeExpirePopup = () => setShowExpirepopup(false);
  const [formData, setFormData] = useState({
    language: "",
    fieldOfStudy: "",
    natureOfStudy: "",
    classOrCourse: "",
    toneOfVoice: "",
    generalInformation: "",
  });

  const [Stvalue, setStvalue] = useState("");
  const formDataString = localStorage.getItem("getFormData");
  const userScript = localStorage.getItem("user_script");
 // console.log(formDataString);

  const getformData = formDataString ? JSON.parse(formDataString) : {};

  // const [caption, setCaption] = useState({
  //     caption_switch: getformData?.caption?.caption_switch || isToggled,
  //     caption_color: getformData?.caption?.caption_color || ""
  // });

  const [subformData, setSubFormData] = useState({
    // image: "",
    // question: getformData?.subformData?.question || '',
    // qabout: getformData?.subformData?.qabout || '',
    question: getformData?.subformData?.question || "",
    qabout: getformData?.subformData?.qabout || "",
    inputText: getformData?.subformData?.inputText || "",
  });

  // const [subformData, setSubFormData] = useState('');

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const [timecompleted, settimeCompleted] = useState(false);

  var forreadyvideo = "";

  function getCurrentURL() {
    return window.location.hash
  }

  const handlePayment = () => {
    // window.location.href = 'https://yatter.ai/checkout/?add-to-cart=1284';
    window.location.href = `https://yatter.ai/wp-sso-payment.php?key=${user?.data.user_email}`;
  };

  // useEffect(() => {
  //   const oneDayPopupvalue = localStorage.getItem('oneDayPopupvalue')
  //   setOneDaypopup(oneDayPopupvalue)
  // }, [])

  // Example
  const hashURl = getCurrentURL()
  console.log("hashURl", hashURl);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const simulateProgress = () => {
    let progress = 0;
    const intervalId = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        setUploadSelfProgress(progress);
      } else {
        clearInterval(intervalId);
      }
    }, 150); // Interval in milliseconds (150ms for smoother animation)
    return intervalId;
  };
  useEffect(() => {
    if (!showCheckMark) {
      setShowAiScript(false);
      setSubFormData({ ...subformData, userPrompt: userScript });
    }
  }, [showCheckMark]);
  console.log("isEdit", isEdit);
  const fetchAiScript = async (e, premiumTip) => {
    e.preventDefault();
    console.log(
      "subformData?.userPrompt?.trim()",
      subformData?.userPrompt?.trim()
    );
    if (
      subformData?.userPrompt?.trim() === "" ||
      subformData?.userPrompt === null
    ) {
      // toast.error('Type your video script to continue')
      setIsTextAreaBlank(true);
      return;
    }
    console.log(
      "!subformData?.question?.trim() ===",
      subformData?.question,
      subformData?.question !== ""
    );
    // if (subformData?.question !== "") {
    //   if(!isEdit){
    //     setShowAiScript(true)
    //     setShowCheckMark(true)
    //     return
    //   }
    // }

    localStorage.removeItem("all_images");
    localStorage.removeItem("imagePageState");
    localStorage.removeItem("pexel_videos");
    const intervalId = simulateProgress();
    setLoading(true);
    try {
      // premiumTip && setSubFormData({...subformData, question: premiumTip});
      const script = subformData?.userPrompt?.trim();
      localStorage.setItem("user_script", script);
      user?.data?.ID && (await mixpanel.identify(user?.data.ID));
      await mixpanel.track("Choosing Script - Video Creation Step 1", {
        Email: user?.data.user_email,
        "User Id": user?.data.ID || "",
        "User Type": user?.orders.order_type || "guest",
        Script: script,
      });
      const data = await getAiScriptapi({ textscript: script });
    //  console.log("data geting ai script", data);
      if (data?.textscript) {
      //  console.log({ textscript: data?.textscript });
        const text = data?.textscript?.trim();
        setSubFormData({ ...subformData, question: text, userPrompt: script });
        setLoading(false);
        clearInterval(intervalId);
        setUploadSelfProgress(0);
        setShowAiScript(true);
        setShowCheckMark(true);
        setIsTextAreaBlank(false);
        setIsEdit(true);
      } else {
        toast.error(
          "If you can't proceed to the next step - not to worry. Our servers are experiencing a lot of traffic. Simply come back a little later and try it again. Thank you!",
          {
            autoClose: 10000,
          }
        );
        console.error({ error: data });
        const temp = { ...subformData, userPrompt: "" };
        setSubFormData(temp);
        setLoading(false);
        clearInterval(intervalId);
        setUploadSelfProgress(0);
        setShowAiScript(false);
        // window.location.reload();
      }
    } catch (error) {
      toast.error(
        "If you can't proceed to the next step - not to worry. Our servers are experiencing a lot of traffic. Simply come back a little later and try it again. Thank you!",
        {
          autoClose: 10000,
        }
      );
      console.error({ error });
      const temp = { ...subformData, userPrompt: "" };
      setSubFormData(temp);
      setLoading(false);
      clearInterval(intervalId);
      setUploadSelfProgress(0);
      setShowAiScript(false);
      // window.location.reload();
    }
  };
  const handleformSubmit = async (e, videoRatio) => {
    e.preventDefault();
    setIsImageModalPopOpen(false);
    localStorage.setItem("mobileImg", videoRatio);
    // if(!isEdit){
    //   navigate('/get-images')
    //   setShowAiScript(false)
    //   setShowCheckMark(false)
    //   return
    // }
    if (saveUserPrompt) {
      setSubFormData({ ...subformData, question: undefined });
    }
    if (!subformData?.userPrompt && !subformData?.question) {
      toast.error("Something went wrong! Please try writing your idea again.");
      return;
    }
    localStorage.removeItem("all_images");
    localStorage.removeItem("imagePageState");
    localStorage.removeItem("pexel_videos");
    console.log("subformData?.question", subformData?.question);
    const textAreaValue = saveUserPrompt
      ? subformData?.userPrompt
      : subformData?.question;
    // if (!Array.isArray(textAreaValue) && !textAreaValue?.trim()) {
    //     setIsTextAreaBlank(true);
    //     setLoading(false);
    // }

    const dataToStore = {
      subformData: {
        ...subformData,
        question: saveUserPrompt
          ? ""
          : subformData?.question?.replace(/(\r\n|\n|\r)/gm, ""),
        userPrompt: subformData?.userPrompt?.replace(/(\r\n|\n|\r)/gm, ""),
      },
      // caption: caption
    };
    // const image = await openai.images.generate({ model: "dall-e-3", prompt: subformData.question });
    // console.log('img', image.data);
    // console.log(image.data);

    console.log("subformDatasubformData", subformData);
    console.log("dataToStoredataToStore", dataToStore);
    console.log("subformData.qabout", subformData.qabout);
    // setLoading(true);
    const InputValueq = saveUserPrompt
      ? subformData?.userPrompt?.replace(/(\r\n|\n|\r)/gm, "")
      : subformData?.question?.replace(/(\r\n|\n|\r)/gm, "");
    console.log("subformData.question", subformData.question);
    localStorage.setItem("getFormData", JSON.stringify(dataToStore));
    Cookies.set("scripttxt", InputValueq, { path: "/", domain: ".yatter.io" });
    Cookies.set("scripttxt", InputValueq);
    setSaveUserPrompt(false);
    user?.data?.ID && (await mixpanel.identify(user?.data.ID));
    await mixpanel.track("Choosing AI Script", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || "",
      "User Type": user?.orders.order_type || "guest",
      "AI Script": dataToStore?.subformData?.question,
      "User Script": subformData?.userPrompt?.trim(),
    });
    setIsModalPopOpen(false);
    // if (user) {
    // navigate("/get-image", {
    // navigate("/dashboard", {
    //   state: {
    //     subFormData: subformData,
    //   },

    // });
    navigate("/get-images", {
      state: {
        subFormData: subformData,
      },

    });
    // localStorage.setItem("generateVideo", true);
    // } else {
    //   navigate("/dashboard", {
    //     state: {
    //       subFormData: subformData,
    //       call: true,
    //     },
    //   });
    //   localStorage.setItem("generateVideo", true);
    // }
  };

  const handleSubmit = async () => {
    setLoader(true)
    const fData = {
      useremail: user?.data.user_email || "",
      message: message || ""
    }
    // console.log('hhhh', fData);
    const response = await supportSubmit(fData)
    if (response) {
      console.log('succ', response);
      setReply(true);
      setTimeout(() => {
        setReply(false);
      }, 3000);
      setLoader(false)
      setShowToolTip(false)
    } else {
      console.log('error', response);
      setShowToolTip(false)
      setLoader(false)
    }
  }
  const handleClose = () => {
    setShowToolTip(false)
  }

  const handlevalueChange = (event) => {
    setIsEdit(true);
    const inputValue = event.target.value;
    console.log(
      "event.target.name",
      event.target.name,
      "event.target.value",
      inputValue
    );

    if (inputValue.length > wordLimit) {
      setIsError(true);
      setSubFormData({
        ...subformData,
        [event.target.name]: inputValue,
        inputText: inputValue,
      });
    } else {
      setSubFormData({
        ...subformData,
        [event.target.name]: inputValue,
        inputText: inputValue,
      });
      setIsError(false);
      setIsTextAreaBlank(false);
      setIsUserTextAreaBlank(false);
      setIsAiTextAreaBlank(false);
    }
    // const words = inputValue.trim().split(/\s+/); // Split by whitespace to get words
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const webscript = queryParameters.get("script");
    console.log({ webscript });
    if (webscript) {
      setSubFormData({ ...subformData, userPrompt: webscript });
      setIsEdit(true);
    }
  }, []);
  console.log({ subformData, showUserPromptPopup });

  const handleOneDaypopup = () => {
    console.log('close');
    setOneDaypopup(false)
  }

  return (
    <>
      {!showAiScript && !showCheckMark && !loading && (
        <>
          {/* <form className="creator-form !min-h-fit" onSubmit={fetchAiScript}>
        <div class="mb-6">
             
          <label for="insert-script" class="label !font-bold mb-2">
            Type your video idea
            <br />
            <span class="f-13 text-gray">
              We'll generate an amazing video for you
            </span>
          </label>
          <textarea
            id="insert-script"
            name="userPrompt"
            value={subformData?.userPrompt}
            rows="4"
            className="input-control oneline mw-300"
            placeholder="Write here..."
            onChange={handlevalueChange}
          ></textarea>
          {isError && (
            <p style={{ color: "red", marginBottom: 10 }}>
              Maximum {wordLimit} characters allowed!
            </p>
          )}
          {isTextAreaBlank && (
            <p className="error-message">This field cannot be blank.</p>
          )}
          {erroroc ? (
            <div>Something went wrong, please try again later</div>
          ) : (
            ""
          )}
        </div>

          <div className="loaderbtnscript">
            <button
              type="submit"
              className="btn btn-gradient"
              disabled={isError}
            >
              Continue
            </button>
          </div>
        </form> */}
          {/* <div className="section-wrap-lg !p-0"> */}
          <form className="container-xl mb-8" onSubmit={fetchAiScript}>
            <div className="ai-icon-animation mx-auto mb-2">
              <img
                className="ai-icon-1"
                src={require("../images/ai-icon.svg").default}
                alt="AI Icon"
              />
              <img
                className="ai-icon-2"
                src={require("../images/ai-icon.svg").default}
                alt="AI Icon"
              />
              <img
                className="ai-icon-3"
                src={require("../images/ai-icon.svg").default}
                alt="AI Icon"
              />
              <img
                className="ai-icon-4"
                src={require("../images/ai-icon.svg").default}
                alt="AI Icon"
              />
            </div>
            {/* ai-icon-animation */}
            <h2 className="text-center mb-8">
              Let's create your first AI video instantly
            </h2>
            <div className="enter-topic-wrap w-full mb-6">
              <input
                type="text"
                className="input-control !m-0"
                // placeholder="Enter a topic for your video..."
                placeholder={hashURl === '#birthday' ? "Who's birthday are we celebrating?" : "Enter a topic. Example: promote my bakery products..."}
                onChange={handlevalueChange}
                id="insert-script"
                name="userPrompt"
                value={subformData?.userPrompt}
              />
            </div>
            {isError && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <p style={{ color: "red", marginBottom: 10 }}>
                  {/* Maximum {wordLimit} characters allowed! */}
                  Maximum {wordLimit} characters Want a longer script ?{" "}
                  {!user && (
                    <span
                      className="text-blue underline cursor-pointer"
                      onClick={() => setIsFreeTrialPopupOpen(true)}
                    >
                      Create your account in 10 seconds.
                    </span>
                  )}
                </p>
              </div>
            )}
            {isTextAreaBlank && (
              <p className="error-message -mt-1 mb-3">
                This field cannot be blank.
              </p>
            )}
            {erroroc ? (
              <div>Something went wrong, please try again later</div>
            ) : (
              ""
            )}
            <div className="flex items-center f-13 mb-8">
              <span className="material-symbols-outlined f-16 mr-1">
                {" "}
                info{" "}
              </span>
              Our AI works best for business or personal video. Not science
              fiction.
            </div>
            <div className="justify-btn-action">
              <button
                type="button"
                className="btn btn-flat underline btn-text-icon"
                onClick={() => {
                  setIsError(false);
                  setShowUserPromptPopup(true);
                }}
              >
                Type my own script instead
              </button>
              <div>

                <button
                  type="submit"
                  className="btn btn-gradient btn-text-icon"
                  disabled={isError}
                >
                  <span className="material-icons f-24 mr-2">auto_awesome</span>
                  Generate my video
                </button>

                <div class="tooltip-parent mt-3"
                  onClick={() =>
                    setShowToolTip(true)
                  }>
                  <a href="#!" class="text-red flex items-center justify-center font-medium">
                    Can't continue?
                  </a>
                  {
                    reply &&
                    <a class=" flex items-center justify-center font-medium">
                      {/* We'll get back */}
                      Thanks! We'll fix this bug ASAP!
                    </a>

                  }
                  {/* {
                    showToolTip &&
                    <div class="custom-tooltip right">
                      <div class="">
                        <label class="text-white f-14">Write your message here</label>
                        <textarea
                          id="regenerate-text"
                          rows="2"
                          class="input-control white mt-2"
                          placeholder="Write here..."
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                        ></textarea>
                        <div class="flex">
                        <button
                        type="button"
                 class="btn btn-white btn-sm mr-2"
                      // onClick={() => {
                      // }}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-white-outline btn-sm"
                        onClick={() => setShowToolTip(false)}
                      >
                        Cancel
                      </button>
                          <button
                            type="button"
                            class="btn btn-white btn-sm mr-2"
                            onClick={() => {
                              handleSubmit()
                            }}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            class="btn btn-white-outline btn-sm"
                            onClick={() => handleClose()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>

                  } */}
                </div>
                {/* <div
                  style={{ cursor: "pointer", marginTop: 5 }}
                  onClick={() =>
                    // navigate('/get-images')
                    setShowToolTip(true)
                  }
                >
                  <p style={{ textAlign: "end", color: "red" }}>Can't continue?</p>
                </div> */}
                {
                  showToolTip &&
                  <div
                    // className="custom-tooltip regenerate-action active"
                    // style={{ backgroundColor: 'black', padding: 10, borderRadius: 10 }}
                    className="custom-tooltip right"
                  >
                    <label className="text-white f-14">
                      {/* Write your message here */}
                      What is the exact text you're typing?
                    </label>
                    <textarea
                      id="regenerate-text"
                      rows="2"
                      className="input-control white "
                      style={{ marginTop: 2 }}
                      // placeholder="write your message"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                    ></textarea>
                    {
                      loader &&
                      <div
                        className="loader1"
                        style={{
                          width: "35px",
                          height: "35px",
                          marginLeft: "auto",
                        }}
                      ></div>
                    }
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-white btn-sm mr-2"
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-white-outline btn-sm"
                        onClick={() => setShowToolTip(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </form>

          {/* container-xxl */}
          {/* </div> */}
        </>
      )}
      {showAiScript && showCheckMark && !loading && (
        // <form
        //   className="creator-form"
        // >
        //   <div className="mb-6">
        //     <label for="insert-script" className="label mb-2">
        //       This text will be said inside your video
        //       <br />
        //     </label>

        //     <div className="ai-script-card bg-light-gray p-3 border-light rounded">
        //       <div className="flex items-center justify-center mb-2">
        //         <span className="material-symbols-outlined f-24 gradient-text mr-2">
        //           {" "}
        //           task_alt{" "}
        //         </span>
        //         <span className="f-14 font-bold">Edit anything you need:</span>
        //       </div>
        //       <textarea
        //         id="insert-script"
        //         name="question"
        //         value={subformData?.question}
        //         rows="4"
        //         className="input-control white border-light"
        //         placeholder="Write here..."
        //         onChange={handlevalueChange}
        //       ></textarea>
        //       {isError && !showUserPromptPopup && (
        //         <p style={{ color: "red", marginBottom: 10 }}>
        //           Maximum {wordLimit} characters allowed!
        //         </p>
        //       )}
        //       {isAiTextAreaBlank && (
        //         <p className="error-message">This field cannot be blank.</p>
        //       )}
        //       {erroroc ? (
        //         <div>Something went wrong, please try again later</div>
        //       ) : (
        //         ""
        //       )}
        //     </div>
        //   </div>

        //   <div className="loaderbtnscript flex justify-between ">
        //     <p
        //       className="underline font-medium cursor-pointer"
        //       onClick={() => {
        //         setIsError(false);
        //         setShowUserPromptPopup(true);
        //       }}
        //     >
        //       Type my own script instead
        //     </p>
        //     <button
        //       type="button"
        //       className="btn btn-gradient"
        //       disabled={isError}
        //       onClick={() => {
        //         if (
        //           subformData?.question.trim() === "" ||
        //           subformData?.question === null
        //         ) {
        //           setIsAiTextAreaBlank(true);
        //           return;
        //         }
        //         setIsImageModalPopOpen(true);
        //         setShowUserPromptPopup(false);
        //       }}
        //     >
        //       Continue
        //     </button>
        //   </div>
        // </form>
        <div className="container-xl mb-8">
          <div className="ai-icon-animation mx-auto mb-2">
            <img
              className="ai-icon-1"
              src={require("../images/ai-icon.svg").default}
              alt="AI Icon"
            />
            <img
              className="ai-icon-2"
              src={require("../images/ai-icon.svg").default}
              alt="AI Icon"
            />
            <img
              className="ai-icon-3"
              src={require("../images/ai-icon.svg").default}
              alt="AI Icon"
            />
            <img
              className="ai-icon-4"
              src={require("../images/ai-icon.svg").default}
              alt="AI Icon"
            />
          </div>
          {/* ai-icon-animation */}
          <h2 className="text-center mb-8">
            {/* Our AI has generated a script for you */}
            AI has generated your script
          </h2>
          <div className="enter-topic-wrap w-full mb-6">
            <div className="flex items-center justify-center mb-4 f-14 font-bold bg-light-gray p-2 radius-md">
              <span className="material-icons f-20 gradient-text mr-2">
                task_alt
              </span>
              Edit anything you need:
            </div>
            <textarea
              id="insert-script"
              name="question"
              value={subformData?.question}
              className="input-control m-0"
              rows={4}
              placeholder="Enter a topic for your video..."
              onChange={handlevalueChange}
            />
            {isError && !showUserPromptPopup && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <p style={{ color: "red", marginBottom: 10 }}>
                  {/* Maximum {wordLimit} characters allowed! */}
                  Maximum {wordLimit} characters want longer script ?{" "}
                  {!user && (
                    <span
                      className="text-blue underline cursor-pointer"
                      onClick={() => setIsFreeTrialPopupOpen(true)}
                    >
                      Create your account in 10 seconds.
                    </span>
                  )}
                </p>
              </div>
            )}
            {isAiTextAreaBlank && (
              <p className="error-message">This field cannot be blank.</p>
            )}
            {erroroc ? (
              <div>Something went wrong, please try again later</div>
            ) : (
              ""
            )}
          </div>
          <div className="justify-btn-action">
            <button
              type="button"
              className="btn btn-flat underline btn-text-icon"
              onClick={() => {
                setIsError(false);
                setShowUserPromptPopup(true);
              }}
            >
              Type my own script instead
            </button>
            <button
              type="button"
              className="btn btn-gradient btn-text-icon"
              disabled={isError}
              onClick={() => {
                if (
                  subformData?.question.trim() === "" ||
                  subformData?.question === null
                ) {
                  setIsAiTextAreaBlank(true);
                  return;
                }
                setIsImageModalPopOpen(true);
                setShowUserPromptPopup(false);
              }}
            >
              <span className="material-icons f-24 mr-2">auto_awesome</span>
              Generate my video
            </button>
          </div>
        </div>
      )}
      <ModalView isOpenPop={isModalPopOpen} closePopupModal={closePopModal}>
        {/* <div class="reserve-my-spot-instantly-popup" onMouseEnter={() => cliearImageHover()}> */}
        <div class="text-center">
          <img class="mx-auto mb-6" src={ImagesIcon} alt="Images" />

          <div class="mb-6">
            {/* <h3 class="mb-6 text-center">Are Images Ready to Go?</h3>
                    <p class="f-18 mb-0">
                      Images will appear in this order during the video. Change
                      the order or delete any if necessary.
                    </p> */}
            <h3 class="mb-6 text-center">You can edit your script...</h3>
            <p class="f-18 mb-0">
              This script was generated by our professional AI for videos. You
              can customize it yourself or continue.
            </p>
          </div>

          <div>
            <button
              type="button"
              class="btn btn-gradient mb-6"
              onClick={() => {
                setIsImageModalPopOpen(true);
                setIsModalPopOpen(false);
              }}
            >
              Confirm & Continue
            </button>
            <br />
            <a
              class="text-gray"
              href="#!"
              onClick={() => setIsModalPopOpen(false)}
            >
              Go back to modify
            </a>
          </div>
        </div>
        {/* </div> */}
      </ModalView>
      <ModalView isOpenPop={isImageModalPopOpen}>
        {/* <button type="button" class="btn-close-icon" onClick={closeImagePopModal}><span class="material-icons"> close </span></button> */}

        <div class="">
          <button
            type="button"
            className="btn-close-icon"
            onClick={() => {
              setIsImageModalPopOpen(false);
            }}
          >
            <span className="material-icons"> close </span>
          </button>
          <div class="mb-6">
            <h1 class="mb-6 text-center">
              Do you want a mobile or desktop video?
            </h1>

            <div class="selector-block-wrap mb-0">
              <div
                class="selector-block"
                onClick={(e) => handleformSubmit(e, 1)}
              >
                <img
                  class="mb-3"
                  src={require("../images/mobile-size.svg").default}
                  alt="Mobile Size"
                />
                <h5>
                  Mobile <span class="font-normal">(9:16)</span>
                </h5>
              </div>

              <div
                class="selector-block"
                onClick={(e) => handleformSubmit(e, 0)}
              >
                <img
                  class="mb-3"
                  src={require("../images/desktop-size.svg").default}
                  alt="Desktop Size"
                />
                <h5>
                  Desktop <span class="font-normal">(16:9)</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </ModalView>
      <ModalView isOpenPop={showUserPromptPopup} increaseWidth={"580px"}>
        <button
          type="button"
          className="btn-close-icon"
          onClick={() => {
            setShowUserPromptPopup(false);
          }}
        >
          <span className="material-icons"> close </span>
        </button>
        <h3 className="mb-3">Type in your video script</h3>
        <p className="script-info">
          <span class="material-symbols-outlined mr-1 text-base">info</span>{" "}
          Make sure to type your full script as this will be said inside your
          video.
        </p>
        <div className="motivation-trial">
          <div className="insert-script lg mb-4">
            <form>
              <textarea
                id="insert-script"
                name="userPrompt"
                value={subformData?.userPrompt}
                className="input-control m-0"
                placeholder="Enter your next video idea"
                onChange={handlevalueChange}
              ></textarea>
              <button
                type="button"
                className="btn btn-gradient btn-text-icon !p-2 !text-sm"
                disabled={isError}
                onClick={() => {
                  if (
                    subformData?.userPrompt.trim() === "" ||
                    subformData?.userPrompt === null
                  ) {
                    setIsUserTextAreaBlank(true);
                    return;
                  }
                  if (isError) {
                    return;
                  }
                  setIsImageModalPopOpen(true);
                  setShowUserPromptPopup(false);
                  setSaveUserPrompt(true);
                }}
              >
                Create my free video
                <span className="material-icons f-24 ml-2 bounce-arrow">
                  arrow_right_alt
                </span>
              </button>
            </form>
          </div>
          {isError && (
            <p style={{ color: "red", marginBottom: 10 }}>
              Maximum {wordLimit} characters want longer script ?{" "}
              {!user && (
                <span
                  className="text-blue underline cursor-pointer"
                  onClick={() => setIsFreeTrialPopupOpen(true)}
                >
                  Create your account in 10 seconds.
                </span>
              )}
            </p>
          )}
          {isUserTextAreaBlank && (
            <p className="error-message">This field cannot be blank.</p>
          )}
          {erroroc ? (
            <div>Something went wrong, please try again later</div>
          ) : (
            ""
          )}
          {/* insert-script */}
        </div>
      </ModalView>
      <ModalView isOpenPop={loading} increaseWidth={"680px"}>
        {/* <button
          type="button"
          className="btn-close-icon"
          onClick={() => {
            setShowUserPromptPopup(false);
          }}
        >
          <span className="material-icons"> close </span>
        </button>
        <h3 className="mb-3">Type in your video script</h3> */}
        <div class="choose-img-inputs ai-generated-img-loader container-xl mb-8 !ml-auto !mr-auto">
          <div class="w-full ai-generated-img-inner">
            <div class="flex justify-center p-4 pb-8">
              <div class="spinner-box">
                <div class="configure-border-1">
                  <div class="configure-core"></div>
                </div>
                <div class="configure-border-2">
                  <div class="configure-core"></div>
                </div>
              </div>
            </div>

            <p class="font-bold mb-3 text-center">
              We are creating a cool script for your video. Give us 15 seconds
            </p>

            <div class="flex items-center w-full">
              <div class="progress-bar mr-4">
                <div
                  class="progress"
                  style={{ width: `${uploadSelfProgress}%` }}
                ></div>
              </div>
              <h4 class="">{uploadSelfProgress.toFixed(0)}%</h4>
            </div>
          </div>
        </div>
      </ModalView>
      {
        days > 0 && days < 7 &&
        <ModalView isOpenPop={oneDaypopup} closePopupModal={closeOneDayPopupModal}>
          <div className="">

            <OneDayPopup onClick={handleOneDaypopup} onPayment={handlePayment} />
          </div>

        </ModalView>

      }
      {/* {
        days == 0 && hours == 0 && min == 0 &&
        <div>giguig</div>
      } */}
      {user?.orders?.order_type === 'free' && days == 0 && hours == 0 && min == 0 &&
        <ModalView isOpenPop={showExpirepopup} closePopupModal={closeExpirePopup}>
          <ExpirePopup onPayment={handlePayment} />
        </ModalView>

      }
    </>
  );
};

export default InputForm;
