import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputForm from "../../components/InputForm";
import { generateImaged3, generateImage } from "../../services/chatGPTService";
import Cookies from "js-cookie";
import MetaTags from "../../components/MetaTags/MetaTags";
// import Topbar from "../../components/Topbar/Topbar";
// import { Footer } from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import FreeTrialPopuplongtext from "../../components/RerenderSignup/FreeTrialPopuplongtext";
import { VideoGenerationContext } from "../../VideoGenerationContext";

function GetScript() {
  const { setUser } = useContext(VideoGenerationContext);
  const data = { message: "Hello from Screen 1!" };
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [erroroc, seterroroc] = useState(false);
  const [hover, setHover] = useState(false);
  const [showCheckMark, setShowCheckMark] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showPremiumTip, setShowPremiumTip] = useState(false);
  const [chooseImageTooltip, setChooseImageTooltip] = useState(false);
  const [isFreeTrialPopupOpen, setIsFreeTrialPopupOpen] = useState(false);
  // const [user, setUser] = useState();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));


  const handleFormSubmit = async (formData) => {
    // console.log(formData);
    // setLoading(true); // Start loading
    // try {
    //   // const response = await generateEssay(formData);
    //   console.log('too much');
    //   console.log(formData);
    //   const response = await generateImaged3(formData);
    //   // const response = await maskImage(formData);
    //   // return console.log('resp', response);
    //  // console.log("in app");
    //  // console.log(response);
    //   if (response) {
    //    // console.log(response);
    //     setLoading(false); // Start loading
    //     navigate('/GetImages', { state: { response } });
    //     return setImage(response);
    //    } else {
    //     seterroroc(true);
    //     return console.log('error');
    //   }
    // } catch (error) {
    //   console.error('Error handling form submission:', error);
    // }
  };

  //console.log(data);

  useEffect(() => {
    // Set a flag in local storage on component load
    // localStorage.setItem("clearData", "true");
    localStorage.removeItem("imagePageState");
    // localStorage.removeItem("getFormData");
    // Cookies.remove("scripttxt");
  }, []);

  // useEffect(() => {
  //   setUser(user);
  // }, []);
  useEffect(() => {
    const isPremiumTipShown = sessionStorage.getItem("premiumTipShown");
    if (!isPremiumTipShown && !user) {
      setShowPremiumTip(true);
    }
  }, []);
  const handleClosePremiumTip = (shouldShowAgain) => {
    setShowPremiumTip(false);
    if (shouldShowAgain) {
      sessionStorage.setItem("premiumTipShown", "true");
    }
  };
  return (
    <>
      <Layout HeaderName="Making your professional video">
        <MetaTags
          keyword="Free AI video generator"
          description="Get ready to use the coolest AI video generator for YouTube. Yatter is the #1 text-to-video AI for video creators and businesses."
        />
        {/* <Topbar /> */}
        <div className={user ? "fullscreen-page" : "fullscreen-page_logout"}>
          <div
            className="!w-full"
          // className={
          //   user
          //     ? "short-creator-block customStyle"
          //     : "customStyleLogoutGetScript customStyleLogout short-creator-block"
          // }
          >
            <div className="creator-wrapper">
              {/* { showCheckMark ? (
                <h3 className="mb-4">Here’s a cool AI script for you.</h3>
              ) : (
                <h3 className="mb-4">Write your video idea... (30 seconds)</h3>
              )
              } */}
              <InputForm
                onSubmit={handleFormSubmit}
                showCheckMark={showCheckMark}
                setShowCheckMark={setShowCheckMark}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                showPremiumTip={showPremiumTip}
                setShowPremiumTip={setShowPremiumTip}
                setIsFreeTrialPopupOpen={setIsFreeTrialPopupOpen}
              />
              {/* <div>&nbsp;</div>
        {erroroc ? <div>Something went wrong, please try again later</div> : ''} */}
            </div>

            {/*<div className="example-part">
        <h6 className="mb-2">AI Example</h6>
        <div className="example-media">
          {/* <img className="media-file" src={`${process.env.PUBLIC_URL}/images/ai-example.jpg`} alt="Example media"/> }
          <video width="320" height="240" controls>
            <source src={`${process.env.PUBLIC_URL}/images/TheMaryCeleste.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>*/}

            {/* <div> {image.map((img) => (
        <img src={img.url} alt="Generated from DALL-E" width={200} height={200} />
      ))}
     <Link to={{ pathname: "/screen2", state= {{ value: data } }}>Go to Screen 2</Link>
        <Link to="/screen2" state={{ value: data }}>Go to Screen 2</Link> </div> */}
          </div>
        </div>
        {/* <Footer/> */}
      </Layout>
      {/*    
      {showPremiumTip && !user && (    
              <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
              style={{ zIndex: 10, padding:'24px' }}
            >
              <div
                className="reserve-my-spot-instantly-popup"
                // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
                style={{ backgroundColor: "#fff", border: '3px solid #FFA12C',
                padding: '24px',
                borderRadius: '12px' }}
              >
          
                <div className="">
                  <div className="flex items-center mb-5">
                    <img src="images/premium.svg" className="mr-3" style={{ height: '30px' }} alt="Premium" />
                    <h4>Premium tip:</h4>
                  </div>
                  <div className="f-14 mb-5">
                    <p className="mb-3"><span className="font-bold">For all of us who aren’t “video savvy”</span><br />A good video takes 3-4 hours of professional editing. Let’s create yours in 5 mins max!</p>
                    <p>First, tell us your idea. This will help AI personalize it for you:</p>
                  </div>
                  <div className="flex">
                    <button type="button" className="btn btn-gradient mr-4" onClick={() => handleClosePremiumTip(false)}>Will do!</button>
                    <button type="button" className="btn btn-flat text-gray" onClick={() => handleClosePremiumTip(true)}>Don't show this again</button>
                  </div>
                </div>
                </div>

              </div>
      
          )}
          */}
      <FreeTrialPopuplongtext
        isOpenPop={isFreeTrialPopupOpen}
        // isOpenPop={true}
        setIsOpenPop={setIsFreeTrialPopupOpen}
        setUser={setUser}
        videoGenerating={false}
      />
    </>
  );
}

export default GetScript;
