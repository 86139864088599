import React, { useEffect, useRef, useState } from "react";
import "./Dino.css";

function Dino() {
  //ref to get 'dino' html element in js
  const dinoRef = useRef();
  //ref to get 'cactus' html element in js
  const cactusRef = useRef();
  const [score, setScore] = useState(0);
  const [scorep, setScorep] = useState(0);
  const [scorel, setScorel] = useState(0);

  //method to add 'jump' class every '300ms' as the class jump css has jumping animation of 0.3s(300ms).
  //so on each key press we need to add animation and remove animation
  const jump = () => {
    if (!!dinoRef.current && dinoRef.current.classList != "jump") {
      dinoRef.current.classList.add("jump");
      setTimeout(function () {
        dinoRef.current.classList.remove("jump");
      }, 300);
    }
  };

  //useEffect to track whether dino position and cactus position is intersecting
  //if yes, then game over.
  useEffect(() => {
    const isAlive = setInterval(function () {
      // get current dino Y position
      const dinoTop = parseInt(
        getComputedStyle(dinoRef.current).getPropertyValue("top")
      );

      // get current cactus X position
      let cactusLeft = parseInt(
        getComputedStyle(cactusRef.current).getPropertyValue("left")
      );

      // detect collision
      if (cactusLeft < 40 && cactusLeft > 0 && dinoTop >= 140) {
        // collision
       // alert("Game Over! Your Score : " + score);
       
       
        setScorep(1);
         clearInterval(isAlive);
         setScore(0);
      } else {
        setScore(score + 1);
        if (score!==0)
        {
         setScorel(score);
        }
        if (scorep===1)
        {
         setScore(0);
         const elementcactus = document.getElementById('cactus');
        if (elementcactus) {
            elementcactus.style.display = 'none';
        }
        
        }
        else{
          const elementcactus = document.getElementById('cactus');
        if (elementcactus) {
         
          elementcactus.style.display = 'block';
        }}
      }
    }, 20);
    return () => clearInterval(isAlive);
   // return () => clearInterval(isAlive);
  });

 




  // return () => clearInterval(isAlive);

  //hook to call jump method on any keypress
  useEffect(() => {
    document.addEventListener("keydown", jump);
    return () => document.removeEventListener("keydown", jump);
  }, []);

  useEffect(()=>{
    document.getElementById("dino").addEventListener('touchend', jump);
  },[]);

  return (
    <div className="game" onClick={jump} style={{cursor: 'pointer'}}>
      Score : {score}
      {score === 0 && (
                  <span class="text-black font-semibold" onClick={() => setScorep(0)} style={{position: 'relative', top: '95px',left: '155px',  cursor: 'pointer'}}>Score {scorel} (play again)</span>
                )}
      <div id="dino" onClick={jump}  style={{ zIndex: 5 }} ref={dinoRef}></div>
      <div id="cactus" ref={cactusRef}></div>
    </div>
  );
}

export default Dino;