import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { getUser } from "../../Api/getUser/getUser";
import { toast } from "react-toastify";

function classremoveforresponsive() {
  var elemcls = document.getElementById("clsaddresp");
  elemcls.classList.remove("responsive-visible-menu");
}

export const LeftNav = () => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [userplantype, Setuserplantype] = useState(user?.orders?.order_type);
  const videodirectlink = localStorage.getItem("video_url");

  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  // const [daysLeft, setDaysLeft] = useState(null);

/*
  console.log(
    "user?.orders?.order_type",
    user?.orders?.order_type,
    userplantype
  );
*/
  useEffect(() => {
    // const singleUser = async () => {
    //   try {
    //     const user = await getUser(userId.ID);
    //     Setuserplantype(user?.orders?.order_type);
    //   } catch (error) {
    //     toast.error(error)
    //   }
    // };
    // singleUser();
  }, []);

  // console.log("my location is : ", location.pathname);
  const navigate = useNavigate();
  const handleLogoClicked = () => {
    navigate("/dashboard");
  };
  //console.log("user", user);
  const handlePayment = () => {
    // window.location.href = 'https://yatter.ai/checkout/?add-to-cart=1284';
    window.location.href = `https://yatter.ai/wp-sso-payment.php?key=${user?.data.user_email}`;
  };


  // --------------------testing------------------------------

  // useEffect(() => {
  //   if (timeLeft2 <= 0) return;

  //   const intervalId = setInterval(() => {
  //     setTimeLeft2((prevTime) => prevTime - 1);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [timeLeft2]);
  const signupDate = user?.data?.user_registered;
  //console.log('signupDate', signupDate);
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const signupDateObj = new Date(signupDate);
    const endTime = new Date(signupDateObj.getTime() + 8 * 24 * 60 * 60 * 1000); // 7 days later

    const difference = endTime - now;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    // console.log('timeLeft1', timeLeft.days);
    localStorage.setItem("days", timeLeft.days);
    localStorage.setItem("hours", timeLeft.hours);
    localStorage.setItem("min", timeLeft.minutes);
    return timeLeft;
  }, [signupDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
      // console.log('timeLeft1', calculateTimeLeft());
      // localStorage.setItem("timeLeft", calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [user?.orders?.order_type === 'free' && calculateTimeLeft]);
  //console.log('user_w', timeLeft.hours, timeLeft.days, timeLeft.minutes, timeLeft.hours == 0 && timeLeft.days == 0 && timeLeft.minutes == 0 ? true : false);
  // --------------------testing end------------------------------

  return (
    <div
      className="left-nav"
      id="clsaddresp"
    // style={{opacity: `${videodirectlink != 'undefined' && videodirectlink ? '0.5' : 1}`}}
    >
      {/* <h1>Countdown: {formatTime(timeLeft)}</h1>
      <h1>Countdown1: {formatTime(timeLeft1)}</h1>
      <h1>days:{7 - daysLeft}</h1> */}
      <button
        type="button"
        class="btn-close-icon"
        onClick={classremoveforresponsive}
      >
        <span class="material-icons"> close </span>
      </button>
      <div className="logo" onClick={handleLogoClicked}>
        <img className="brand-logo" src="images/logo.png" alt="Yatter" />
      </div>
      <div className="p-4">
        <button
          type="button"
          className="btn btn-gradient btn-text-icon w-full"
          onClick={() => {
            navigate("/");
          }}
        >
          <span className="material-icons f-20 mr-2"> auto_awesome </span> Make
          an instant video
        </button>
      </div>
      <ul className="menu-list">

        <li className={location.pathname === "/explore" ? "active" : ""}>
          <Link to="/explore">
            <span class="material-icons f-18 mr-3"> explore </span> Explore
          </Link>
        </li>

        <li className={location.pathname === "/dashboard" ? "active" : ""}>
          <Link to="/dashboard">
            <span className="material-icons f-18 mr-3"> dashboard </span>{" "}
            My videos
          </Link>
        </li>

        <li className={location.pathname === "/settings" ? "active" : ""}>
          <Link to="/settings">
            <span className="material-icons f-18 mr-3"> settings </span>{" "}
            Settings
          </Link>
        </li>
        <li
          className={location.pathname === "/affiliate-program" ? "active" : ""}
        >
          <Link to="/affiliate-program">
            <span className="material-symbols-outlined f-18 mr-3">
              {" "}
              diversity_3{" "}
            </span>{" "}
            Affiliate Program
          </Link>
        </li>
        <li
          style={{ display: "none" }}
          className={location.pathname === "/viral-videos" ? "active" : ""}
        >
          <Link to="/viral-videos">
            <span className="material-symbols-outlined f-18 mr-3">
              {" "}
              diversity_3{" "}
            </span>{" "}
            Most viral videos
          </Link>
        </li>
        {/* <li class="active"><a href="#"><span class="material-symbols-outlined f-18 mr-3"> diversity_3 </span> Most viral videos</a></li> */}
        <li className="yatter-nav-menu-mbl">
          <Link to="mailto:info@yatter.ai" target="_blank">
            <span className="material-symbols-outlined f-18 mr-3">
              {" "}
              diversity_3{" "}
            </span>{" "}
            Contact us
          </Link>
        </li>
        <li className="yatter-nav-menu-mbl">
          <Link to="https://yatter.ai/privacy-policy/" target="_blank">
            <span className="material-symbols-outlined f-18 mr-3">
              {" "}
              diversity_3{" "}
            </span>{" "}
            Our Policy
          </Link>
        </li>
      </ul>

      {userplantype == "free" ? (
        // <div className="my-plan-wrap">
        //   <div className="my-plan-card free-plan">
        //     <div className="head">
        //       <span className="premium-icon">
        //         <img
        //           src={require("../../images/free-plan.svg").default}
        //           alt="Premium"
        //         />
        //       </span>
        //       <h5>FREE PLAN</h5>
        //     </div>
        //     <div className="mb-2">
        //       <p className="mb-1">
        //         <span className="font-bold">Create 30+ Instant Videos </span>{" "}
        //         with our Free Plan. Watermark included.
        //       </p>
        //       <p className="mb-0">
        //         Are you <span className="font-bold">obsessed to improve</span>{" "}
        //         your videos? Try Premium.
        //       </p>
        //     </div>
        //     <div>
        //       <button
        //         type="button"
        //         disabled
        //         className="btn btn-white btn-sm btn-text-icon w-full"
        //         onClick={() => {
        //           navigate("/free-plan");
        //         }}
        //       >
        //         <img
        //           style={{ height: "20px" }}
        //           src="images/premium.svg"
        //           alt="Free"
        //         />{" "}
        //         Coming soon
        //       </button>
        //     </div>
        //   </div>
        // </div>
        // daysLeft != 7 ? (
        // daysLeft < 6 ? 
        timeLeft.days > 0 ?
          (
            <div class="my-plan-wrap">
              <div class="my-plan-card free-trial">
                <span class="material-icons icon">info</span>
                <span>
                  <span>Your Free Trial Expires in </span>
                  {/* <h5>{7 - daysLeft}days</h5> */}
                  {/* <h5>{7 - daysLeft + 1}days</h5> */}
                  <h5 >{timeLeft.days} days</h5>

                </span>

                <div class="feature-tooltip">
                  <h5 class="f-18  mb-4">The fastest and easiest way to create your videos:</h5>
                  <ul class="feature-options list mb-3">
                    <li>
                      <div class="feature-option-block bg-light-gradient">
                        <span class="feature-icon"><img src={require('../../images/stock-img-feature.svg').default} alt="Feature Image" /></span>
                        <span>Access <span class="font-bold">unlimited AI images</span> for your videos</span>
                      </div>
                    </li>
                    <li>
                      <div class="feature-option-block bg-light-gradient">
                        <span class="feature-icon"><img src={require('../../images/replace-image-feature.svg').default} alt="Feature Image" /></span>
                        <span>Make and edit your videos automatically <span class="font-bold">with AI</span></span>
                      </div>
                    </li>
                    <li>
                      <div class="feature-option-block bg-light-gradient">
                        <span class="feature-icon"><img src={require('../../images/watermark-feature.svg').default} alt="Feature Image" /></span>
                        <span>Make beautiful videos <span class="font-bold">without any watermark</span></span>
                      </div>
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-gradient premium-btn"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={handlePayment}
                  >
                    <span class="icon"><img src="images/premium.svg" alt="Premium" style={{ width: 30, height: 30 }} /></span>
                    Upgrade in less than 1 min
                  </button>
                </div>
              </div>
            </div>

          ) : timeLeft.hours != 0 && timeLeft.minutes != 0 ? (
            <div class="my-plan-wrap">
              <div class="my-plan-card free-trial red">
                <span class="material-icons icon">info</span>
                <span>
                  <span>Your Free Trial Expires in</span>
                  {/* <h5 class="text-red">23h 25m</h5> */}
                  <h5 class="text-red">{timeLeft.hours}h {timeLeft.minutes}m</h5>
                  {/*<h5>{7 - daysLeft + 1}days</h5>*/}
                  {/* <h5>7 days</h5> */}
                  {/* {formatTime(timeLeft)} */}
                </span>

                <div class="expiring-soon-tooltip">
                  <div class="flex">
                    <p class="f-16 font-medium mb-3">Your trial's expiring soon! Upgrade to continue making instant videos with our AI tool.</p>
                    {/* <span class="material-icons close"> close </span> */}
                  </div>

                  <button
                    type="button"
                    class="btn btn-gradient premium-btn"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={handlePayment}
                  >
                    <span class="icon"><img src="images/premium.svg" alt="Premium" style={{ width: 30, height: 30 }} /></span>
                    Upgrade in less than 1 min
                  </button>
                </div>
              </div>
            </div>
          )
            : null
      ) : (
        <div className="my-plan-wrap">
          <div className="my-plan-card">
            <div className="head">
              <span className="premium-icon">
                <img
                  src={require("../../images/premium.svg").default}
                  alt="Premium"
                />
              </span>
              <h5>Yatter Premium</h5>
            </div>
            <div className="mb-2">
              <p className="mb-1">
                Nice! You're an exclusive Premium user. Create
                <span className="font-bold">instant AI videos:</span>
              </p>
              <p className="mb-0">
                You have <span className="font-bold">50</span> AI videos this
                month.
              </p>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-white btn-sm w-full"
                onClick={() => {
                  navigate("/pricing-plan");
                }}
              >
                View my plan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
