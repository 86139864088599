import { getUserVideoCount } from "../Api/getUserVideoCount/getUserVideoCount";

export const getUSerVideoCountHandle = async(userid) => {
    const data =  await getUserVideoCount(userid);
    console.log("data geting ai script", data);
    if (data?.totalvideo) {
      console.log({ totalvideo: data?.totalvideo });
      localStorage.setItem('video_count', data?.totalvideo)
    } else {
      //toast.error(data?.error);
      console.log({ error: data });
    }
  }