import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
//  import './PricingPlan.css';

// Modal component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      // padding: '20px',
      zIndex: 1000,
    }}>

      <div class="custom-modal warning-modal md">
      {children}
          <div class="text-center">
          <button type="button" class="btn btn-gray mb-4">Downgrade anyway</button>
          <br/>
          <a href="#" class="f-18 gradient-text font-bold" onClick={onClose}>Continue with Premium</a>
          </div>    
      </div>
     {/* <button onClick={onClose}>Close</button> */}
      
    </div>
  );
};


const PricingPlan = () => {
    const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    navigate('/free-plan')
  //   console.log('aaaa')
  //   setIsModalOpen(!isModalOpen);
  // };
  // const handleUpgrade = () => {
  //   window.location.href = "https://yatter.ai/checkout/?add-to-cart=1284";
  };
  return (
    <Layout HeaderName="Pricing Plan">
      <div className="page-container">
        <div className="page-wrapper">
          <div className="card-wrap">
            <div className="pricing-wrap">
              <div className="pricing-block free">
                <div className="head">
                  <h2>Free</h2>
                  <button onClick={toggleModal}
                    type="button"
                    className="btn btn-gray-ouline btn-sm"
                    >
                    Downgrade
                  </button>

                  {/* <Modal isOpen={isModalOpen} onClose={toggleModal}>
                      

                        <div class="text-center mb-6">
                        <h1 class="flex items-center justify-center text-red mb-4"><span class="material-icons f-30 mr-2"> warning </span> WARNING</h1>

                        <h3 class="font-normal"><span class="font-bold">Are you sure?</span> You will lose all your Exclusive benefits...</h3>
                        </div>

                        <div class="red-card mb-6">
                        <ul class="list-wrapper text-left mb-4">
                        <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span class="f-14 font-normal">You'll <span class="font-bold">lose access to all your videos</span>. The dashboard is only accessible to Premium users. </span></li>
                        <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span class="f-14 font-normal">You'll <span class="font-bold">lose access to 50+ instant AI videos</span> every month</span></li>
                        <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span class="f-14 font-normal">You'll lose all the <span class="font-bold">Premium and Affiliate features</span></span></li>
                        </ul>
                        </div>
                   </Modal> */}



                </div>
                <h2 className="font-normal mb-5">It's Free</h2>
                <ul className="list-wrapper text-left">
                  <li className="list-item">
                    <span className="material-symbols-outlined check-icon">
                      task_alt
                    </span>
                    <span>
                      <span className="f-14 font-normal">
                      30+ instant AI videos per month{" "}
                      </span>
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="material-symbols-outlined check-icon">
                      task_alt
                    </span>
                    <span>
                      <span className="f-14 font-normal">
                       Watermark included
                      </span>
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="material-symbols-outlined check-icon">
                      task_alt
                    </span>
                    <span>
                      <span className="f-14 font-normal">
                       No premium features or exclusive offers.
                      </span>
                    </span>
                  </li>
                </ul>
              </div>

              <div className="pricing-block premium">
                <div className="head">
                  <h2 className="flex item-center">
                    <img
                      className="mr-3"
                      src={require("../../images/premium.svg").default}
                      style={{ height: "30px" }}
                      alt="Premium"
                    />{" "}
                    Yatter Premium
                  </h2>
                  <button
                    type="button"
                    className="btn bg-white btn-sm"
                    >
                    This is your current plan
                  </button>
                </div>
                <h2 className="font-normal mb-5">
                  <strike className="text-gray">$19.99</strike>{" "}
                  <span className="font-bold">Only $9.99</span>
                  <span className="f-16 font-bold">/month</span>
                </h2>
                <ul className="list-wrapper text-left">
                  <li className="list-item">
                    <span className="material-symbols-outlined check-icon">
                      task_alt
                    </span>
                    <span>
                      <span className="f-14 font-normal">
                        Get{" "}
                        <span className="font-bold">50+ instant AI </span>{" "}
                         videos per month!
                      </span>
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="material-symbols-outlined check-icon">
                      task_alt
                    </span>
                    <span>
                      <span className="f-14 font-normal">
                        <span className="font-bold">No watermark. </span> 
                        Absolute freedom.
                      </span>
                    </span>
                  </li>
                  <li className="list-item">
                    <span className="material-symbols-outlined check-icon">
                      task_alt
                    </span>
                    <span>
                      <span className="f-14 font-normal">
                        <span className="font-bold">Make $$</span>{" "}
                         as an Affiliate (coming soon)
                      </span>
                    </span>
                  </li>
                </ul>
                <video class="w-full mt-4" autoPlay muted loop>
                  <source
                    src={require("../../images/yatter-video-carousel-1.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPlan;
