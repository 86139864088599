import React, {useEffect} from "react";
import Layout from "../../components/Layout/Layout";
import userImage from "../../images/user3.png";
import { Footer } from "../../components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const Validateemail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state);

  const getQueryValue = (key) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(key);
  };

  useEffect(() => {
  
    let valueqry = getQueryValue('v');
    console.log(valueqry);
    }, []);


  return (
    <Layout HeaderName="Email Confirmation" hideFooter={true}>
      <div className="page-container">
        <div className="page-wrapper">

        <div class="card-wrap">
          <div class="mb-6 text-center">
            <span class="material-symbols-outlined gradient-text" style={{fontSize:"80px"}}> mark_email_read </span>
          </div>

          <div class="mb-6 text-center">
            <h1 class="mb-6">Last step: Confirm your email</h1>
            <p class="f-24">Thanks for validating your email</p>
          </div>
        </div>
          
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default Validateemail;