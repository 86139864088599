import axios from 'axios';

export const login = async (loginData) => {
    console.log("coming In function");
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/yatter-login/v1/login`, loginData);
      return response;
    } catch (error) {
        return error
    }
  };