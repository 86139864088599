import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/Modal/Modal";
import { resetPassword } from "../../Api/resetPassword/resetPassword";
import { toast } from "react-toastify";
import { cursortowait, cursortodefault } from "../../services/chatGPTService";

const ForgotPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  function navigateAndRefresh() {
    navigate("/login");
    window.location.reload();
  }


  const handlePasswordReset = async () => {
    try {
      cursortowait();
      const data = await resetPassword(email);
  
      if (data?.success) {
        setIsModalOpen(!isModalOpen);
        setEmail('')
      } else if (data?.error) {
        toast.error(data.error);
        setEmail('')
      }
    } catch (error) {
      console.error("An error occurred while resetting password:", error);
    }
    cursortodefault();
  };
  
  const handleForgot = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="authentication-wrap">
        <div className="mb-6 text-center">
          <img
            className="logo mx-auto"
            src="../../images/logo.png"
            alt="Yatter"
          />
        </div>

        <div
          className="authentication-block"
          style={{ marginTop: "70px", marginBottom: "70px" }}
        >
          <video className="video-carousel" autoPlay muted loop>
            <source
              src={require("../../images/yatter-video-carousel-3.mp4")}
              type="video/mp4"
            />
          </video>

          <div className="authentication-card text-center">
            <div className="head">
              <h1 className="text-red uppercase">Forgot password?</h1>
              <h4>
                Write your email below, and we'll send you instructions to reset
                your password.
              </h4>
            </div>

            <div className="body">
              <form action="#">
                <div className="input-group">
                  <input
                    type="email"
                    className="input-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="input-group mb-0">
                  <button
                    type="button"
                    className="btn btn-gradient"
                    onClick={handlePasswordReset}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p className="f-18 mb-0 font-medium">
            Remembered your password?{" "}
            <button
              className="font-bold text-red"
              onClick={navigateAndRefresh}
            >
              Login
            </button>
          </p>
        </div>

        <div className="perks-wrap">
          <div className="perk-block">
            <span className="material-icons icon gradient-text f-24 mr-2">
              {" "}
              thumb_up_alt{" "}
            </span>
            <span>
            Selected by hundreds of{" "}
              <span className="font-bold">video experts</span>
            </span>
          </div>
          <div className="perk-block">
            <span className="material-icons icon gradient-text f-24 mr-2">
              {" "}
              star{" "}
            </span>
            <span>
              <span className="font-bold">4.9 stars</span> based on 1,309
              reviews
            </span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        title="Great!"
        body="We have sent a password reset link to your email. <br> Please check your inbox to reset your password."
        btn_text="Go to login"
        onClick={() => {
          setIsModalOpen(false);
          navigate("/login");
        }}
      />
    </>
  );
};

export default ForgotPassword;
