import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//import { sendDataToWebService} from './services/SendImageService';
import Dino from "../../components/Dino/Dino";
import "../../css/getvideo.css";
import axios from "axios";
import Cookies from "js-cookie";
import Topbar from "../../components/Topbar/Topbar";
import { Footer } from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import mixpanel from "mixpanel-browser";
import { postEmail } from "../../services/SendImageService";
import { toast } from "react-toastify";
import { getUserVideoCount } from "../../Api/getUserVideoCount/getUserVideoCount";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import astronaut1 from "../../images/astronaut-1.png";
import astronaut2 from "../../images/astronaut-2.png";
import astronautbg from "../../images/astronauts-bg.jpg";
import { getDeviceType } from "../../utils/CheckDevice";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const deviceType = getDeviceType();

function formatMilliseconds(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutes}:${paddedSeconds}`;
}

function GetVideo() {
  const location = useLocation();
  // console.log(location.state);
  const data = location?.state?.items || []; // Accessing data passed from Screen 1
  console.log("check_data", data);

  console.log("checking location", location?.state);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timecompleted, settimeCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const getformData = JSON.parse(localStorage.getItem("getFormData"));
  const videoScript =
    getformData?.subformData?.question || getformData?.subformData?.userPrompt;
  const userScript = localStorage.getItem("user_script");
  const scriptout = Cookies.get("scripttxt");
  //const [email, setEmail] = useState('');
  //const [submit, setSubmit] = useState(false);
  var forreadyvideo = "";

  const [text, settext] = useState([]);

  /* const handleSubmit = () => {
     localStorage.setItem('Email', email)
     setSubmit(true)
   }
 */
  //console.log("useruser", user)
  function getFilenameFromURL(fileUrl) {
    const lastSlashIndex = fileUrl.lastIndexOf("/");
    return fileUrl.substring(lastSlashIndex + 1); // Extracts the substring after the last slash
  }
  const sendEmail = async (emaildata) => {
    console.log("emaildata", emaildata);
    const resp = await postEmail(emaildata);
    console.log("resp", resp);
    if (resp?.status === 200) {
      // localStorage.removeItem("Email");
      console.log("Video sent successfully to your email");
    } else {
      console.log("Error in sending email", resp);
      // toast.error("Error in sending email", resp);
      // localStorage.removeItem("Email");
    }
  };
  useEffect(() => {
    return () => {
      if (cancelToken) {
        cancelToken.cancel();
      }
    };
  }, [cancelToken]);
  useEffect(() => {
    const totalDuration = 120000;
    let elapsedTime = 0;
    const interval = setInterval(() => {
      elapsedTime += 1000;
      const calculatedProgress = (elapsedTime / totalDuration) * 100;
      setProgress(calculatedProgress);

      //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
      const calculatedTimeRemaining1 = totalDuration - elapsedTime;
      const calculatedTimeRemaining = formatMilliseconds(
        calculatedTimeRemaining1
      );

      setTimeRemaining(calculatedTimeRemaining);

      if (elapsedTime >= totalDuration) {
        console.log("forreadyvideo");
        console.log("forreadyvideo");
        if (forreadyvideo == "") {
          console.log("video not ready");
          elapsedTime = 60000;
          setLoading(true);
        } else {
          localStorage.setItem("created-video-url", forreadyvideo);
          setTimeout(() => {
            clearInterval(interval);
            settimeCompleted(true);
            setProgress(100);
            // console.log("ready is");
            //  console.log(forreadyvideo);
            // navigate("/ready", { state: { forreadyvideo } });
            // window.location.reload(); // Refresh the page
            // Cookies.remove('scripttxt')
            // window.addEventListener('load', () => {
            //   // Reset the current state to a default value
            //   window.history.replaceState({}, document.title, window.location);
            // });
          }, 3000);
        }
      }
    }, 1500);

    // localStorage.setItem('alldata', JSON.stringify(data)) //to be used for re-render

    console.log("updatedData1234", data);
    const source = axios.CancelToken.source();
    setCancelToken(source);
    if (!user) {
      console.log('user');
      // if (user) {
      axios
        .post(
          "https://staging.yatter.io/webs/receiveimages.php",
          {
            data: data,
          },
          { cancelToken: source.token }
        )
        .then((response) => {
          console.log(
            "response.dataresponse.data",
            response?.data,
            response?.data?.video_url
          );
          if (!user) {
            localStorage.setItem("video_id", response?.data?.video_id);
            localStorage.setItem("video_url", response?.data?.video_url);
            // settimeCompleted(true);
          }
          if (response?.data?.video_url) {
            //setforready(response.data);
            localStorage.setItem("alldata", JSON.stringify(data));
            user && getUSerVideoCountHandle({ userid: user?.ID });
            localStorage.setItem(
              "created-video-url",
              response?.data?.video_url
            );
            setTimeout(() => {
              forreadyvideo = response?.data?.video_url;
              clearInterval(interval);
              settimeCompleted(true);
              setProgress(100);
              // console.log("ready is");
              //  console.log(forreadyvideo);
              const mixPanelVideo = async () => {
                user?.data?.ID && (await mixpanel.identify(user?.data.ID));
                await mixpanel.track("While video was getting ready- Step 3", {
                  Email: user?.data.user_email,
                  "User Id": user?.data.ID || "",
                  "User Type": user?.orders.order_type || "guest",
                  Video: forreadyvideo,
                  "User Script": userScript,
                  "AI Script": scriptout,
                });
                const searchParams = new URLSearchParams(
                  window.location.search
                );
                const utmSource = searchParams.get("utm_source");
                await mixpanel.track("Created Video", {
                  Email: user?.data.user_email,
                  "User Id": user?.data.ID || "",
                  "User Type": user?.orders.order_type || "guest",
                  UTM: utmSource,
                  Video: forreadyvideo,
                  "User Script": userScript,
                  "AI Script": scriptout,
                });
              };
              mixPanelVideo();
              if (user && forreadyvideo) {
                const filename = data && getFilenameFromURL(forreadyvideo);
                let fulllink =
                  "https://staging.yatter.io/webs/video/index.php?v=" +
                  filename;
                const emaildata = {
                  email: user?.data?.user_email,
                  url: fulllink,
                };
                console.log({ emaildata });
                console.log("user coming", user);
                sendEmail(emaildata);
              }
              // navigate("/ready", { state: { forreadyvideo, showMessage: true } });
              navigate("/dashboard", {
                state: { forreadyvideo, showMessage: true, call: true },
              });
              user && toast.success("Video sent successfully to your email");
              // Cookies.remove('scripttxt')
              // window.addEventListener('load', () => {
              //   // Reset the current state to a default value
              //   window.history.replaceState({}, document.title, window.location);
              // });
              // setTimeout(() => {
              //     window.location.reload(); // Refresh the page
              // }, 5);
            }, 3000);
          } else if (response?.data?.errmsg) {
            // navigate('/')
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
          if (timecompleted) {
            console.log("completed response");
            //const forreadyvideo=response.data;
            // setforready(response.data);
          } else {
            console.log("notcompleted yet");
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("API request canceled");
          } else {
            console.error("Error during the POST request", error);
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
        });
    } else {
      console.log('user1');
      const fetchItemsAndVideos = async () => {
        try {
          const payload = {
            textscript: videoScript,
            mobileimage: localStorage.getItem("mobileImg"),
          };
          const data = await axios.post(
            "https://staging.yatter.io/webs/getstability6imageapi.php",
            payload,
            { cancelToken: source.token }
          );
          // const items = itemsResponse?.data;
          // console.log("itemsResponse", data)
          const itemsResponse = data?.data;

          // Extract the relevant objects into a new array
          const items = itemsResponse?.map((item) => item.data[0]);
          console.log("Item Array:", items);
          localStorage.setItem("all_images", JSON.stringify(items));
          if (items?.length > 0) {
            const newItems = [...items];
            const numericKeys = Object.keys(newItems).filter(
              (key) => !isNaN(Number(key))
            );
            const newKey =
              numericKeys.length > 0
                ? Math.max(...numericKeys.map(Number)) + 1
                : 0;
            let newlink = {
              audio: "https://staging.yatter.io/webs/staticcontent/echo.mp3",
            };
            newItems[newKey] = newlink;
            const data = {
              bgMusicData: {
                music_name:
                  "https://staging.yatter.io/webs/staticcontent/backmusic8.mp3",
                music_switch: true,
                Volume: 25,
                music_name_custom: "",
              },
              white_particles: false,
              voice: true,
              Caption: {
                caption_switch: true,
                caption_color: "&HFFFFFF&",
                caption_align: "Bottom",
              },
              MobileVideo: localStorage.getItem("mobileImg"),
              Scripted: { text: videoScript },
              wpuser: {
                id: user?.ID,
                useremail: user?.data?.user_email,
                usertype: user?.orders?.order_type,
              },
              businessInfo: {
                image: "",
                email: "",
                number: "",
                website: "",
              },
            };
            const updatedData = { ...newItems, ...data };
            axios
              .post(
                "https://staging.yatter.io/webs/receiveimages.php",
                {
                  data: updatedData,
                },
                { cancelToken: source.token }
              )
              .then((response) => {
                console.log(
                  "response.dataresponse.data",
                  response?.data,
                  response?.data?.video_url
                );
                if (!user) {
                  localStorage.setItem("video_id", response?.data?.video_id);
                  localStorage.setItem("video_url", response?.data?.video_url);
                  // settimeCompleted(true);
                }
                if (response?.data?.video_url) {
                  //setforready(response.data);
                  localStorage.setItem("alldata", JSON.stringify(updatedData));
                  user && getUSerVideoCountHandle({ userid: user?.ID });
                  localStorage.setItem(
                    "created-video-url",
                    response?.data?.video_url
                  );
                  setTimeout(() => {
                    forreadyvideo = response?.data?.video_url;
                    clearInterval(interval);
                    settimeCompleted(true);
                    setProgress(100);
                    // console.log("ready is");
                    //  console.log(forreadyvideo);
                    const mixPanelVideo = async () => {
                      user?.data?.ID &&
                        (await mixpanel.identify(user?.data.ID));
                      await mixpanel.track(
                        "While video was getting ready- Step 3",
                        {
                          Email: user?.data.user_email,
                          "User Id": user?.data.ID || "",
                          "User Type": user?.orders.order_type || "guest",
                          Video: forreadyvideo,
                          "User Script": userScript,
                          "AI Script": scriptout,
                        }
                      );
                      const searchParams = new URLSearchParams(
                        window.location.search
                      );
                      const utmSource = searchParams.get("utm_source");
                      await mixpanel.track("Created Video", {
                        Email: user?.data.user_email,
                        "User Id": user?.data.ID || "",
                        "User Type": user?.orders.order_type || "guest",
                        UTM: utmSource,
                        Video: forreadyvideo,
                        "User Script": userScript,
                        "AI Script": scriptout,
                      });
                    };
                    mixPanelVideo();
                    if (user && forreadyvideo) {
                      const filename =
                        data && getFilenameFromURL(forreadyvideo);
                      let fulllink =
                        "https://staging.yatter.io/webs/video/index.php?v=" +
                        filename;
                      const emaildata = {
                        email: user?.data?.user_email,
                        url: fulllink,
                      };
                      console.log({ emaildata });
                      console.log("user coming", user);
                      sendEmail(emaildata);
                    }
                    // navigate("/ready", { state: { forreadyvideo, showMessage: true } });
                    navigate("/dashboard", {
                      state: { forreadyvideo, showMessage: true, call: true },
                    });
                    user &&
                      toast.success("Video sent successfully to your email");
                    // Cookies.remove('scripttxt')
                    // window.addEventListener('load', () => {
                    //   // Reset the current state to a default value
                    //   window.history.replaceState({}, document.title, window.location);
                    // });
                    // setTimeout(() => {
                    //     window.location.reload(); // Refresh the page
                    // }, 5);
                  }, 3000);
                } else if (response?.data?.errmsg) {
                  // navigate('/')
                  toast.error(
                    "Errors may happen because everyone wants to use Yatter AI at the same time. " +
                    "It seems it's taking longer than usual - go back to the beginning or " +
                    "send us a request to be on a free priority list at info@yatter.ai",
                    {
                      autoClose: 10000,
                    }
                  );
                }
                if (timecompleted) {
                  console.log("completed response");
                  //const forreadyvideo=response.data;
                  // setforready(response.data);
                } else {
                  console.log("notcompleted yet");
                }
              })
              .catch((error) => {
                if (axios.isCancel(error)) {
                  console.log('API request canceled');
                } else {
                  console.error("Error during the POST request", error);
                  toast.error(
                    "Errors may happen because everyone wants to use Yatter AI at the same time. " +
                    "It seems it's taking longer than usual - go back to the beginning or " +
                    "send us a request to be on a free priority list at info@yatter.ai",
                    {
                      autoClose: 10000,
                    }
                  );
                }
              });
            // navigate("/dashboard", { state: { items: updatedData } });
          } else {
            // navigate('/')
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
        } catch (itemsError) {
          if (axios.isCancel(itemsError)) {
            console.log("API request canceled");
          } else {
            console.error("Error fetching items:", itemsError);
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
        }
      };

      fetchItemsAndVideos();
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  /*
  
    useEffect(() => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            setCompleted(true);
            return 100;
          }
          return prevProgress + 10; // Increment progress
        });
    
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            return 0;
          }
          return prevTime - 1; // Decrement time remaining
        });
      }, 1000); // Update every second
    
      return () => clearInterval(interval); // Cleanup on component unmount
    }, []);
  
    */

  {
    /*
useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/ready');  // Path to navigate to after the delay
    }, 10000);  // Delay in milliseconds

    // Cleanup function to clear the timer if the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, [navigate]);  // Dependency array includes `navigate` to ensure stability
*/
  }
  const localvalue = localStorage.getItem("loglevel");
  //data[]=Array('localval')
  // const combineara = [...data, localvalue]
  //sendDataToWebService (combineara);
  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <>
      {/* <Topbar /> */}
      {/*<Layout HeaderName="Making your professional video">*/}

      <div
        className="page-container center-card-page astronauts"
        style={{
          background: "url(" + astronautbg + ") ",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          top: "0px",
          left: "0px",
          overflow: "hidden",
        }}
      >
        <img className="astronaut-1" src={astronaut1} alt="astronaut" />
        <img className="astronaut-2" src={astronaut2} alt="astronaut" />

        <div
          className={
            user ? "fullscreen-page loging-vid-block" : "fullscreen-page_logout"
          }
          style={{ position: "static" }}
        >
          <div
            className={
              user
                ? "short-creator-block "
                : "customStyleLogoutGetScript customStyleLogout short-creator-block"
            }
          >
            {/*<div>
            <div>Progress: {progress.toFixed(2)}%</div>
            <div style={{ width: '100%', backgroundColor: '#ddd', margin: '10px 0' }}>
            <div style={{ width: `${progress}%`, backgroundColor: 'green', height: '20px' }}></div>
            </div>
            {timeRemaining !== null && <div>Estimated Time Remaining: {timeRemaining.toFixed(2)} seconds</div>}
            {timecompleted && <div>Request Completed!</div>}
        </div>*/}

            {/* <div class="page-container fullscreen-page" style={{ justifyContent: "center", width: "100%" }}>
        <div class="video-progress">
          {
            !submit &&
            <>
              <h3 class="mb-6 text-center">Enter your email to receive video</h3>
              <div class="w-full mb-6">
                <div class="flex">
                  <input id="insert-script"
                    class="input-control mb-0"
                    placeholder="Enter email here"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="button" onClick={handleSubmit} class="btn btn-gradient ml-2" style={{ height: 45 }}>Continue</button>
                </div>
              </div>

            </>
          }
          {submit && <h3 class="mb-6 text-center">Your email will be sent when short video will ready</h3>}
          <div class="flex items-center w-full mb-4">
            <p class="font-semibold mb-0 f-14">Almost&nbsp;ready...</p>
            <div class="progress-bar mx-3">
              <div class="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <h4>{progress.toFixed(0)}%</h4>
          </div>

       

          <div class="flex items-center justify-center">
            <span class="text-gray mr-1">Estimated time:</span>
            {timeRemaining !== null && <span class="text-black font-semibold">{timeRemaining}</span>}
          </div>


          <div class="short-limit-info">
            <span class="material-icons">info</span>
            Please do not close tab before generate AI short
          </div>

        </div>
      </div> */}

            <div className="videospecific">
              {/* <h3 class="mb-6 text-center">AI short is almost ready...</h3>
              <h3 class="mb-6 text-center">Your customized video is almost ready...</h3>

               <div class="flex items-center w-full mb-4">
            <p class="font-semibold mb-0 f-14">Almost&nbsp;ready...</p>
            <div class="progress-bar mx-3">
              <div class="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <h4>{progress.toFixed(0)}%</h4>
          </div>
    */}

              <div class="mb-6">
                <img
                  class="mx-auto mb-6 logo-60"
                  src={`${process.env.PUBLIC_URL}/images/logo.png`}
                  alt="video"
                />
              </div>

              <h3 class="mb-6 text-center">
                Your customized video is almost ready...
              </h3>

              <div class="flex items-center w-full mb-4">
                <div class="progress-bar mr-4">
                  <div class="progress" style={{ width: `${progress}%` }}></div>
                </div>
                <h4>{progress.toFixed(0)}%</h4>
              </div>

              <div class="flex items-center justify-center mb-6">
                <span class="text-gray mr-1">Estimated time:</span>
                {timeRemaining !== null && (
                  <span class="text-black font-semibold">{timeRemaining}</span>
                )}
              </div>

              <div class="danger-info">
                <span class="material-icons icon">info</span>
                Do not close this tab! You could lose your video completely.
              </div>

              {/* {loading ? <div className="waitlong">Validating....</div> : ''} */}
            </div>
          </div>
          <br />

          {deviceType === "desktop" && <Dino />}
          {/* <Dino /> */}
        </div>
      </div>
      {/* <Footer/> */}
      {/*</Layout>*/}
    </>
  );
}

export default GetVideo;
