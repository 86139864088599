import React, { useEffect, useState } from 'react';

function Modal({ isOpen, onClose, imgSrc }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 580);
  const videoExtensions = [".mp4", ".webm", ".avi", ".mov", ".mkv", ".flv"];
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 580);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (!isOpen) return null;
  return (
    <div style={styles.overlay} onClick={onClose}>
       <div style={isMobile ? styles.modalMobile : styles.modal} onClick={handleModalContentClick}>
        {
        videoExtensions?.some((ext) => imgSrc?.includes(ext)) ?  <video
        src={imgSrc}
        // src={"https://videos.pexels.com/video-files/7579667/7579667-hd_720_1366_25fps.mp4"}
        style={isMobile ? styles.mediaMobile : styles.image}
        type="video/mp4"
        controls
      ></video> :
        <img src={imgSrc} alt="Modal" 
        style={isMobile ? styles.mediaMobile : styles.image}
         />
        }
        <button onClick={onClose} style={styles.closeBtn}>
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" style={{ color: '#000' }}>
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          </button>
      </div>
      </div>

  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modal: {
    position: 'relative',
    background: '#FFF',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '80%',
    maxHeight: '80%',
    overflowY: 'auto',
    overflowX:'hidden'
    
  },
  modalMobile: {
    position: 'relative',
    background: '#FFF',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '90%',
    height: '90%',
    maxWidth: '600px',
    maxHeight: '80%',
    overflow: 'hidden',
  },
  closeBtn: {
    position: 'absolute',
    top: '0px',
    right: '10px',
    color:'red',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '4px',
  },
  mediaMobile: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: '4px',
  }
};

export default Modal;