import React from 'react'
import Layout from '../../components/Layout/Layout'

const UploadImage = () => {
    return (
        <Layout HeaderName='Make An AI Video'>
            <div class="page-container">
                <div class="page-wrapper">

                    <div class="container-xl mb-8">
                        <div class="ai-icon-animation mx-auto mb-2">
                            <img class="ai-icon-1" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                            <img class="ai-icon-2" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                            <img class="ai-icon-3" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                            <img class="ai-icon-4" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                        </div>

                    </div>

                    <h4 class="text-center mb-8">Your own choosen images</h4>

                    <div class="container-lg">
                        <div class="generated-images justify-center">

                            <div class="generated-img-block">
                                <div class="animated-border"></div>
                                <img src="images/generated-img-1.jpg" alt="Generated Image" />

                                <div class="custom-tooltip regenerate-action active">
                                    <div class="text-center">
                                        <div class="tooltip-footer-action mt-0">
                                            <button type="button" class="btn btn-sm btn-text-icon preview"><span class="material-symbols-outlined mr-2"> visibility </span> Preview</button>
                                            <button type="button" class="btn btn-sm btn-text-icon delete"><span class="material-symbols-outlined mr-2"> delete </span> Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="generated-img-block">
                                <div class="animated-border"></div>
                                <img src="images/generated-img-2.jpg" alt="Generated Image" />

                                <div class="custom-tooltip regenerate-action active">
                                    <div class="text-center">
                                        <div class="tooltip-footer-action mt-0">
                                            <button type="button" class="btn btn-sm btn-text-icon preview"><span class="material-symbols-outlined mr-2"> visibility </span> Preview</button>
                                            <button type="button" class="btn btn-sm btn-text-icon delete"><span class="material-symbols-outlined mr-2"> delete </span> Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="generated-img-block">
                                <div class="animated-border"></div>
                                <img src="images/generated-img-3.jpg" alt="Generated Image" />

                                <div class="custom-tooltip regenerate-action active">
                                    <div class="text-center">
                                        <div class="tooltip-footer-action mt-0">
                                            <button type="button" class="btn btn-sm btn-text-icon preview"><span class="material-symbols-outlined mr-2"> visibility </span> Preview</button>
                                            <button type="button" class="btn btn-sm btn-text-icon delete"><span class="material-symbols-outlined mr-2"> delete </span> Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>

                        <div class="generated-images justify-center">

                            <div class="generated-img-block blank">
                                <div class="text-center">
                                    <span class="material-symbols-outlined text-gray mb-4">cloud_upload</span>
                                    <p class="text-gray m-0">Add another image</p>
                                </div>
                            </div>

                        </div>

                        <div class="flex justify-center">
                            <button type="button" class="btn btn-gradient btn-text-icon mb-2"><span class="material-icons f-24 mr-2">auto_awesome</span>Generate my video</button>
                        </div>
                    </div>

                </div>
            </div>
        </Layout >
    )
}

export default UploadImage