import axios from "axios";

export const getAiScriptapi = async (textscript) => {
  try {
    let scriptrum= Math.random() * 100;
      const response = await axios.post(
      `https://staging.yatter.io/webs/getstabilityscriptapi.php?sai=${scriptrum}`,
      textscript
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};