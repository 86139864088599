import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import { cursortodefault, cursortowait } from "../../services/chatGPTService";
import { signup } from "../../Api/Auth/signup";
import { updateVideo } from "../../Api/updateVideo/updateVideo";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import { login } from "../../Api/Auth/login";
import { useGoogleLogin } from "@react-oauth/google";
import { googleAuth } from "../../Api/googleAuth/googleAuth";
import { loginWithGoogle } from "../../Api/googleAuth/loginWithGoogle";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const FreeTrialPopup = ({ isOpenPop, setIsOpenPop, children,setIsFreeTrialSuccessPopupOpen }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    // if (formData.username.trim() === "") {
    //   errors.username = "Email is required.";
    // }

    // if (formData.password.trim() === "") {
    //   errors.password = "Password is required.";
    // }
    // if (selectedOption.trim() === "") {
    //   errors.selectedOption = "Please select where you heard about us";
    // }
    // if (Object.keys(errors).length > 0) {
    //   setFormErrors(errors);
    //   return;
    // }
    try {
      // Call the signup function with the form data
      cursortowait();
      const response = await signup(formData);
      // Handle successful signup (you can redirect to login page or show a success message)
      console.log(
        "Signup successful:",
        response,
        "response?.data",
        response?.data
      );
      if (response?.data) {
        console.log("response?.data?.ID", response?.data?.ID);
        const userId = response?.data?.ID;
        const videoId = localStorage.getItem("video_id");
        //  setShowModal(true)
        const data = await updateVideo(userId, videoId);
        if (data == "successfully updated") {
          // navigate("/confirmation");
          /*let detailsu=response.data;
              navigate("/confirmation", { state: { detailsu } });*/
          userId && (await getUSerVideoCountHandle({ userid: userId }));
          const userResponse = await login(formData);
          console.log("userResponse", userResponse);
          if (userResponse?.data?.data) {
            await getUSerVideoCountHandle({ userid: userResponse?.data?.ID });
            const userData = { ...userResponse.data };
            delete userData.data.user_pass;
            delete userData.data.user_activation_key;
            console.log("response", userData);
            localStorage.setItem("user", JSON.stringify(userData));
            await mixpanel.identify(userData?.data?.ID);
            await mixpanel.people.set({
              $email: userData?.data?.user_email,
              $name: userData?.data?.user_email,
              "User Id": userData?.data?.ID,
            });
            await mixpanel.track("Sign Up to get your video", {
              Email: userData?.data?.user_email,
              "Sign Up Platform": "Website Form",
              "User Id": userData?.data?.ID,
            });
            await mixpanel.track("Free trial activated?", {
              Email: userData?.data?.user_email,
              "Free trial": "Yes",
            });
            localStorage.removeItem("video_id");
            localStorage.removeItem("video_url");
            setIsOpenPop(false)
            setIsFreeTrialSuccessPopupOpen(true)
            // navigate("/dashboard");
            // toast.success("Free trial activated");
            console.log("Login success");
            // window.location.reload();
          }
        } else {
          console.log(data);
        }
        //navigate("/login");
      } else if (response?.message === "User already exists.") {
        // toast.error("User already exists.");
        const userResponse = await login(formData);
        console.log("userResponse", userResponse);
        if (userResponse?.data?.data) {
          const userData = { ...userResponse.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          const userId = userData?.ID;
          const videoId = localStorage.getItem("video_id");
          const data = await updateVideo(userId, videoId);
          if (data == "successfully updated") {
            userId && (await getUSerVideoCountHandle({ userid: userId }));
            await mixpanel.identify(userData?.data?.ID);
            await mixpanel.people.set({
              $email: userData?.data?.user_email,
              $name: userData?.data?.user_email,
              "User Id": userData?.data?.ID,
            });
            await mixpanel.track("Sign Up to get your video", {
              Email: userData?.data?.user_email,
              "Sign Up Platform": "Website Form",
              "User Id": userData?.data?.ID,
            });
            await mixpanel.track("Free trial activated?", {
              Email: userData?.data?.user_email,
              "Free trial": "Yes",
            });
            localStorage.setItem("user", JSON.stringify(userData));
            // navigate("/dashboard");
            // toast.success("Free trial activated");
            setIsOpenPop(false)
            setIsFreeTrialSuccessPopupOpen(true)
            localStorage.removeItem("video_id");
            localStorage.removeItem("video_url");
            // window.location.reload();
          } else {
            console.log({ error: data });
          }
        }
      }
    } catch (error) {
      toast.error(error);
    }
    cursortodefault();
  };
  const googleSignup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      cursortowait();
      console.log({ codeResponse });
      // if (selectedOption.trim() === "") {
      //   return toast.error("Please select where you heard about us");
      // }
      const data = await googleAuth(codeResponse);
      if (data && data?.email) {
        console.log({ profile: data });
        const body = {
          email: data?.email,
          name: data?.name,
          signuplast: "yes",
        };
        const response = await loginWithGoogle(body);
        // Handle successful signup (you can redirect to login page or show a success message)
        console.log(
          "Signup successful:",
          response,
          "response?.data",
          response?.data
        );
        if (response?.data) {
          console.log("response?.data?.ID", response?.data?.ID);
          const userId = response?.data?.ID;
          const videoId = localStorage.getItem("video_id");
          //  setShowModal(true)
          const data = await updateVideo(userId, videoId);
          if (data === "successfully updated") {
            userId && (await getUSerVideoCountHandle({ userid: userId }));
            console.log("userResponse", response);
            const userData = { ...response?.data };
            delete userData.data.user_pass;
            delete userData.data.user_activation_key;
            console.log("response", userData);
            localStorage.setItem("user", JSON.stringify(userData));
            await mixpanel.identify(userData?.data?.ID);
            await mixpanel.people.set({
              $email: userData?.data?.user_email,
              $name: userData?.data?.user_email,
              "User Id": userData?.data?.ID,
            });
            await mixpanel.track("Sign Up to get your video", {
              Email: userData?.data?.user_email,
              "Sign Up Platform": "Google",
              "User Id": userData?.data?.ID,
            });
            await mixpanel.track("Free trial activated?", {
              Email: userData?.data?.user_email,
              "Free trial": "Yes",
            });
            // navigate("/dashboard");
            // toast.success("Free trial activated");
            setIsOpenPop(false)
            setIsFreeTrialSuccessPopupOpen(true)
            console.log("Login success");
            localStorage.removeItem("video_id");
            localStorage.removeItem("video_url");
            // window.location.reload();
          } else {
            console.log(data);
          }
        }
      }
      cursortodefault();
    },
    onError: (error) => toast.error(error),
  });

  //always keep it at end
  if (!isOpenPop) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
      style={{ zIndex: 6 }}
    >
      <div
        className="reserve-my-spot-instantly-popup"
        // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
        style={{ backgroundColor: "white" }}
      >
        <button
          type="button"
          class="btn-close-icon"
          onClick={() => {
            setIsOpenPop(false);
          }}
        >
          <span class="material-icons"> close </span>
        </button>
        <div className="custom-modal success-modal sm !mt-4">
          <div className="mb-6 text-center">
            <div className="premium-circle-icon mx-auto mb-4">
              <img
                src={require("../../images/premium.svg").default}
                alt="Premium"
              />
            </div>
            <h2 className="uppercase">Sign up for a 14-day Free Trial</h2>
          </div>

          <div onClick={() => googleSignup()}>
            <button
              type="button"
              className="btn btn-black-ouline btn-text-icon w-full rounded-btn"
            >
              <img
                className="mr-2"
                src={require("../../images/google.svg").default}
                alt="Google"
              />{" "}
              Sign up with Google
            </button>
          </div>

          <div className="or-divider text-center">
            <span>OR</span>
          </div>

          <form className="mb-6" onSubmit={handleFormSubmit}>
            <div className="input-group">
              <input
                type="username"
                className="input-control"
                placeholder="Enter your email"
                name="username"
                value={formData?.username}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-group">
              <input
                type={passwordVisible ? "text" : "password"}
                className="input-control"
                placeholder="Enter your password"
                name="password"
                value={formData?.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="btn-icon show-pwd"
                onClick={togglePasswordVisibility}
              >
                <span className="material-icons text-gray f-20">
                  visibility
                </span>
              </button>
            </div>
            <div className="input-group text-center mb-0">
              <button type="submit" className="btn btn-gradient">
                Sign up now
              </button>
            </div>
          </form>

          <span className="text-gray flex justify-center items-center f-14">
            <span className="material-symbols-outlined f-18 mr-2">info</span>
            No credit card required
          </span>
        </div>
      </div>
    </div>
    
  );
};
export default FreeTrialPopup;
