import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { uploadImageGenerate } from "../../services/SendImageService";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { cursortodefault, cursortowait } from "../../services/chatGPTService";

const BusinessInfo = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const businessData = JSON.parse(localStorage.getItem('businessData'))
  const user = JSON.parse(localStorage.getItem("user"));
  const scriptout = Cookies.get("scripttxt");
  const [imageUrl, setImageUrl] = useState(null);
  const [formData, setFormData] = useState({
    image: businessData?.image || '',
    email: businessData?.email || '',
    number: businessData?.number || '',
    website: businessData?.website || ''
  });
  const [error, setError] = useState("")
  console.log({formData})
  function getFileExtension(fileName) {
    // return fileName
    // Use a regex pattern to find the extension
    if(fileName){
        var pattern = /\.([a-zA-Z0-9]+)$/;
        var match = fileName?.match(pattern);
        if (match) {
        return match[1];
        } else {
        return null;
            }
    }
  }
  const locationData = location?.state?.items || [];
  console.log("locationData", locationData)
  const handleAddFileChange = async (event) => {
    cursortowait()
    const allowedTypes = ["jpg", "jpeg", "png", "webp"];
    const file = event?.target?.files[0];
    const extension = file && await getFileExtension(file.name);

    if (allowedTypes.includes(extension)) {
      let data = new FormData();
      data.append("image", file);

      const imageResp = await uploadImageGenerate(data, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
      });
      setImageUrl(imageResp?.url);
      setFormData(prev => ({ ...prev, image:imageResp?.url }));
    }
    cursortodefault()
  };
  const openFilePicker = () => {
      document.getElementById("fileInput").click();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    setFormData({
      ...formData,
      [name]: value
    });
    if(name === 'email'){
      setError('')
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (
    //   formData?.email.trim() !== "" &&
    //   !emailRegex.test(formData?.email)
    // ) {
    //   setError("Please enter a valid email address.");
    //   return
    // }
    console.log('imageUrl', imageUrl)
    const wpuser = {
      id: user?.ID,
      useremail: user?.data?.user_email,
      usertype: user?.orders?.order_type,
    };
    console.log({scriptout});
    const Scripted = { text: scriptout };
    const updatedData = { ...locationData, Scripted, wpuser, businessInfo: formData };
    console.log("updated data in business info",{updatedData})
    localStorage.setItem('businessData', JSON.stringify(formData))
    localStorage.setItem('alldata', JSON.stringify(updatedData))
    localStorage.setItem("generateVideo", true)
    navigate("/dashboard", { state: { items: updatedData,call:true } });
  }
  return (
    <>
      <Layout HeaderName="Make an AI Video">
        <div
          className={
            user
              ? "fullscreen-page fullpageimg"
              : "fullscreen-page_logout fullpageimg"
          }
          style={{ overflow: user ? "auto" : "hidden" }}
        >
          <div
            className={
              user
                ? "short-creator-block customStyle"
                : "customStyleLogoutGetScript customStyleLogout short-creator-block"
            }
          >
            <div className="creator-wrapper">
              <h3 className="mb-4">
                Add your business info{" "}
                <span class="text-red f-16 font-bold">(Optional)</span>
              </h3>
              <p className="mb-4 flex items-center">
                Personalize all your videos automatically for your business
              </p>

              <form className="creator-form">
                <div className="business-info-wrap mb-5">
                  <div className="business-logo generated-img-block blank cursor-pointer"
                  onClick={openFilePicker}>
                    {!formData?.image && (
                      <div
                        className="text-center"
                      >
                        <span className="material-symbols-outlined text-gray mb-4">
                          cloud_upload
                        </span>
                        <p className="text-gray m-0">Upload my own</p>
                      </div>
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => handleAddFileChange(event)}
                    />

                    {formData?.image && (
                      <div style={{maxHeight:'100%', maxWidth:'100%',overflow:'hidden'}}>
                        <img src={formData?.image} alt="Uploaded" style={{maxHeight:'100%'}}/>
                      </div>
                    )}
                  </div>

                  <div className="contact-info">
                    <input type="email" className="input-control white" name="email" value={formData?.email} onChange={handleChange} placeholder="Business email" />
                    {error && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-1.25rem",
                      marginBottom: "1.25rem",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {error}
                  </p>
                )}
                    <input type="text" className="input-control white" name="number" value={formData?.number} onChange={handleChange} placeholder="Business number" />
                    <input type="text" className="input-control white mb-0" name="website" value={formData?.website} onChange={handleChange} placeholder="Website Link" />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <button type="button" className="btn btn-flat btn-text-icon" onClick={() => {
                    localStorage.setItem('businessData', JSON.stringify(formData))
                    navigate('/get-audio')}}>
                    <span className="material-symbols-outlined mr-2 f-20">
                      {" "}
                      arrow_back{" "}
                    </span>
                    Go Back
                  </button>
                  <button type="button" className="btn btn-gradient"  onClick={handleSubmit}>
                    Generate short
                  </button>
                </div>
              </form>
            </div>
            {/* creator-wrapper */}

            <div className="advice-wrapper">
              <div className="my-plan-wrap">
                <div className="my-plan-card gradient-plan-card">
                  <div className="head">
                    <span className="premium-icon">
                      <img src={require('../../images/premium.svg').default} alt="Premium" />
                    </span>
                    <h5>
                      Yatter Premium{" "}
                      <span className="font-normal">lets you:</span>
                    </h5>
                  </div>
                  <div className="mb-2">
                    <ul className="list-wrapper text-left">
                      <li className="list-item">
                        <span className="material-symbols-outlined f-18 mr-2 text-white">
                          task_alt
                        </span>{" "}
                        <span>
                          <span className="f-14 font-normal">
                            Add your{" "}
                            <span className="font-bold">custom music</span>
                          </span>
                        </span>
                      </li>
                      <li className="list-item">
                        <span className="material-symbols-outlined f-18 mr-2 text-white">
                          task_alt
                        </span>{" "}
                        <span>
                          <span className="f-14 font-normal">
                            Add your{" "}
                            <span className="font-bold">own voice</span>
                          </span>
                        </span>
                      </li>
                      <li className="list-item">
                        <span className="material-symbols-outlined f-18 mr-2 text-white">
                          task_alt
                        </span>{" "}
                        <span>
                          <span className="f-14 font-normal">
                            Add{" "}
                            <span className="font-bold">custom captions</span>
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-white btn-sm w-full"
                    >
                      Coming Soon
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BusinessInfo;
