import axios from "axios";

export const sendFeedBack = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/yatter-send/v1/emailsend_new`,data
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};