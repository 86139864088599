import axios from "axios";

export const signup = async (userData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/yatter-register/v1/register`,
      userData
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
