import React from "react";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
//  import './PricingPlan.css';
import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const FreePlan = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleUpgrade = async () => {
    user?.data?.ID && await mixpanel.identify(user?.data.ID);
    await mixpanel.track("Enable the 1-month premium trial", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || '',
      'User Type': user?.orders.order_type || 'guest',
    });
    navigate('/pricing-plan')
  };
  return (
    <Layout HeaderName="Free Plan">
      <div className="page-container">
      <div class="page-wrapper">
        <div class="card-wrap affiliate-program">
          <div class="details-wrap text-center">

            <h4 class="text-gray flex items-center justify-center mb-6"><span class="material-icons f-20 mr-2"> warning </span> Warning, you're about to make amazing videos that people love!</h4>

            <h1 class="flex items-center justify-center flex-wrap mb-6"><span class="font-normal">Start my</span> <span class="flex items-center justify-center"><img class="mx-3" src="images/premium.svg" style={{height: "30px"}} alt="Premium"/> 1-month Premium Trial</span></h1>

            <h4 class="font-normal mb-6">To generate instant AI videos with <span class="font-bold">ABSOLUTE freedom</span> & no watermark for your viewers.</h4>
            
            <p class="f-18 mb-6">Get it now for just <strike class="text-gray">$19.99</strike> <span class="gradient-text font-bold">$9.99</span><span class="f-14 font-bold">/month</span></p>

            <div class="d-flex justify-center mb-6">
              <div class="highlighted-card inline-block">
                <div class="flex items-center flex-wrap f-18 mb-6"><span class="yatter-premium"><img class="premium-icon" src="images/yatter-premium.svg" alt="Yatter Premium"/> Yatter Premium</span> <span class="ml-2">gives you:</span></div>
    
                <ul class="features-list text-left">
                  <li class="feature-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Get <span class="font-bold">50+ instant AI</span> videos every month!</span></li>
                  <li class="feature-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span><span class="font-bold">Make $$ </span> as an Affiliate <span class="font-bold">(coming soon)</span></span></li>
                  <li class="feature-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Get <span class="font-bold">No watermark.</span> Absolute freedom.</span></li>
                </ul>
              </div>
            </div>

            <button type="button" class="btn btn-gradient btn-xl mx-auto" onClick={handleUpgrade}>Enable my 1-month trial</button>

          </div>
        </div>
      </div>
      </div>
    </Layout>
  );
};

export default FreePlan;