import React, { useContext, useEffect, useState } from "react";
import { login } from "../../Api/Auth/login";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { googleAuth } from "../../Api/googleAuth/googleAuth";
import { useGoogleLogin } from "@react-oauth/google";
import { loginWithGoogle } from "../../Api/googleAuth/loginWithGoogle";
import { cursortowait, cursortodefault } from "../../services/chatGPTService";
import mixpanel from "mixpanel-browser";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import MetaTags from "../../components/MetaTags/MetaTags";
import { VideoGenerationContext } from "../../VideoGenerationContext";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

export default function Login() {
  const { setUser } = useContext(VideoGenerationContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  // const [ user, setUser ] = useState([]);
  // const [ profile, setProfile ] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Reset the error message for the field when the user starts typing
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  function navigateAndRefresh(whereto) {
    navigate(whereto);
    window.location.reload();
  }

  const handleLogin = async () => {
    console.log("coming In");
    const errors = {};
    if (formData?.username.trim() === "") {
      errors.username = "Email is required.";
    }
    if (formData?.password.trim() === "") {
      errors.password = "Password is required.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      formData?.username.trim() !== "" &&
      !emailRegex.test(formData?.username)
    ) {
      errors.username = "Please enter a valid email address.";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      cursortowait();
      const response = await login(formData);
      // console.log("response", response);sss
      if (response?.data?.data) {
        await mixpanel.identify(response?.data?.data.ID);
        await mixpanel.people.set({
          $email: response?.data?.data?.user_email,
          $name: response?.data?.data?.user_email,
          "User Id": response?.data?.data?.ID,
        });
        await mixpanel.track("Login", {
          Email: response?.data?.data.user_email,
          "Login Platform": "Website Form",
          "User Id": response?.data?.data.ID,
        });
        const userData = { ...response.data };
        delete userData.data.user_pass;
        delete userData.data.user_activation_key;
        console.log("response", userData);
        localStorage.setItem("user", JSON.stringify(userData));
        setUser(userData);
        await getUSerVideoCountHandle({ userid: userData?.ID });
        setTimeout(() => {
          navigate("/explore");
          // toast.success("Login success");
          console.log("Login success");
          cursortodefault();
        }, 2000);
      } else if (response?.data?.error) {
        //toast.error(response?.data?.error);
        toast.error("Wrong login credentials.");
        cursortodefault();
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      cursortodefault();
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      cursortowait();
      console.log({ codeResponse });
      const data = await googleAuth(codeResponse);
      if (data && data?.email) {
        console.log({ profile: data });
        const body = { email: data?.email, name: data?.name };
        const response = await loginWithGoogle(body);
        if (response?.data?.data) {
          const userData = { ...response.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          await mixpanel.identify(userData?.data?.ID);
          await mixpanel.people.set({
            $email: userData?.data?.user_email,
            $name: userData?.data?.user_email,
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Login", {
            Email: userData?.data?.user_email,
            "Sign Up Platform": "Google",
            "User Id": userData?.data?.ID,
          });
          localStorage.removeItem("video_id");
          localStorage.removeItem("video_url");
          await getUSerVideoCountHandle({ userid: userData?.ID });
          setTimeout(() => {
            navigate("/dashboard");
            toast.success("Login success");
            console.log("Login success");
            cursortodefault();
          }, 2000);
        } else if (response?.data?.error) {
          //toast.error(response?.data?.error);
          toast.error("Something went wrong.");
          console.log({ error: response });
          cursortodefault();
        }
      }
    },
    onError: (error) => {
      toast.error(error);
      cursortodefault();
    },
  });

  // useEffect(
  //   () => {
  //      const googleUserAuth = async()=>{
  //       try {
  //           const data=await googleAuth(user);
  //           setProfile(data)
  //     } catch (error) {
  //       toast.error(error);
  //     }
  //   }
  //   googleUserAuth()

  // },[ user ]);

  return (
    <div className="authentication-wrap">
      <MetaTags
        keyword="Login here"
        description="Login here into our free AI video generator. Yatter is the #1 text-to-video AI for creators and businesses. Access your account here."
      />

      <div className="mb-6 text-center">
        <img
          className="logo mx-auto"
          src="../../images/logo.png"
          alt="Yatter"
        />
      </div>

      <div className="authentication-block">
        <video className="video-carousel" autoPlay muted loop>
          <source
            src={require("../../images/yatter-video-carousel-3.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="authentication-card text-center">
          <div className="head">
            <h1 className="text-red uppercase">Login</h1>
            <h4>
              Login to create professional quality videos under <br /> 3 mins.
              Guaranteed.
            </h4>
          </div>

          <div className="body">
            <div>
              <button
                type="button"
                className="btn btn-black-ouline btn-text-icon w-full rounded-btn"
                onClick={() => googleLogin()}
              // disabled
              >
                <img
                  className="mr-2"
                  src={require("../../images/google.svg").default}
                  alt="Google"
                />{" "}
                Login with Google
              </button>
            </div>

            <div className="or-divider">
              <span>OR</span>
            </div>

            <form
              action="#"
              onKeyDown={(e) => {
                if (e.key === "Enter") handleLogin();
              }}
            >
              <div className="input-group">
                <input
                  type="email"
                  name="username"
                  className="input-control"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required={true}
                />
                {formErrors.username && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-1.25rem",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {formErrors.username}
                  </p>
                )}
              </div>
              <div className="input-group">
                <input
                  type="password"
                  name="password"
                  className="input-control"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required={true}
                />
                {formErrors.password && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-1.25rem",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {formErrors.password}
                  </p>
                )}
              </div>
              <div className="input-group">
                <button
                  type="button"
                  className="btn btn-gradient"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </form>
            <div className="text-center">
              <button
                onClick={() => navigateAndRefresh("/forgot-password")}
                className="f-14 text-red font-medium"
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <p className="f-18 mb-0 font-medium">
          Don't have an account?{" "}
          <button
            className="font-bold text-red"
            onClick={() => navigateAndRefresh("/signup")}
          >
            Sign up
          </button>
        </p>
      </div>

      <div className="perks-wrap">
        <div className="perk-block">
          <span className="material-icons icon gradient-text f-24 mr-2">
            thumb_up_alt
          </span>
          <span>
            Selected by hundreds of{" "}
            <span className="font-bold">video experts</span>
          </span>
        </div>
        <div className="perk-block">
          <span className="material-icons icon gradient-text f-24 mr-2">
            star
          </span>
          <span>
            <span className="font-bold">4.9 stars</span> based on 1,309 reviews
          </span>
        </div>
      </div>
    </div>
  );
}
