import React, { useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { LeftNav } from "../LeftNav/LeftNav";

const Layout = ({ HeaderName, children, hideFooter }) => {
  // const [user, setUser] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  // useEffect(() => {
  //   const data = localStorage.getItem("user");
  //   setUser(data);
  // }, []);
  return (
    <>
      {/* <div className="top-info-bar">
        <div className="flex items-center f-14 font-weight-medium">
          <span className="material-icons text-orange mr-2"> warning </span>
          If you leave Yatter AI, you may loose your stored videos. Sign up to
          keep using your account.
        </div>
      </div> */}
      <div
        style={{
          backgroundColor: "#fef4f1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        <div>
          <Header HeaderName={HeaderName} />
          {user ? <LeftNav /> : ""}
          {children}
        </div>
        {!hideFooter && (
          <div>
            <Footer style={user ? { marginLeft: "280px" } : {}} />
          </div>
        )}
      </div>
    </>
  );
};

export default Layout;
