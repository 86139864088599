import axios from 'axios';

export const loginWithGoogle = async (loginData) => {
    console.log("coming In loginWithGoogle function");
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/yatter-getcustomer/v1/getcustomer`, loginData);
      return response;
    } catch (error) {
        return error
    }
  };