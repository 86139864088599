import React, { useState, useEffect, useRef, useContext } from "react";
import Layout from "../../components/Layout/Layout";
import userImage from "../../images/user3.png";
import { Footer } from "../../components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import trash from "../../images/trash.svg";
import {
  getGenerateImage,
  uploadImageGenerate,
  GetAudiofromWebService,
  uploadMusicGenerate,
} from "../../services/SendImageService";
import Modal from "../../Modal";
import Cookies from "js-cookie";
import {
  cursortodefault,
  cursortowait,
  generateImageSingle,
} from "../../services/chatGPTService";
import mixpanel from "mixpanel-browser";
import { getUserVideoCount } from "../../Api/getUserVideoCount/getUserVideoCount";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import { getStockImages } from "../../Api/getStockImages/getStockImages";
import { getStockVideos } from "../../Api/getStockVideos/getStockVideos";
import { getThreeAiImages } from "../../Api/getThreeAiImages/getThreeAiImages";
import axios from "axios";
import FreeTrialPopup from "../../components/RerenderSignup/FreeTrialPopup";
import { toast } from "react-toastify";
import { VideoGenerationContext } from "../../VideoGenerationContext";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const Rerender = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchVideos, setInitialVideos, updateInitialVideos, initialVideos } =
    useContext(VideoGenerationContext);
  const data1 = localStorage.getItem("pexel_videos");
  const data = JSON.parse(localStorage.getItem("alldata"));
  const userScript = localStorage.getItem("user_script");
  const subScript = JSON.parse(localStorage.getItem("getFormData"));
  console.log("userScript", subScript?.subformData?.question);
  console.log("data", data1);
  const allImagesData = JSON.parse(localStorage.getItem("all_images"))?.filter(
    (item) => item?.url && item?.url?.trim() !== ""
  );
  let imagecount = allImagesData?.length;
  let getaudiosourcecount = imagecount + 1;
  console.log("images c", imagecount);
  const [Script, setScript] = useState(
    subScript?.subformData?.question || data?.Scripted?.text
  );
  const [voice, setVoice] = useState(true);
  const [music, setMusic] = useState(data?.bgMusicData?.music_switch);
  const [voiceId, setVoiceId] = useState();
  const [Cusbgmusicfilename, setCusbgmusicfilename] = useState("");
  const [selectedId, setSelectId] = useState(data?.bgMusicData?.music_id);
  //const [volume, setVolume] = useState(data.bgMusicData.volume); // Default volume is 100%
  const [volume, setVolume] = useState(25); // Default volume is 100%
  const audioRef = useRef(null); // Reference to the audio element
  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
  const [playmusic, setPlayMusic] = useState(false);
  const [isPopupdownload, setIsPopupdownload] = useState(null);
  // console.log(data.Scripted.text);
  const audioRefff = useRef(null); // Reference to the audio element
  const [cusMusicUpl, setcusMusicUpl] = useState("");
  const [caption, setCaption] = useState({
    caption_switch: data?.Caption?.caption_switch,
    caption_color: data?.Caption?.caption_color,
    caption_align: data?.Caption?.caption_align,
  });
  const [audioTimeout, setAudioTimeout] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [ifImgIndex, setIfImgIndex] = useState(null);
  const [ifPopUpdown, setIfPopUpdown] = useState(1);
  const [ifImgIndexText, setIfImgIndexText] = useState(null);
  const [addAnotherImagePopup, setAddAnotherImagePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAiScript, setShowAiScript] = useState(false);
  const [stockDataSuccess, setStockDataSuccess] = useState(false);
  const [isTabOpen, setIsTabOpen] = useState("image");
  const [stockImages, setStockImages] = useState([]);
  const [stockVideos, setStockVideos] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const [isSelectedEmpty, setIsSelectedEmpty] = useState(false);
  const [uploadCustomProgress, setUploadCustomProgress] = useState(0);
  const [showDetails, setShowDetails] = useState("");
  const [chooseImageTooltip, setChooseImageTooltip] = useState(false);
  const [chooseImageTooltipid, setChooseImageTooltipid] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const formDataString = localStorage.getItem("getFormData");
  const user = JSON.parse(localStorage.getItem("user"));
  const [items, setitems] = useState([]);
  const getformData = formDataString ? JSON.parse(formDataString) : {};
  const [regeneratecount, setregeneratecount] = useState(0);
  const [generatecount, setGeneratecount] = useState(0);
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const [modalImage, setModalImage] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [addhoveredIndex, setAddHoveredIndex] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadAddProgress, setUploadAddProgress] = useState(null);
  const [regenProgress, setReGenProgress] = useState(null);
  const [regenerate, setRegenerate] = useState(null);
  const [regenerateLoader, setRegenerateLoader] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(null);
  const [uploadSelfLoader, setUploadSelfLoader] = useState(null);
  const [uploadSelfProgress, setUploadSelfProgress] = useState(null);
  const [uploadAddLoader, setUploadAddLoader] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState("0:00");
  const [timecompleted, settimeCompleted] = useState(false);
  const [firstRegenerate, setFirstRegenerate] = useState(false);
  const [hover, setHover] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [progress, setProgress] = useState(0);
  const [checkData, setCheckData] = useState();
  const [imageLimit, setImageLimit] = useState(false);
  const [imgvalid, setimgvalid] = useState("");
  const [animationKey, setAnimationKey] = useState(0);
  const [isModalPopOpen, setIsModalPopOpen] = useState(true);
  const [editPopup, setEditPopup] = useState(false);
  const [videoDownloadPopup, setVideoDownloadPopup] = useState(false);
  const [isFreeTrialPopupOpen, setIsFreeTrialPopupOpen] = useState(false);

  const [validatemsg, setvalidatemsg] = useState(false);

  const [loadagain, setloadagain] = useState(false);
  const [stopAudio, setStopAudio] = useState(false);
  const [expandedDiv, setExpandedDiv] = useState("script");
  const [count, setCount] = useState("");
  const [check, setCheck] = useState("");
  const intervalRef = useRef(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const videoSize = data?.["MobileVideo"];
  const [audioData, setAudioData] = useState([
    {
      id: 1,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic1.mp3",
      name: "Funky vibes",
    },
    {
      id: 2,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic2.mp3",
      name: "Night vibes...",
    },
    {
      id: 3,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic3.mp3",
      name: "Action time!...",
    },
    {
      id: 4,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic4.mp3",
      name: "Mysteries roaming ...",
    },
    {
      id: 5,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic5.mp3",
      name: "Fantasy realms ...",
    },
    {
      id: 6,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic6.mp3",
      name: "Cartoon vibes ...",
    },
    {
      id: 7,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic7.mp3",
      name: "Classical Piano ...",
    },
    {
      id: 8,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic8.mp3",
      name: "Classical ...",
    },
    {
      id: 9,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic9.mp3",
      name: "Italian Romance ...",
    },
    {
      id: 10,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic10.mp3",
      name: "Lofi vibes ...",
    },
    {
      id: 11,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic11.mp3",
      name: "Relaxing Soiree ...",
    },
    {
      id: 12,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic12.mp3",
      name: "Slow jazz ...",
    },
    {
      id: 13,
      src: "https://staging.yatter.io/webs/staticcontent/backmusic13.mp3",
      name: "Upbeat and Fun ...",
    },
  ]);

  const audioSources = [
    {
      id: 1,
      src: "https://staging.yatter.io/webs/staticcontent/staticaudio.mp3",
      name: "Derek (Australia)",
    },
    {
      id: 2,
      src: "https://staging.yatter.io/webs/staticcontent/echo.mp3",
      name: "Tommy (USA)",
    },
    {
      id: 3,
      src: "https://staging.yatter.io/webs/staticcontent/nova.mp3",
      name: "Isabella (USA)",
    },
    {
      id: 4,
      src: "https://staging.yatter.io/webs/staticcontent/fable.mp3",
      name: "Charlotte (British)",
    },
    {
      id: 5,
      src: "https://staging.yatter.io/webs/staticcontent/shimmer.mp3",
      name: "Amir (India)",
    },
  ];

  const colorData = [
    {
      id: 1,
      code: "#FFFFFF",
      name: "&HFFFFFF&",
    },
    {
      id: 2,
      code: "#F77F07",
      name: "&H077FF7&",
    },
    {
      id: 3,
      code: "#F9E50D",
      name: "&H0DE5F9&",
    },
    {
      id: 4,
      code: "#03B61F",
      name: "&H1FB603&",
    },
    {
      id: 5,
      code: "#0135AF",
      name: "&HAF3501&",
    },
  ];

  const captionAlignData = [
    {
      id: 1,
      name: "Top",
    },
    {
      id: 2,
      name: "Middle",
    },
    {
      id: 3,
      name: "Bottom",
    },
  ];
  const toggleDiv = (divName) => {
    setExpandedDiv(expandedDiv === divName ? null : divName);
  };

  const openPopModal = () => setIsModalPopOpen(true);
  const closePopModal = () => setIsModalPopOpen(false);
  const [videoUrl, setVideoUrl] = useState(
    localStorage.getItem("created-video-url") || location?.state?.forreadyvideo
  );
  console.log(
    "location?.state?.forreadyvideo ",
    location?.state?.forreadyvideo
  );
  const addTovideoRef = useRef(null);
  const searchInputRef = useRef(null);
  const textareaRef = useRef(null);
  const tooltipRef = useRef(null);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getStockData();
    }
  };

  console.log(
    "data?.bgMusicData?.music_switch",
    data?.bgMusicData?.music_switch,
    "music",
    music,
    data,
    "videoUrl",
    videoUrl,
    "videoDownloadPopup",
    videoDownloadPopup
  );
  const getStockData = async () => {
    cursortowait();
    try {
      const stockImageData = await getStockImages(searchInputRef.current.value);
      stockImageData && setStockImages(stockImageData);
      const stockVideoData = await getStockVideos(searchInputRef.current.value);
      stockVideoData?.length > 0 && setStockVideos(stockVideoData);
      console.log({ stockImageData, stockVideoData });
      searchInputRef.current.value = "";
      setStockDataSuccess(true);
    } catch (error) {
      console.log({ error });
    }
    cursortodefault();
  };
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (cancelToken) {
        cancelToken.cancel();
      }
    };
  }, [cancelToken]);
  const handlePromptformSubmit = (e) => {
    e.preventDefault();
    setIsEdit(true);
    console.log("textareaRef.current.value", textareaRef.current.value);
    const aiText = textareaRef.current.value;
    // setAddAnotherImagePopup(false);
    setShowAiScript(false);
    setIsLoading(true);
    // handleSingleAiImageGenetrate(aiText);
    if (ifImgIndex) {
      handleReSingleAiImageGenetrate(aiText);
    } else {
      handleSingleAiImageGenetrate(aiText);
    }
  };
  useEffect(() => {
    if (selectedId) {
      const selectedTrack = audioData?.find(
        (track) => track?.id.toString() == selectedId
      );
      if (selectedTrack) {
        if (
          ![1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(selectedId) &&
          audioTimeout
        ) {
          // If selectedId is not in the specified set, add a 5-second timeout before proceeding
          setTimeout(() => {
            if (audioRef.current) {
              audioRef?.current?.pause();
            }
            const audio = audioRefff?.current;
            let rnum = Math.random() * 100;
            audio.src = selectedTrack.src;
            audio.className = selectedTrack.src;
            audio.key = rnum;

            console.log(audio.src);
            setAudioSrc(audio?.src);
            setPlayMusic(true);
            audio
              .play()
              .catch((error) =>
                console.error("Error playing the audio:", error)
              );
            setAudioTimeout(false);
            if (isModalPopOpen) {
              audio.pause();
              setPlayMusic(false);
            }
          }, 2000);
        } else {
          if (audioRef.current) {
            audioRef?.current?.pause();
          }
          const audio = audioRefff.current;
          let rnum = Math.random() * 100;
          audio.src = selectedTrack.src;
          audio.className = selectedTrack.src;
          audio.key = rnum;

          console.log("audio.src", audio.src);
          setAudioSrc(audio?.src);
          setPlayMusic(true);
          audio
            .play()
            .catch((error) => console.error("Error playing the audio:", error));
          if (isModalPopOpen) {
            audio.pause();
            setPlayMusic(false);
          }
        }
      }
    }
  }, [selectedId]);
  const simulateUploadProgress = () => {
    let progress = 0;
    const intervalId = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        setUploadCustomProgress(progress);
      } else {
        setIsLoading(false);
        clearInterval(intervalId);
        setUploadCustomProgress(0);
      }
    }, 1000);
    return intervalId;
  };
  const openAnotherFilePicker = () => {
    document.getElementById("fileinputfielding")?.click();
  };
  const toggleItemSelection = (item) => {
    setSelectedContent((prevSelectedImages) => {
      if (prevSelectedImages.length + items.length == 8) {
        // If already 5 selected, allow removing but not adding
        setChooseImageTooltip(true);
        setChooseImageTooltipid(item.id);
        return prevSelectedImages.filter(
          (selectedImage) => selectedImage.id !== item.id
        );
      } else {
        setChooseImageTooltip(false);
        // Add or remove item based on current selection state
        return prevSelectedImages.some(
          (selectedImage) => selectedImage.id === item.id
        )
          ? prevSelectedImages.filter(
            (selectedImage) => selectedImage.id !== item.id
          )
          : [
            ...prevSelectedImages,
            { ...item, url: item.link, link: undefined },
          ];
      }
    });
  };

  const handleReSingleAiImageGenetrate = async (aiText) => {
    // const waitingItem = [
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question }
    // ];
    // setitems([...items, ...waitingItem]);
    // const lastIndex = items?.length;
    // handleSelfRegenerate(lastIndex, aiText);
    setIsLoading(true);
    const intervalId = simulateUploadProgress();
    const threeAiImages = await getThreeAiImages(aiText);
    if (threeAiImages) {
      const newData = threeAiImages?.map((item) => ({
        ...item,
        url: item.link,
        link: undefined, // Optional: remove the 'link' key if you don't need it anymore
      }));

      if (ifImgIndex) {
        setitems([...items]);
        const updatedImages = [...items];
        updatedImages[ifImgIndex - 1] = newData[0];
        setitems(updatedImages);
        localStorage.setItem("all_images", JSON.stringify(updatedImages));
        console.log(updatedImages);
        console.log("updatedImages");
      } else {
        setitems([...items, ...newData]);
        localStorage.setItem(
          "all_images",
          JSON.stringify([...items, ...newData])
        );
      }
      setTimeout(() => {
        setIfImgIndex(null);
        setIfImgIndexText(null);
        setUploadAddProgress(null);
        clearInterval(intervalId);
        setIsLoading(false);
        setUploadCustomProgress(0);
        setAddAnotherImagePopup(false);
      }, 2000);
    }
  };

  const handleSingleAiImageGenetrate = async (aiText) => {
    // const waitingItem = [
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question }
    // ];
    // setitems([...items, ...waitingItem]);
    // const lastIndex = items?.length;
    // handleSelfRegenerate(lastIndex, aiText);
    setIsLoading(true);
    const intervalId = simulateUploadProgress();
    const threeAiImages = await getThreeAiImages(aiText);
    if (threeAiImages) {
      const newData = threeAiImages?.map((item) => ({
        ...item,
        url: item.link,
        link: undefined, // Optional: remove the 'link' key if you don't need it anymore
      }));
      setitems([...items, ...newData]);
      localStorage.setItem(
        "all_images",
        JSON.stringify([...items, ...newData])
      );
      setTimeout(() => {
        clearInterval(intervalId);
        setAddAnotherImagePopup(false);
        setIsLoading(false);
        setUploadCustomProgress(0);
      }, 2000);
    }
  };
  let currentAudio = null;
  let currentAudioElement = null;
  const [whiteparticles, setWhiteParticles] = useState(false);

  const handleChange = (event) => {
    setScript(event.target.value);
    setIsEdit(true);
  };

  const startCountdown = () => {
    let VideoProgress = 0;
    let timeProgress = 120;
    // const intervalId = setInterval(() => {
    intervalRef.current = setInterval(() => {
      if (VideoProgress < 100) {
        VideoProgress += 1;
        timeProgress -= 1;
        setCountdown(VideoProgress);

        // Calculate minutes and seconds
        const minutes = Math.floor(timeProgress / 60);
        const seconds = timeProgress % 60;

        // Format seconds with leading zero if less than 10
        const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

        // Update timeRemaining state with the formatted time
        setTimeRemaining(`${minutes}:${formattedSeconds}`);
      } else {
        clearInterval(intervalRef.current);
        setCountdown(0);
        setTimeRemaining("0:00");
      }
    }, 1500);
    // return intervalId;
  };
  useEffect(() => {
    // This effect runs whenever videoUrl changes
    console.log("videoUrl updated:", videoUrl); // Optional for debugging
  }, [videoUrl]);
  const navigateToVideo = async (e) => {
    e.preventDefault();
    console.log("isEdit", isEdit);
    if (!isEdit) {
      return setEditPopup(true);
    }
    // const intervalId = startCountdown();
    startCountdown();
    // setVideoLoading(true);
    let updatedscriptValue = document.getElementById("insert-script").value;
    Cookies.set("scripttxt", updatedscriptValue, {
      path: "/",
      domain: ".yatter.io",
    });

    Cookies.set("scripttxt", updatedscriptValue);

    // const newKey = Math.max(...Object.keys(items).map(Number)) + 1;
    const newItems = [...items];
    user && (await getUSerVideoCountHandle({ userid: user?.ID }));
    const numericKeys = Object.keys(items).filter((key) => !isNaN(Number(key)));
    const newKey =
      numericKeys.length > 0 ? Math.max(...numericKeys.map(Number)) + 1 : 0;
    let newlink = { audio: count };
    if (count) {
      newItems[newKey] = newlink;
    }

    // const updatedValue = { ...items, bgMusicData: { music_name: selectedValue.audioSrc, voice_switch: voice } };
    const updatedValue = {
      ...newItems,
      bgMusicData: {
        music_name: AudioSrc,
        music_switch: music,
        Volume: volume,
        music_name_custom: CusAudioSrc,
      },
      white_particles: whiteparticles,
      voice: voice,
      Caption: caption,
      MobileVideo: videoSize,
      Scripted: {
        text: updatedscriptValue,
      },
      wpuser: {
        id: user?.ID,
        useremail: user?.data?.user_email,
        usertype: user?.orders?.order_type,
      },
    };
    const Audio = { audio: count };
    // const updatedItems = { ...items, Audio };
    console.log("updated items are :", newItems);
    // console.log(items);
    //  const dataToPass = { items };
    audioRefff?.current?.pause();

    user?.ID && (await mixpanel.identify(user?.ID));
    await mixpanel.track("Re-Render", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || "",
      "User Type": user?.orders.order_type,
    });
    console.log("Items to rerender ", updatedValue);
    console.log("uopispjs", updatedValue);
    // navigate("/dashboard", { state: { items: updatedValue } });
    console.log("items area :", voice, Audio);
    console.log("checkwpw", Script);
    console.log("chec", subScript?.subformData?.question);
    navigate("/dashboard", {
      state: {
        updatedArray: updatedValue,
      },
    });
    localStorage.setItem("generateVideo", true);
    // try {
    //   const source = axios.CancelToken.source();
    //   setCancelToken(source);

    //   const response = await axios.post(
    //     "https://staging.yatter.io/webs/receiveimages.php",
    //     {
    //       data: updatedValue,
    //     },
    //     { cancelToken: source.token }
    //   );
    //   // Handle response data here
    //   console.log(response?.data);
    //   if (response?.data?.video_url) {
    //     localStorage.setItem("alldata", JSON.stringify(updatedValue));
    //     localStorage.setItem("created-video-url", response?.data?.video_url);
    //     localStorage.setItem("all_images", JSON.stringify(items));
    //     if (!user) {
    //       localStorage.setItem("video_id", response?.data?.video_id);
    //       localStorage.setItem("video_url", response?.data?.video_url);
    //       // settimeCompleted(true);
    //     }
    //     setTimeout(() => {
    //       setVideoLoading(false);
    //       clearInterval(intervalRef.current);
    //       // clearInterval(intervalId);
    //       setCountdown(0);
    //       setTimeRemaining("0:00");
    //       setVideoUrl(response?.data?.video_url);
    //       const temp = initialVideos;
    //       temp.unshift(response?.data?.video_url);
    //       console.log("tempinitial in ", temp);
    //       setInitialVideos(temp);
    //       setVideoDownloadPopup(true);
    //       setForceUpdate((prev) => !prev);
    //       setIsEdit(false);
    //     }, 3000);
    //   }
    // } catch (error) {
    //   // Handle error
    //   if (axios.isCancel(error)) {
    //     console.log("API request canceled");
    //   } else {
    //     console.error("Error fetching items:", error);
    //     toast.error(
    //       "Errors may happen because everyone wants to use Yatter AI at the same time. " +
    //       "It seems it's taking longer than usual - go back to the beginning or " +
    //       "send us a request to be on a free priority list at info@yatter.ai",
    //       {
    //         autoClose: 10000,
    //       }
    //     );
    //   }
    // }
    // if (Script.toLowerCase() === data?.Scripted?.text?.toLowerCase()) {
    //   setWarningText("customize your video before re-rendering");
    //   setTimeout(() => {
    //     // document.getElementById("wrongfiletypemsg").style.display = "none";
    //     setWarningText("");
    //   }, 5000);

    //   console.log("yyuuiy", updatedValue);
    // } else {
    //   return;
    //   navigate("/dashboard", { state: { items: updatedValue } });
    // }
  };

  const handleCheckboxChange = () => {
    setIsEdit(true);
    setCaption((prevState) => ({
      ...prevState,
      caption_switch: !prevState.caption_switch,
    }));
  };

  const handleColorChange = async (event) => {
    const newColor = event.target.value;
    console.log("color is ", newColor);
    setCaption((prevState) => ({
      ...prevState,
      caption_color: newColor,
    }));
    /* user?.data?.ID && await mixpanel.identify(user?.data.ID);
     await mixpanel.track("Choose Captions Color - Video Creation Steps", {
       Email: user?.data.user_email,
       "User Id": user?.data.ID || '',
       'User Type': user?.orders.order_type || 'guest',
     });*/
  };
  const downloadFile = () => {
    const downloadPath = `https://staging.yatter.io/webs/video/index.php?v=${videoUrl.substring(
      videoUrl.lastIndexOf("/") + 1
    )}`;
    window.open(downloadPath, "_blank");
    setVideoDownloadPopup(false);
    !user && setIsFreeTrialPopupOpen(true);
    if (user) {
      // updateInitialVideos(temp);
      navigate("/dashboard");
      // fetchVideos();
    }
  };
  const handleCaptionAlignChange = async (event) => {
    setIsEdit(true);
    const newCaptionAlign = event.target.value;
    console.log("align is ", newCaptionAlign);
    setCaption((prevState) => ({
      ...prevState,
      caption_align: newCaptionAlign,
    }));
  };

  const handleVoice = (e) => {
    setVoice(!voice);
  };
  const handleVolumeChange = (event) => {
    setIsEdit(true);
    setVolume(event.target.value);
    let volumnchange = event.target.value / 100;
    let mybgmusicsudio = document.getElementById("bgmusicaudio");
    mybgmusicsudio.volume = volumnchange;
  };
  const handlePlayPaused = () => {
    console.log("handlePlayPaused", selectedId);
    if (selectedId) {
      const selectedTrack = audioData.find(
        (track) => track.id.toString() == selectedId
      );
      console.log("handlePlayPaused selectedTrack", selectedTrack);
      if (selectedTrack) {
        console.log("audioRef", audioRef.current);
        if (audioRef.current) {
          const audio = audioRefff.current;
          if (playmusic) {
            audio.pause();
            setPlayMusic(false); // Pause music, update state
          } else {
            audio.play();
            setPlayMusic(true); // Start playing music, update state
            if (isModalPopOpen) {
              audio.pause();
              setPlayMusic(false);
            }
          }
        }
      }
    }
  };
  const togglePlayaudio = async (event) => {
    console.log("event.target.value", event.target.value);
    setSelectId(event.target.value);
    setIsEdit(true);
    /*  user?.data?.ID && (await mixpanel.identify(user?.data.ID));
      await mixpanel.track("Choose Music - Video Creation Steps", {
        Email: user?.data.user_email,
        "User Id": user?.data.ID || "",
        'User Type': user?.orders.order_type || "guest",
      });*/
  };

  const [AudioSrc, setAudioSrc] = useState("");
  const [CusAudioSrc, setCusAudioSrc] = useState("");
  const GetImagesfrmData =
    data &&
    Object.keys(data)
      .filter((key) => !isNaN(key))
      .map((key) => {
        const item = data[key];
        if (item.url) {
          return { url: item?.url, vidText: item?.vidtext, type: item?.type };
        }
        return null;
      })
      .filter((item) => item !== null);

  console.log({ GetImagesfrmData });

  const handlecusMusicClose = () => async (event) => {
    const musicUpldel = "";

    setcusMusicUpl(musicUpldel);
  };

  useEffect(() => {
    if (cusMusicUpl) {
      console.log("musicupl");
      console.log(cusMusicUpl);
    } else {
      console.log("musicuplnone");
    }
  }, [cusMusicUpl]); // This effect runs when cusMusicUpl changes.

  const togglePlayPause = (items) => {
    console.log("items.id", items.id);
    if (voiceId === items.id) {
      const audio = audioRef.current;
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play();
        setIsPlaying(true);
        setCount(audio?.src);
      }
    } else {
      setVoiceId(items.id);
    }
  };

  const handlePauseVoice = () => {
    console.log("comin in handlepausevoice");
    if (voiceId) {
      const selectedTrack = audioSources.find((track) => track.id === voiceId);
      if (selectedTrack) {
        if (audioRefff.current) {
          audioRefff.current.pause();
        }
        const audio = audioRef?.current;
        if (isPlaying) {
          audio?.pause();
          setIsPlaying(false); // Pause music, update state
        } else {
          audio?.play();
          setIsPlaying(true); // Start playing music, update state
        }
      }
    }
  };

  function ModalView({
    isOpenPop,
    closePopupModal,
    children,
    increaseWidth,
    increaseHeight,
  }) {
    if (!isOpenPop) return null;
    const popupStyle = {
      backgroundColor: "white",
    };

    if (increaseHeight) {
      popupStyle.height = increaseHeight;
    }
    if (increaseWidth) {
      popupStyle.maxWidth = increaseWidth;
    }
    return (
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
        style={{ zIndex: 2147483648, padding: "10px" }}
      >
        <div
          className="reserve-my-spot-instantly-popup flex flex-col"
          // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
          style={popupStyle}
        >
          {/* <div style={{ flex: "1 1 auto", overflowY: "auto" }}> */}
          {children}
          {/* </div> */}
        </div>
      </div>
    );
  }

  useEffect(() => {
    // console.log('ccc', voiceId);
    if (voiceId) {
      const selectedTrack = audioSources.find((track) => track.id === voiceId);
      console.log("selectedTrack", selectedTrack);
      if (selectedTrack) {
        // console.log("id", voiceId);
        if (audioRefff.current) {
          console.log("Audio ref is available");
          audioRefff.current.pause(); // Corrected from audioRefff.current
        }
        const audio = audioRef.current;
        console.log("Setting audio source:", selectedTrack.src);
        audio.src = selectedTrack.src;
        setCount(audio?.src);
        setIsPlaying(true);
        audio
          .play()
          .then(() => console.log("Audio playback started"))
          .catch((error) => console.error("Error playing the audio:", error));
      }
    }
  }, [voiceId]);
  useEffect(() => {
    const musicName = data?.bgMusicData?.music_name;
    const matchingAudio = audioData?.find((audio) => audio?.src === musicName);
    if (matchingAudio) {
      setSelectId(matchingAudio?.id);
    }
    const Audiosrcselected =
      data && Object.values(data)?.find((obj) => obj?.audio);
    setCount(Audiosrcselected?.audio);
    setAudioSrc(Audiosrcselected?.audio);

    const matchingVoice = audioSources?.find(
      (audio) => audio?.src === Audiosrcselected?.audio
    );
    if (matchingVoice) {
      setCheck(matchingVoice?.id);
    }
    console.log(
      "Audiosrcselected",
      Audiosrcselected,
      "matchingVoice",
      matchingVoice
    );
  }, []);

  console.log("find voice", "check", check, "count", count);

  const videoExtensions = [".mp4", ".webm", ".avi", ".mov", ".mkv", ".flv"];
  const Card = ({ src, title, id, index, moveImage }) => {
    console.log("srcsrc", src);
    const ref = React.useRef(null);
    // const handleMouseEnterPlay = () => {
    //   if (ref.current) {
    //     ref.current.play();
    //   }
    // };

    // const handleMouseLeavePause = () => {
    //   if (ref.current) {
    //     ref.current.pause();
    //   }
    // };
    const [, drop] = useDrop({
      accept: "image",
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        /* console.log("drag index ",dragIndex);
 
         console.log("hover index ",hoverIndex);
 
         console.log("hover client Y ",hoverClientY);
 
         console.log("hover Middle Y ",hoverMiddleY);
 */

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        /*  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            console.log("in drop");
            return;
          }*/

        moveImage(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "image",
      item: () => {
        return { id, index };
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    const handlePlay = (event) => {
      if (ref.current) {
        ref.current.pause();
      }
    };
    return (
      // <div ref={ref} style={{ opacity }} className="card">

      <div
        className="generated-img-block"
        key={index}
        onMouseEnter={() => {
          setHoveredIndex(index);
          if (uploadAddLoader === null) {
            setAddHoveredIndex(false);
          }
        }}
        onMouseLeave={() => setHoveredIndex(false)}
      >
        {" "}
        <div className="animated-border"></div>
        {videoExtensions?.some((ext) => src?.includes(ext)) ? (
          <>
            <button
              type="button"
              className="btn-circle play"
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                zIndex: 4,
                transition: "0.4s",
                color: "white",
                border: "none",
                borderRadius: "50%",
                padding: "10px",
                fontSize: "16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="material-icons icon">play_arrow</span>
            </button>
            <video
              id={`video-${src}`}
              ref={ref}
              //  style={{ height: '100%', width: '100%' }} // Make video take up entire space
              src={src}
              type="video/mp4"
              // controls
              width={200}
              height={200}
              style={{ zIndex: 2, position: "inherit" }}
              key={index}
              className="imgzoom"
            // onPlay={handlePlay}
            // autoPlay={false}
            ></video>
            <img
              src={require("../../images/video-icon.png")}
              alt="Video Icon"
              style={{
                position: "absolute",
                top: "4px",
                left: "1px",
                zIndex: 3,
                height: "30px",
                width: "30px",
              }}
            />
          </>
        ) : (
          <>
            {/* <div className="animated-border"></div> */}
            <img
              ref={ref}
              style={{ opacity }}
              key={index}
              src={src}
              className="imgzoom"
              alt="Generated from AI"
              width={200}
              height={200}
            // onClick={() => setSelectedImg(src)}
            />
            <img
              src={require("../../images/image-icon.png")}
              alt="Image Icon"
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                zIndex: 3,
                height: "27px",
                width: "27px",
              }}
            />
          </>
        )}
        <button type="button" className="btn-dots">
          <span className="material-symbols-outlined dots-icon">more_vert</span>
        </button>
        {hoveredIndex === index && (
          <>
            {/* <button onClick={() => handleTrashClick(index)} className="icon-button" style={{ position: "absolute", top: -10, right: 4 }}>
              <img style={{ width: 24, height: 24, margin: 0, border: 'none' }} src={trash} alt="dlete_btn" />
            </button> */}

            {uploadLoader === index && uploadProgress != null && (
              <div
                className="custom-tooltip regenerate-action"
                style={{ zIndex: 5 }}
              >
                <div className="">
                  <p className="text-white font-bold mb-3">
                    Generating image...
                  </p>
                  <div className="flex items-center w-full">
                    <div className="progress-bar white mr-4">
                      <div
                        className="progress"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <h4 className="text-white">{uploadProgress.toFixed(0)}%</h4>
                  </div>
                </div>
              </div>
              // <div className="flex items-center w-100  mt-2">
              //   <div className="progress-bar ml-4">
              //     <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
              //   </div>
              //   <h4 className="ml-4" style={{ color: "white" }}>{uploadProgress.toFixed(0)}%</h4>
              // </div>
            )}

            {uploadProgress === null && (
              <div
                className="custom-tooltip regenerate-action active"
                style={{ zIndex: 5 }}
                ref={tooltipRef}
              >
                {regenerate === index ? (
                  // <div>

                  //   <p style={{ color: "white", fontSize: 10 }}>Describe your image how you want it</p>
                  //   <textarea
                  //     name="enter desc" rows={4}
                  //     cols={40}
                  //     style={{
                  //       fontSize: 10,
                  //       color: "white",
                  //       backgroundColor:
                  //         "transparent",
                  //       border: '1px solid white',
                  //       borderRadius: 5,
                  //       marginTop: 3,
                  //       paddingLeft: 5,
                  //       paddingTop: 5
                  //     }}
                  //     value={textAreaValue}
                  //     onChange={handleTextAreaChange}
                  //   />
                  //   <button
                  //     className="btn btn-white btn-sm absolute bottom-4 right-3"
                  //     type="button"
                  //     onClick={() =>
                  //       setRegenerate(null)

                  //     }
                  //   >
                  //     ←
                  //   </button>
                  //   <button
                  //     className="btn btn-white btn-sm"
                  //     type="button"
                  //     onClick={() => {
                  //       handleSelfRegenerate(index);
                  //       setRegenerate(null);
                  //     }}

                  //   >
                  //     Submit
                  //   </button>

                  // </div>
                  <div className="">
                    <label className="text-white f-14">
                      Describe your image how you want it
                    </label>
                    <textarea
                      id="regenerate-text"
                      rows="2"
                      className="input-control white "
                      style={{ marginTop: 2 }}
                      placeholder="Write description..."
                    ></textarea>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-white btn-sm mr-2"
                        onClick={() => {
                          handleSelfRegenerate(index);
                          setRegenerate(null);
                        }}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-white-outline btn-sm"
                        onClick={() => setRegenerate(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  // </div>
                  <div className="text-center">
                    <button
                      className="btn btn-white btn-sm w-full"
                      type="button"
                      style={{ zIndex: 5 }}
                      // disabled={(generatecount === 7 || regeneratecount > 3) && true}
                      // onClick={() => setRegenerate(index)}
                      onClick={() => {
                        setIfImgIndex(index + 1);
                        setIfImgIndexText("Re");
                        uploadAddProgress === null &&
                          !uploadProgress &&
                          setAddAnotherImagePopup(true);
                      }}
                    >
                      Change this media
                    </button>

                    {/* <div className="mt-2 mb-2">
                      <span className="text-white">OR</span>
                      </div> */}

                    <div className="flex justify-center mt-2 mb-4">
                      <span
                        className="f-12 text-light-gray flex items-center"
                        style={{ display: "none" }}
                      >
                        <span className="material-symbols-outlined mr-1 f-18">
                          info
                        </span>{" "}
                        You can only regenerate image once
                      </span>
                    </div>

                    {/*  <div className='btn_main w-full'>
                        <button type="button" className="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                        </button>

                        <input className="btn btn-white btn-sm sxs" type="file" onChange={handleFileChangesss(index)} />

                      </div> 
                            */}
                    <div
                      className="tooltip-footer-action"
                      style={{ justifyContent: "center" }}
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-text-icon preview"
                        onClick={() => setSelectedImg(src)}
                        style={{ borderRight: "none" }}
                      >
                        <span className="material-symbols-outlined mr-2">
                          {" "}
                          visibility{" "}
                        </span>{" "}
                        Preview
                      </button>
                      <button
                        type="button"
                        id={`delbutton-${index}`}
                        className="btn btn-sm btn-text-icon delete"
                        onClick={() => handleTrashClick(index)}
                      >
                        <span className="material-symbols-outlined mr-2">
                          {" "}
                          delete{" "}
                        </span>{" "}
                        Delete
                      </button>
                      <div
                        className="deletebtnnext"
                        id={`delbuttontooltip-${index}`}
                        style={{ display: "none" }}
                      >
                        3 images minimum
                      </div>
                    </div>

                    {/* {uploadLoader === index && uploadProgress != null && (
                      <div className="flex items-center w-100  mt-2">
                        <div className="progress-bar ml-4">
                          <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                        </div>
                        <h4 className="ml-4" style={{ color: "white" }}>{uploadProgress.toFixed(0)}%</h4>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      // </div>
    );
  };

  function getFileExtension(fileName) {
    // return fileName
    // Use a regex pattern to find the extension
    var pattern = /\.([a-zA-Z0-9]+)$/;
    var match = fileName.match(pattern);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  // Example usage:

  const handleFileChange = async (event) => {
    console.log("coming in");
    localStorage.removeItem("imagePageState");
    // const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
    const allowedTypes = ["jpg", "jpeg", "png", "webp"];
    // return console.log(event.target.files);
    // console.log('check', location?.state?.subFormData?.question);

    const file = event.target?.files[0];
    // var fileName = "sample.webp";
    var extension = getFileExtension(file.name);
    console.log("File extension:", extension);
    if (allowedTypes.includes(extension)) {
      setUploadSelfLoader(true);
      setFirstRegenerate(true);
      // File type not allowed, handle this case according to your requirements
      console.log("File type allowed. Please select a JPG, PNG, or WEBP file.");
      console.log("nisaldhpowe", file);
      let data = new FormData();
      data.append("image", file);
      // const resp = await getUploadImageId(file)
      const imageResp = await uploadImageGenerate(data, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadSelfProgress(progress);
      });

      // Cookies.set('scripttxt', location?.state?.subFormData?.question, { path: '/', domain: '.yatter.io' });

      Cookies.set("scripttxt", getformData.subformData.question, {
        path: "/",
        domain: ".yatter.io",
      });

      Cookies.set("scripttxt", getformData.subformData.question);

      // const imgData = {
      //   url: imageResp.url,
      //   type: 'upload'
      // }
      setitems([...items, imageResp]);
      // setitems([...items, imgData]);
      // setUploadSelfLoader(false)
      setUploadSelfLoader(null);

      // if (file) {
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     // Store the Base64 string in local storage
      //     localStorage.setItem("uploadedImage", reader.result);
      //   };
      //   reader.readAsDataURL(file);
      // }
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const dbRequest = indexedDB.open("fileStorage", 1);
          dbRequest.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("files")) {
              db.createObjectStore("files", { keyPath: "id" });
            }
          };

          dbRequest.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(["files"], "readwrite");
            const objectStore = transaction.objectStore("files");
            const fileData = { id: "uploadedImage", data: reader.result };

            const request = objectStore.put(fileData);
            request.onsuccess = () => {
              console.log("File stored successfully in IndexedDB");
            };
            request.onerror = (err) => {
              console.error("Error storing file in IndexedDB", err);
            };
          };

          dbRequest.onerror = (err) => {
            console.error("Error opening IndexedDB", err);
          };
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const isFileSizeValid = (file) => {
    // const maxSizeInBytes = 3 * 1024 * 1024;
    const maxSizeInBytes = 10 * 1024 * 1024;

    return file?.size <= maxSizeInBytes;
  };
  useEffect(() => {
    switch (true) {
      case items?.length < 3:
        setWarningText(
          "Use at least 3 images/videos. This will make your video look much better."
        );
        break;
      case items?.length > 8:
        setWarningText(
          "8 media files are the optimal amount for high converting videos. You can only add 8 max."
        );
        break;
      case items?.length >= 5 && items?.length <= 8:
        setWarningText("");
        break;
      default:
        setWarningText("");
    }
  }, [items]);
  const handleAddFileChange = async (event) => {
    // const allowedTypes = ["jpg", "jpeg", "png", "webp", "mp4", "webm", "avif"];
    // const allowedTypes = ["jpg", "jpeg", "png", "webp", "avif"];
    const allowedTypes = ["jpg", "jpeg", "png", "webp", "avif", "mp4"];
    const file = event.target?.files[0];

    const filSize = await isFileSizeValid(file);
    if (!filSize) {
      setWarningText(`Please upload files under 10MB. Thanks!`);
      // setWarningText(`Please upload files under 3MB. Thanks!`);
      setTimeout(() => {
        setWarningText("");
      }, 5000);
      return;
    }
    var extension = getFileExtension(file?.name);

    if (items?.length < 8) {
      if (allowedTypes.includes(extension)) {
        setUploadAddLoader(true);
        setUploadAddProgress(0);
        const file = event.target?.files[0];
        // console.log('fileee', file);
        console.log("nisaldhpowe", file);
        let data = new FormData();
        data.append("image", file);
        // // const imageResp = await uploadImageGenerate(data);
        const imageResp = await uploadImageGenerate(data, (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadAddProgress(progress);
        });
        user?.data?.ID && (await mixpanel.identify(user?.data.ID));
        await mixpanel.track("Upload my own image", {
          Email: user?.data.user_email,
          "User Id": user?.data.ID || "",
          "User Type": user?.orders.order_type || "guest",
          "User Image": imageResp?.url,
        });
        // const imgData = {
        //   url: imageResp.url,
        //   type: 'upload'
        // }
        // ([...items, imgData]);
        console.log(ifImgIndex + "selected");
        setTimeout(() => {
          if (ifImgIndex) {
            setUploadLoader(ifImgIndex - 1);
            const updatedImages = [...items];
            updatedImages[ifImgIndex - 1] = imageResp;
            setitems(updatedImages);
          } else {
            setitems([...items, imageResp]);
          }
          setIfImgIndex(null);
          setIfImgIndexText(null);
          setIsEdit(true);
          localStorage.setItem(
            "all_images",
            JSON.stringify([...items, imageResp])
          );
          //   Cookies.set("scripttxt", videoScript, {
          //     path: "/",
          //     domain: ".yatter.io",
          //   });
          //   Cookies.set("scripttxt", videoScript);
          setUploadAddProgress(null);

          // setUploadAddLoader(false)
          setUploadAddLoader(null);
          // if (file) {
          //   const reader = new FileReader();
          //   reader.onloadend = () => {
          //     // Store the Base64 string in local storage
          //     console.log("reader.result", reader.result);
          //     localStorage.setItem("uploadedImage", reader.result);
          //   };
          //   reader.readAsDataURL(file);
          // }
          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const dbRequest = indexedDB.open("fileStorage", 1);
              dbRequest.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains("files")) {
                  db.createObjectStore("files", { keyPath: "id" });
                }
              };

              dbRequest.onsuccess = (event) => {
                const db = event.target.result;
                const transaction = db.transaction(["files"], "readwrite");
                const objectStore = transaction.objectStore("files");
                const fileData = { id: "uploadedImage", data: reader.result };

                const request = objectStore.put(fileData);
                request.onsuccess = () => {
                  console.log("File stored successfully in IndexedDB");
                };
                request.onerror = (err) => {
                  console.error("Error storing file in IndexedDB", err);
                };
              };

              dbRequest.onerror = (err) => {
                console.error("Error opening IndexedDB", err);
              };
            };
            reader.readAsDataURL(file);
          }
        }, 2000);
      } /* extension IF ends */ else {
        console.log(
          "File type not allowed 1. Please select a JPG, PNG, or WEBP file."
        );
        //alert("You can upload only png , jpg , webp");
        // document.getElementById("wrongfiletypemsg").style.display = "flex";
        setWarningText(
          "File type not allowed. Please select a JPG, PNG, or WEBP file."
        );
        setTimeout(() => {
          // document.getElementById("wrongfiletypemsg").style.display = "none";
          setWarningText("");
        }, 5000);
      }
    } else {
      setImageLimit(true);
    }
    const fileinputfieldingElement =
      document.getElementById("fileinputfielding");
    if (fileinputfieldingElement) {
      fileinputfieldingElement.value = "";
    }
    cursortodefault();
    setStockImages([]);
    setStockVideos([]);
    setStockDataSuccess(false);
  };
  /*  const handleFileChangesss = (index) => async (event) => {
      const allowedTypes = ["jpg", "jpeg", "png", "webp"];
      const file = event.target.files[0];
      var extension = getFileExtension(file.name);
  
      if (allowedTypes.includes(extension)) {
        setUploadProgress(null);
        setUploadLoader(index);
        let data = new FormData();
        data.append("image", file);
        const imageResp = await uploadImageGenerate(data, (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        });
        const updatedImages = [...items];
        // const imgData = {
        //   url: imageResp.url,
        //   type: 'upload'
        // }
        updatedImages[index] = imageResp;
        // updatedImages[index] = imgData;
        setitems(updatedImages);
        Cookies.set("scripttxt", getformData.subformData.question, {
          path: "/",
          domain: ".yatter.io",
        });
  
         Cookies.set('scripttxt', getformData.subformData.question);
  
        setUploadLoader(null);
        setUploadProgress(null);
        // setUploadLoader(false)
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Store the Base64 string in local storage
            localStorage.setItem("uploadedImage", reader.result);
          };
          reader.readAsDataURL(file);
        }
      } else {
        console.log(
          "File type not allowed. Please select a JPG, PNG, or WEBP file."
        );
        // alert("You can upload only png , jpg , webp");
      }
    };
  */

  const handleFileChangesss = () => async (event) => {
    const allowedTypes = ["mp3"];
    const file = event.target.files[0];
    let randomId = Math.floor(Math.random() * 9000) + 1000;
    if (file.name) {
      var extension = getFileExtension(file.name);
      console.log(file.name);
      if (allowedTypes.includes(extension)) {
        let data = new FormData();
        setCusAudioSrc(file.name);
        data.append("image", file);
        console.log(file.name);
        const musicUpl = await uploadMusicGenerate(data, (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          // setUploadProgress(progress);
        });
        console.log("musicUpl", musicUpl);
        setCusbgmusicfilename(file.name);
        setcusMusicUpl(musicUpl);
        setAudioData((prevAudioData) => {
          return [
            ...prevAudioData,
            {
              id: randomId,
              src: musicUpl,
              name: file.name,
            },
          ];
        });
        if (musicUpl) {
          setAudioTimeout(true);
        }
        setCusAudioSrc(musicUpl);
        setAudioSrc(musicUpl);
        console.log(CusAudioSrc);
        setSelectId(`${randomId}`);
        setIsEdit(true);
      } else {
        console.log("File type not allowed. Please select a mp3 file.");
      }
    }

    document.getElementById("filemp3open").value = ""; //clear the file input field
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    handleMouseAddLeave();
  };

  const handleMouseLeave = (index) => {
    if (uploadLoader === null) {
      setHoveredIndex(false);
    }
  };

  const handleMouseAddEnter = () => {
    if (uploadAddLoader != null || uploadAddProgress === 100) {
      setAddHoveredIndex(true);
    } else {
      setAddHoveredIndex(true);
    }
    handleMouseLeave();
  };

  const handleMouseAddLeave = () => {
    // if (uploadAddLoader === null) {
    setAddHoveredIndex(false);
    // }
  };

  const cliearImageHover = (index) => {
    handleMouseLeave();
    handleMouseAddLeave();
  };

  function formatMilliseconds(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${paddedSeconds}`;
  }
  // console.log('iteenn', items?.[0]);
  var forreadyimage = "";

  async function handleSelfRegenerate(indexData) {
    console.log(items);
    if (generatecount < 9) {
      setUploadLoader(indexData);
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        // setReGenProgress(calculatedProgress);
        setUploadProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = totalDuration - elapsedTime;
        const calculatedTimeRemaining = formatMilliseconds(
          calculatedTimeRemaining1
        );

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {
          console.log("forreadyimage");
          console.log("forreadyimage");
          if (forreadyimage == "") {
            console.log("data not ready");
            elapsedTime = 0;
            //   setRegenerateLoader(null);
            // setUploadLoader(null)
            setUploadLoader(indexData);
          } else {
            // setReGenProgress(100);
            setUploadProgress(100);
            clearInterval(interval);
            settimeCompleted(true);
            // setReGenProgress(100);
            setUploadProgress(100);
            // setRegenerateLoader(null)
            setUploadLoader(null);
            handleResponse();
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }
        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        question: document.getElementById("regenerate-text").value,
        //question: textAreaValue,
        // qabout: getformData.subformData.question
      };
      console.log(formData);
      console.log(textAreaValue);
      try {
        const response = await generateImageSingle(formData);
        // const response = await generateImage(location.state.subFormData);
        // const response = await generateImage(location.state.subFormData, (progressEvent) => {
        //   const progress = (progressEvent.loaded / progressEvent.total) * 100;
        //   console.log('porrr', progress);
        //   setReGenProgress(progress);
        // });
        // const response = await generateImage(location.state.subFormData);
        // const response = await maskImage(formData);
        // return console.log('resp', response);
        //console.log("in app");
        //console.log(response);
        console.log("respnose_regen", response);
        if (response) {
          // setLoading(false); // Start loading
          // navigate('/GetImages', { state: { response } });
          // return setImage(response);
          // setitems(response);
          // const newImageData = items[indexData] = response;

          // setitems(updatedImages);
          // console.log(updatedImages);
          // const updatedImages = items.map((item, index) => (
          //   index === indexData ? response : item
          // ));

          // // Update the state with the modified array
          // setitems(updatedImages);
          // setReGenProgress(null)
          // console.log('in items response', updatedImages);
          const data = {
            images: response,
          };
          console.log("gene_data", data);
          // setitems([...items, response]);
          const get_response = await getGenerateImage(data);
          setGeneratecount((prevCount) => prevCount + 1);
          if (generatecount === 4) {
            setregeneratecount((prevCount) => prevCount + 1);
          } else {
            setregeneratecount((prevCount) => prevCount + 1);
          }
          forreadyimage = get_response?.images;
          //  setLoading(false); // Start loading
          // forreadyimage = response;
          let timerem = totalDuration - elapsedTime;

          // setTimeout(() => {
          //     navigate('/GetImages', {
          //         state: {
          //             response: response, // your response data
          //             subFormData: subformData // your additional data
          //         }
          //     });
          // }, timerem); // 2000 milliseconds = 2 seconds
          //console.log(items);
          /* items=response;
           console.log('in items respnose');
           console.log(items);
           */
        } else {
          // setReGenProgress(null)
          return console.log("error in regenerate");
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error("Error handling form submission:", error);
      }

      function handleResponse() {
        // const imagData = {
        //   url: forreadyimage?.[0]?.url,
        //   type: "generate"
        // }
        const updatedImages = [...items];
        // updatedImages[indexData] = imagData;
        updatedImages[indexData] = forreadyimage?.[0];
        // const updatedImages = [...items, forreadyimage?.[0]];
        setitems(updatedImages);
        setUploadProgress(null);
        console.log("forreadyimage", forreadyimage);
      }
    }
    // else {
    //   setregeneratecount(prevCount => prevCount + 1);
    // }

    // setRegenerateLoader(indexData)
  }

  const handleTrashClick = (indexToRemove) => {
    console.log("Trash button clicked");
    console.log(items.length);

    let whichelempopup = "delbuttontooltip-" + indexToRemove;
    console.log(whichelempopup);
    let whichdelbtn = "delbutton-" + indexToRemove;
    console.log(whichdelbtn);
    if (items.length > 3) {
      setitems((currentItems) => {
        // Create a new array without the item at indexToRemove
        return currentItems.filter((item, index) => index !== indexToRemove);
      });
      setIsEdit(true);
      if (items.length === 0) {
        setFirstRegenerate(false);
      }
      // Add your trash click handling logic here
    } else {
      document.getElementById(whichelempopup).style.display = "block";
      document.getElementById(whichdelbtn).classList.add("delcursorbtn");
    }
  };

  useEffect(() => {
    const scriptout = Cookies.get("scripttxt");
    console.log("SABDGSQ", scriptout);
    const savedStateString = localStorage.getItem("imagePageState");
    console.log("Raw savedState from localStorage:", savedStateString);

    if (savedStateString) {
      setCheckData(savedStateString);
      try {
        // Parse the JSON string to an object
        const savedStateObject = JSON.parse(savedStateString);
        console.log("Parsed savedStateObject:", savedStateObject);

        // Access the 'firstRegenerate' property correctly
        if ("generatecount" in savedStateObject) {
          console.log("generatecount", savedStateObject.generatecount);
          setGeneratecount(savedStateObject.generatecount);
          // setFirstRegenerate(savedStateObject.firstRegenerate);
        }
        if ("firstRegenerate" in savedStateObject) {
          setFirstRegenerate(true);
          // setFirstRegenerate(savedStateObject.firstRegenerate);
        } else {
          console.error(
            "firstRegenerate property is missing in the saved state object."
          );
        }

        // Assuming you want to extract all URL items into an array
        const itemsArray = Object.values(savedStateObject).filter(
          (item) => typeof item === "object" && "url" in item
        );
        setitems(itemsArray);
      } catch (error) {
        console.error(
          "Error parsing savedStateString or accessing properties:",
          error
        );
      }
    }
  }, []);

  useEffect(() => {
    setitems(GetImagesfrmData);
  }, []);

  useEffect(() => {
    // Check local storage for the clearData flag
    const shouldClearData = localStorage.getItem("clearData") === "true";
    if (shouldClearData) {
      // Clear local state based on the flag
      // setitems([]); // Clear the state
      setFirstRegenerate(false);
      localStorage.removeItem("imagePageState");
      setCheckData();

      // Optionally, reset or remove the flag to prevent clearing data on subsequent loads
      localStorage.removeItem("clearData");
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("imagePageState");

    if (items?.length === 0) {
      setFirstRegenerate(false);
    } else if (items?.length > 0) {
      setFirstRegenerate(true);
      setUploadSelfProgress(100);
      setCheckData();
    }
  }, [items]);

  const moveImage = React.useCallback((dragIndex, hoverIndex) => {
    setitems((prevCards) => {
      const clonedCards = [...prevCards];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];

      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  return (
    <>
      <Layout
        HeaderName="Edit your script, images, and audio:"
        hideFooter={true}
      >
        <div className={`page-container ${!user ? "!static" : ""}`}>
          <div className="container-xxxl" style={{ minHeight: "78vh" }}>
            <div className="edit-video-wrap mb-6">
              <div className="edit-container">
                <div
                  className={`accordion-block ${expandedDiv === "script" ? "expnded" : ""
                    }`}
                >
                  <div
                    className="accordion-head"
                    onClick={() => toggleDiv("script")}
                  >
                    <h3>
                      <span className="text-red">Step 1:</span> Script
                    </h3>
                    <span
                      className="material-symbols-outlined arrow-icon cursor-pointer"
                      onClick={() => toggleDiv("script")}
                    >
                      {" "}
                      expand_more{" "}
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="mb-6">
                      <label htmlFor="insert-script" className="label mb-2">
                        Edit your current script as needed
                      </label>
                      <div className="ai-script-card bg-light-gradient p-2 rounded">
                        <div className="flex items-center justify-center mb-2">
                          <span className="material-symbols-outlined f-24 gradient-text mr-2">
                            {" "}
                            task_alt{" "}
                          </span>
                          <span className="f-14 font-bold">Edit below</span>
                        </div>
                        <textarea
                          id="insert-script"
                          rows={4}
                          className="input-control white border-light mb-0"
                          placeholder="Write here..."
                          defaultValue={Script}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="accordion-footer">
                      <button
                        type="button"
                        className="btn btn-gradient"
                        onClick={() => toggleDiv("image")}
                      >
                        Continue
                      </button>
                    </div>
                    {/* accordion-footer */}
                  </div>
                  {/* accordion-body */}
                </div>
                {/* accordion-block */}
                <div
                  className={`accordion-block ${expandedDiv === "image" ? "expnded" : ""
                    }`}
                >
                  <div
                    className="accordion-head"
                    onClick={() => toggleDiv("image")}
                  >
                    <h3>
                      <span className="text-red">Step 2:</span> Image
                    </h3>
                    <span
                      className="material-symbols-outlined arrow-icon cursor-pointer"
                      onClick={() => toggleDiv("image")}
                    >
                      {" "}
                      expand_more{" "}
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="mb-6">
                      <label htmlFor="insert-script" className="label mb-2">
                        Add or replace any image inside your video
                      </label>
                    </div>
                    <div
                      className="generated-images"
                      onMouseEnter={handleMouseAddEnter}
                      onMouseLeave={handleMouseAddLeave}
                    >
                      {React.Children.toArray(
                        items?.map((image, index) => (
                          <Card
                            key={index} // Added key prop to fix React warning
                            src={image?.url}
                            title={image?.title}
                            id={index}
                            index={index}
                            moveImage={moveImage}
                          />
                        ))
                      )}
                      <input
                        class="btn btn-white btn-sm hidden"
                        type="file"
                        id="fileinputfielding"
                        onChange={(event) => {
                          handleAddFileChange(event);
                          setAddAnotherImagePopup(false);
                        }}
                      // onChange={() => console.log('File input clicked')}
                      />

                      {items?.length < 8 && (
                        <div
                          class="generated-img-block blank"
                          onMouseEnter={handleMouseAddEnter}
                          onMouseLeave={handleMouseAddLeave}
                        >
                          <div
                            class="text-center cursor-pointer"
                            onClick={() =>
                              uploadAddProgress === null &&
                              !uploadProgress &&
                              setAddAnotherImagePopup(true)
                            }
                          >
                            <span class="material-symbols-outlined text-gray mb-4">
                              cloud_upload
                            </span>
                            <p class="text-gray m-0">Upload my own</p>

                            <div className="btn_main">
                              <button
                                type="button"
                                class="btn btn-white btn-sm "
                                style={{ cursor: "pointer" }}
                              >
                                Upload my own
                              </button>
                            </div>
                          </div>
                          {addhoveredIndex && (
                            <>
                              {uploadAddProgress === null && (
                                <div class="custom-tooltip regenerate-action active">
                                  <div class="text-center">
                                    {/*   <button
                                    class="btn btn-white btn-sm w-full customclassauto"
                                    type="button"
                                    onClick={handleRegenerate}
                                    disabled={generatecount === 5 && true}
                                    // onClick={() =>
                                    //   setRegenerate()
                                    // }
                                  >
                                    Generate with AI
                          </button>
                                   <div class="mt-2 mb-2">
                            <span class="text-white">OR</span>
                          </div> 
                         */}
                                    <div class="flex justify-center mt-2 mb-4">
                                      <span class="f-12 text-light-gray flex items-center uploadallowedtypes">
                                        <span class="material-symbols-outlined mr-1 f-18">
                                          info
                                        </span>{" "}
                                        File types : .jpg, .jpeg, .png, .webp
                                      </span>
                                    </div>

                                    {/*   <div className="btn_main">
                                      <button type="button" class="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                              </button>
                        
                                    <input
                                      class="btn btn-white btn-sm"
                                      type="file"
                                      onChange={(event) =>
                                        handleAddFileChange(event)
                                      }
                                      // onChange={() => console.log('File input clicked')}
                                    />
                                  </div> 
                                    
                             
                                   {uploadAddLoader && uploadAddProgress != null && (
                            <div class="flex items-center w-100  mt-2">
                              <div class="progress-bar ml-4">
                                <div class="progress" style={{ width: `${uploadAddProgress}%` }}></div>
                              </div>
                              <h4 class="ml-4" style={{ color: "white" }}>{uploadAddProgress.toFixed(0)}%</h4>
                            </div>
                                   
                          )} */}
                                  </div>
                                </div>
                              )}

                              {uploadAddLoader && uploadAddProgress != null && (
                                <div class="custom-tooltip regenerate-action">
                                  <div class="">
                                    <p class="text-white font-bold mb-3">
                                      Generating image...
                                    </p>
                                    <div class="flex items-center w-full">
                                      <div class="progress-bar white mr-4">
                                        <div
                                          class="progress"
                                          style={{
                                            width: `${uploadAddProgress}%`,
                                          }}
                                        ></div>
                                      </div>
                                      <h4 class="text-white">
                                        {uploadAddProgress.toFixed(0)}%
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="accordion-footer">
                      <button
                        type="button"
                        className="btn btn-gradient"
                        onClick={() => toggleDiv("audio")}
                      >
                        Confirm my images
                      </button>
                    </div>
                    {warningText && (
                      <div
                        key={animationKey}
                        className="short-limit-info invideoscreen mt-5 warning-animation"
                        style={{ width: "max-content" }}
                        onMouseEnter={() => cliearImageHover()}
                      >
                        <span className="material-icons">info</span>
                        {warningText}
                      </div>
                    )}
                    {/* accordion-footer */}
                  </div>
                  {/* accordion-body */}
                </div>
                {/* accordion-block */}
                <div
                  className={`accordion-block ${expandedDiv === "audio" ? "expnded" : ""
                    }`}
                >
                  <div
                    className="accordion-head"
                    onClick={() => toggleDiv("audio")}
                  >
                    <h3>
                      <span className="text-red">Step 3:</span> Audio
                    </h3>
                    <span
                      className="material-symbols-outlined arrow-icon cursor-pointer"
                      onClick={() => {
                        setPlayMusic(!playmusic);
                        toggleDiv("audio");
                      }}
                    >
                      {" "}
                      expand_more{" "}
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="mb-6">
                      <label htmlFor="insert-script" className="label mb-2">
                        This audio will be used inside your video
                      </label>
                    </div>
                    <div className="border-block mb-8">
                      <div className="checkbox-container">
                        <div className="flex items-center">
                          <input
                            class="w-4 h-4 bg-gradient rounded"
                            type="checkbox"
                            id="short-voice"
                            onChange={handleVoice}
                            checked={voice}
                            style={{ display: "none" }}
                          />
                          <label
                            for="short-voice"
                            className="ms-2"
                            style={{ marginLeft: "1.5rem" }}
                          >
                            Voice
                          </label>
                        </div>

                        <div class="voices">
                          {audioSources.map((items) => (
                            <div
                              key={items.id}
                              class="voice-block selected"
                              onClick={async () => {
                                setCheck(items.id);
                                setCount(items.src);
                                setIsEdit(true);
                                // console.log("user?.data?.ID")
                              }}
                            >
                              {/*  <audio ref={audioRef} id='audioPlayer' src={count}/> */}
                              <audio
                                ref={audioRef}
                                id={`audioPlayer_${items.id}`}
                                src={items.src}
                                onEnded={() => setIsPlaying(false)}
                              />
                              {loading ? (
                                ""
                              ) : isPlaying && voiceId === items.id ? (
                                <a
                                  class="voice-play"
                                  onClick={() => handlePauseVoice()}
                                >
                                  <span class="material-icons check-icon">
                                    pause
                                  </span>
                                </a>
                              ) : (
                                <a
                                  class="voice-play"
                                  onClick={() => togglePlayPause(items)}
                                >
                                  <span class="material-symbols-outlined">
                                    play_circle
                                  </span>
                                </a>
                              )}
                              {/*} <a class="voice-play" onClick={togglePlayPause}>{isPlaying ? 'play' : 'pause'}<span class="material-symbols-outlined">play_circle</span></a>*/}
                              <span class="voice-name">
                                {items.name}
                                {/* {voiceId === items.id && <span className="material-icons check-icon">check</span>}</span> */}
                                {check === items.id && (
                                  <span className="material-icons check-icon">
                                    check
                                  </span>
                                )}
                              </span>
                            </div>
                          ))}

                          {/* <audio ref={audioRef} src={count}></audio>
      <a onClick={togglePlayPause}>
        <a class="voice-play" onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}<span class="material-symbols-outlined">play_circle</span></a>
  </a>*/}
                        </div>

                        {/*  <div className="voices">
                    <div className="voice-block selected">
                      <a href="#" className="voice-play"><span className="material-symbols-outlined">play_circle</span></a>
                      <span className="voice-name">Male - John <span className="material-icons check-icon">check</span></span>
                    </div>
                    <div className="voice-block">
                      <a href="#" className="voice-play"><span className="material-symbols-outlined">play_circle</span></a>
                      <span className="voice-name">Female - Alexa <span className="material-icons check-icon">check</span></span>
                    </div>
                    <div className="voice-block">
                      <a href="#" className="voice-play"><span className="material-symbols-outlined">play_circle</span></a>
                      <span className="voice-name">Male - Adam <span className="material-icons check-icon">check</span></span>
                    </div>
                    <div className="voice-block">
                      <a href="#" className="voice-play"><span className="material-symbols-outlined">play_circle</span></a>
                      <span className="voice-name">Female - Emma <span className="material-icons check-icon">check</span></span>
                    </div>
    
                  </div> */}
                      </div>

                      <div className="checkbox-container">
                        <div className="flex items-center">
                          <input
                            id="short-bg-music"
                            type="checkbox"
                            checked={music}
                            onChange={() => {
                              setIsEdit(true);
                              setMusic(!music);
                            }}
                            class="w-4 h-4 bg-gradient rounded"
                          />
                          <label for="short-bg-music" className="ms-2">
                            Background Music
                          </label>
                        </div>

                        <div class="bg-music">
                          {music && (
                            <>
                              <span class="block f-14 font-regular mb-2 mt-3">
                                Choose background audio
                              </span>

                              {cusMusicUpl && stopAudio ? (
                                <div class="own-file-uploaded">
                                  <span class="file-icon">
                                    <span class="material-symbols-outlined">
                                      {" "}
                                      music_note{" "}
                                    </span>
                                  </span>
                                  <span class="file-text">
                                    <p class="f-14 font-medium cusmusicname">
                                      {Cusbgmusicfilename}
                                    </p>
                                  </span>
                                  <button
                                    type="button"
                                    class="btn btn-close-icon"
                                    onClick={handlecusMusicClose()}
                                  >
                                    <span class="material-symbols-outlined">
                                      {" "}
                                      close{" "}
                                    </span>
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {
selectedId&&
<span className="material-icons check-icon" style={{ cursor: "pointer" }} onClick={handlePlayPause}>
{playmusic ? 'pause' : 'play_circle'}
</span>
} */}
                                    {AudioSrc && (
                                      <span
                                        className="material-icons "
                                        style={{ cursor: "pointer" }}
                                        onClick={handlePlayPaused}
                                      >
                                        {playmusic ? "pause" : "play_circle"}
                                      </span>
                                    )}
                                    <select
                                      className="input-control"
                                      value={selectedId}
                                      onChange={togglePlayaudio}
                                    >
                                      <option selected value="">
                                        Select Music
                                      </option>
                                      {audioData?.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                          {name}
                                        </option>
                                      ))}
                                    </select>
                                    <audio
                                      id="bgmusicaudio"
                                      ref={audioRefff}
                                      onEnded={() => setPlayMusic(false)}
                                    />
                                  </div>
                                  <p class="mb-4 mt-4 text-gray">OR</p>
                                  <div class="flex items-center ">
                                    <div class="btn-premium-input">
                                      <button
                                        type="button"
                                        class="btn btn-premium"
                                      >
                                        <img
                                          class="premium-icon"
                                          src="images/premium.svg"
                                          alt="Premium"
                                        />{" "}
                                        Upload my own music
                                      </button>
                                      <input
                                        id="filemp3open"
                                        class="btn btn-premium sxs"
                                        type="file"
                                        accept=".mp3"
                                        onChange={handleFileChangesss()}
                                      />
                                    </div>
                                    {/*  <div class="flex justify-center m-2">
                                  <span class="f-14 text-gray flex items-center">
                                    <span class="material-symbols-outlined mr-1 f-20">
                                      info
                                    </span>{" "}
                                    Only with Premium. Coming May 1st
                                    
                                  </span>
                                </div>*/}
                                  </div>
                                </>
                              )}

                              <div class="flex items-center mt-4">
                                <span class="text-gray mr-4">Volume:</span>
                                <div class="volume-control mr-4">
                                  <input
                                    type="range"
                                    min="1"
                                    max="50"
                                    value={volume}
                                    class="slider-volume"
                                    onChange={handleVolumeChange}
                                  />
                                </div>
                                <span class="font-medium">{volume}</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* {user && ( */}
                      <div className="checkbox-container !pb-0 !mb-0 !border-none">
                        <div className="flex items-center">
                          <input
                            id="short-caption"
                            type="checkbox"
                            class="w-4 h-4 bg-gradient rounded"
                            checked={caption.caption_switch}
                            onChange={handleCheckboxChange}
                          />
                          <label for="short-caption" className="ms-2">
                            Captions
                          </label>
                          {console.log("hiu", user === null ? true : user)}
                        </div>

                        {/* <div className="captions captionsradioboxes">
                            <div className="flex items-center">
                              <span className="text-gray mr-4">
                                Caption color:
                              </span>
                              {colorData.map(({ id, code, name }) => (
                                <div class="form-group">
                                  <>
                                    <input
                                      type="radio"
                                      key={id}
                                      id={`colorradio-${id}`}
                                      name="colorradio"
                                      value={name}
                                      onChange={handleColorChange}
                                    />
                                    <label
                                      for={`colorradio-${id}`}
                                      style={{ "background-color": code }}
                                    ></label>
                                  </>
                                </div>
                              ))}
                            </div>
                          </div> */}
                      </div>
                      {/* )} */}
                      <div class="mt-5">
                        <span
                          class="text-gray block mb-3"
                          style={{ marginBottom: "20px" }}
                        >
                          Caption position:
                        </span>

                        <div class="custom-radio-btn-wrap captionalignradioboxes">
                          {captionAlignData.map(({ id, name }) => (
                            <div class="radio-btn-block">
                              <>
                                <input
                                  type="radio"
                                  className="custom-radio-btn"
                                  key={id}
                                  id={`captionalign-${id}`}
                                  name="captionalign"
                                  checked={caption.caption_align === name}
                                  value={name}
                                  onChange={handleCaptionAlignChange}
                                />
                                <label
                                  className="custom-radio-btn"
                                  id={`captionalignlabel-${id}`}
                                  for={`captionalign-${id}`}
                                >
                                  {name}
                                </label>
                              </>
                            </div>
                          ))}
                          {/* <div class="radio-btn-block">
                            <span class="flex items-center text-red pb-3">
                              <span class="material-symbols-outlined f-18 mr-1">
                                {" "}
                                star{" "}
                              </span>
                              <span>Most Popular</span>
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-footer">
                      <button
                        type="button"
                        className="btn btn-gradient"
                        onClick={navigateToVideo}
                      >
                        Re-render
                      </button>
                    </div>
                    {/* accordion-footer */}
                  </div>
                  {/* accordion-body */}
                </div>
                {/* accordion-block */}
                <div className="formula-tooltip">
                  <a
                    href="#!"
                    className="text-red flex items-center font-medium"
                  >
                    <span className="material-symbols-outlined f-22 mr-2">
                      {" "}
                      help{" "}
                    </span>
                    What you can change inside our editor?
                  </a>
                  <div className="custom-tooltip left">
                    <ul className="list-wrapper text-white">
                      <li className="list-item">
                        <span className="material-symbols-outlined check-icon text-white">
                          {" "}
                          task_alt{" "}
                        </span>
                        <span className="f-14">The script for your video</span>
                      </li>
                      <li className="list-item">
                        <span className="material-symbols-outlined check-icon text-white">
                          {" "}
                          task_alt{" "}
                        </span>
                        <span className="f-14">
                          The images inside your video
                        </span>
                      </li>
                      <li className="list-item">
                        <span className="material-symbols-outlined check-icon text-white">
                          {" "}
                          task_alt{" "}
                        </span>
                        <span className="f-14">
                          The voice and the background music!
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* custom-tooltip */}
                </div>
                {/* <div class="tips-list-view">
        <h5 class="font-bold mb-4 flex items-center f-14"><span class="material-icons text-orange mr-2"> info </span> Inside our editor, you can change:</h5>
        <ul class="list-wrapper">
          <li class="list-item">
            <span class="material-symbols-outlined check-icon"> task_alt </span>
            <span class="f-14">The script for your video</span>
          </li>
          <li class="list-item">
            <span class="material-symbols-outlined check-icon"> task_alt </span>
            <span class="f-14">The images inside your video</span>
          </li>
          <li class="list-item">
            <span class="material-symbols-outlined check-icon"> task_alt </span>
            <span class="f-14">The voice and the background music!</span>
          </li>
        </ul>
      </div> */}
              </div>
              {/* edit-container */}
              <div className="draft-video-container">
                <div className="panel">
                  <div className="panel-head">
                    <h3 style={{ display: "flex" }}>
                      Your original draft{" "}
                      <button
                        type="button"
                        className="btn btn-gray btn-text-icon"
                        style={{
                          padding: "0px 0px 0px 6px",
                          marginLeft: "10px",
                        }}
                        onClick={downloadFile}
                      >
                        <span className="material-icons mr-2"> download </span>
                      </button>
                    </h3>
                  </div>

                  {/* panel-head */}
                  <div className="panel-body">
                    <div className="mb-6">
                      <video
                        key={videoUrl}
                        loop=""
                        // autoPlay
                        className="downloaded-video mx-auto"
                        style={{
                          maxWidth: videoSize == 0 ? "auto" : "200px",
                        }}
                        controls
                      >
                        {" "}
                        <source
                          //   src={require("../../images/short-1.mp4")}
                          src={videoUrl}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="success-info mb-4">
                      <span className="material-icons icon">info</span>
                      Once ready, you can re-render here:
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="button"
                        className="btn btn-gradient btn-text-icon"
                        onClick={navigateToVideo}
                      >
                        {" "}
                        <span className="material-icons f-20 mr-4">
                          {" "}
                          auto_awesome{" "}
                        </span>{" "}
                        Re-render
                      </button>
                    </div>
                  </div>
                  {/* panel-body */}
                </div>
              </div>
              {/* edit-container */}
            </div>
            {/* edit-video-wrap */}
          </div>

          <Footer />
        </div>
      </Layout>
      <Modal
        isOpen={selectedImg !== null}
        imgSrc={selectedImg}
        onClose={() => setSelectedImg(null)}
      />
      <ModalView
        isOpenPop={addAnotherImagePopup}
        increaseWidth={"980px"}
        increaseHeight={
          !isLoading && !showAiScript && stockDataSuccess ? "100%" : false
        }
      >
        <>
          <button
            type="button"
            className="btn-close-icon"
            onClick={() => {
              setIfImgIndex(null);
              setIfImgIndexText(null);
              setAddAnotherImagePopup(false);
              setShowAiScript(false);
              setShowDetails("");
            }}
          >
            <span className="material-icons"> close </span>
          </button>
          {!isLoading && !showAiScript && <h1 className="">Add more files</h1>}
          {!isLoading && !showAiScript && (
            <div className="custom-modal lg overflow-y-auto">
              <div className="mb-5">
                <div className="search-input-wrap mb-6">
                  <form>
                    <input
                      type="text"
                      ref={searchInputRef}
                      // name="keyword"
                      onKeyPress={handleKeyPress}
                      id="insert-script"
                      className="input-control !m-0 imitatefocus"
                      placeholder="Search any topic here..."
                    />
                    <button
                      type="button"
                      className="btn btn-gradient"
                      onClick={() => getStockData()}
                      style={{ cursor: "pointer" }}
                    >
                      Search
                    </button>
                  </form>
                </div>
                {/* <div className="search-input mb-6">
                          <input
                            type="text"
                            ref={searchInputRef}
                            name="keyword"
                            className="input-control mb-0"
                            placeholder="Search here"
                            onKeyPress={handleKeyPress}
                          />
                          <span
                            className="material-icons search-icon"
                            onClick={handleSearch}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            search{" "}
                          </span>
                          <button
                            type="button"
                            className="btn-close-icon"
                            onClick={handleClear}
                          >
                            <span
                              className="material-icons f-18"
                              // style={{ display: "none" }}
                            >
                              {" "}
                              close{" "}
                            </span>
                          </button>
                        </div> */}

                {stockDataSuccess && (
                  <>
                    {" "}
                    <div className="tab-nav mb-5">
                      <button
                        type="button"
                        className={`${isTabOpen === "image" ? "tab-link active" : "tab-link"
                          }`}
                        onClick={() => setIsTabOpen("image")}
                      >
                        Images
                      </button>
                      <button
                        type="button"
                        className={`${isTabOpen === "video" ? "tab-link active" : "tab-link"
                          }`}
                        onClick={() => setIsTabOpen("video")}
                      >
                        Videos
                      </button>
                    </div>
                    {isTabOpen === "image" ? (
                      <>
                        <div
                          className="stock-media-wrap"
                          style={{ position: "relative" }}
                        >
                          {stockImages?.map((item, index) => (
                            <div
                              key={item?.id}
                              style={{ position: "relative" }}
                            >
                              <div
                                key={item?.id}
                                className={`stock-img-block ${selectedContent?.some(
                                  (selectedImage) =>
                                    selectedImage?.id === item?.id
                                )
                                  ? "selected"
                                  : ""
                                  }`}
                                onClick={() => {
                                  toggleItemSelection(item);
                                }}
                              >
                                <div className="animated-border"></div>
                                <img src={item?.link} alt="Generated Image" />
                                <span className="select-circle">
                                  <span className="material-symbols-outlined check-icon f-18">
                                    {" "}
                                    done{" "}
                                  </span>
                                </span>
                                <img
                                  src={require("../../images/image-icon.png")}
                                  alt="Image Icon"
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    left: "5px",
                                    zIndex: 3,
                                    height: "27px",
                                    width: "27px",
                                  }}
                                />
                              </div>
                              {chooseImageTooltip &&
                                chooseImageTooltipid === item.id && (
                                  <div
                                    className="custom-tooltip regenerate-action active"
                                    style={{
                                      position: "absolute",
                                      paddingBottom: 30,
                                      bottom: "10px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }}
                                  >
                                    <div className="text-center">
                                      <div className="flex justify-center">
                                        <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                                          You can choose 8 files.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="vid_class stock-media-wrap"
                          style={{ position: "relative" }}
                        >
                          {stockVideos?.map((item, index) => (
                            <div
                              key={item?.id}
                              style={{ position: "relative" }}
                            >
                              <div
                                key={item?.id}
                                className={`stock-img-block relative ${selectedContent?.some(
                                  (selectedImage) =>
                                    selectedImage?.id === item?.id
                                )
                                  ? "selected"
                                  : ""
                                  }`}
                                onClick={() => {
                                  toggleItemSelection(item);
                                }}
                              >
                                <div className="animated-border"></div>
                                <button
                                  type="button"
                                  className="btn-circle play"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    margin: "auto",
                                    zIndex: 4,
                                    transition: "0.4s",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    padding: "10px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedImg(item?.link);
                                    // setSelectedImg('../../images/index.H.264')
                                  }}
                                >
                                  <span className="material-icons icon">
                                    play_arrow
                                  </span>
                                </button>
                                <video>
                                  <source src={item?.link} type="video/mp4" />
                                </video>
                                <span className="select-circle">
                                  <span className="material-symbols-outlined check-icon f-18">
                                    {" "}
                                    done{" "}
                                  </span>
                                </span>
                                <img
                                  src={require("../../images/video-icon.png")}
                                  alt="Video Icon"
                                  style={{
                                    position: "absolute",
                                    top: "4px",
                                    left: "1px",
                                    zIndex: 3,
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>
                              {chooseImageTooltip &&
                                chooseImageTooltipid === item.id && (
                                  <div
                                    className="custom-tooltip regenerate-action active"
                                    style={{
                                      position: "absolute",
                                      paddingBottom: 30,
                                      bottom: "0px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }}
                                  >
                                    <div className="text-center">
                                      <div className="flex justify-center">
                                        <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                                          You can choose 8 files.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {stockDataSuccess && (
                <div className="stock-image-footer border-light-top pt-3">
                  <span>
                    <span className="font-semibold">
                      {selectedContent?.length} selected
                    </span>{" "}
                    (You can choose{" "}
                    {8 - (items.length + selectedContent?.length)} more files)
                  </span>
                  <button
                    ref={addTovideoRef}
                    type="button"
                    className="btn btn-gradient relative"
                    // onClick={() => setShowStockScreen(false)}
                    onClick={() => {
                      if (selectedContent?.length == 0) {
                        setIsSelectedEmpty(true);
                        return;
                      }
                      if (ifImgIndex) {
                        setUploadLoader(ifImgIndex - 1);
                        console.log("ifImgIndex", ifImgIndex);
                        const updatedImages = [...items];
                        updatedImages.splice(
                          ifImgIndex - 1,
                          1,
                          ...selectedContent
                        );
                        setitems(updatedImages);
                        localStorage.setItem(
                          "all_images",
                          JSON.stringify(updatedImages)
                        );
                        setSelectedContent([]);
                      } else {
                        setitems([...items, ...selectedContent]);
                        localStorage.setItem(
                          "all_images",
                          JSON.stringify([...items, ...selectedContent])
                        );
                      }
                      setIfImgIndex(null);
                      setIfImgIndexText(null);
                      setUploadAddProgress(null);
                      setAddAnotherImagePopup(false);
                      setSelectedContent([]);
                      setIsEdit(true);
                      // setIsTabOpen('image')
                    }}
                  >
                    Add to my video
                    {isSelectedEmpty && (
                      <div
                        className="custom-tooltip regenerate-action active"
                        style={{ width: "180px" }}
                      >
                        <div className="text-center">
                          <div className="flex justify-center mt-2 mb-4">
                            <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                              <span className="material-symbols-outlined mr-1 f-18">
                                info
                              </span>{" "}
                              Please choose atleast 1 image or video first.
                              Thanks!
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              )}

              <div className="or-divider full">
                <span>OR</span>
              </div>

              <div className="selector-block-wrap compact">
                <div className="selector-block" onClick={openAnotherFilePicker}>
                  <img
                    src={
                      require("../../images/upload-your-own-img.svg").default
                    }
                    alt="Vector"
                  />
                  <h5>Upload your own</h5>
                </div>

                {/* <div className="selector-block" onClick={() => {
                            setShowAiScript(true);
                            setSelectedContent([])
                            setStockImages([])
                            setStockVideos([])
                            setStockDataSuccess(false)
                        }}>
                          <img
                            src={require("../../images/choose-from-ai-img.svg").default}
                            alt="Vector"
                          />
                          <h5>Generate from AI</h5>
                        </div> */}
                <div
                  className="selector-block prmeium-feature-block bg-light-gradient"
                  onClick={() => {
                    //if(user){
                    setShowAiScript(true);
                    setSelectedContent([]);
                    setStockImages([]);
                    setStockVideos([]);
                    setStockDataSuccess(false);
                    //}
                  }}
                >
                  {/*<span className="prmeium-feature-tag">
                  <img src={require("../../images/premium.svg").default} alt="premium"/>
                  <span>Only with Premium</span>
                      </span>*/}

                  <img
                    src={require("../../images/choose-from-ai-img.svg").default}
                    alt="Vector"
                  />
                  <h5 className="flex items-center">
                    Generate from AI
                    <span className="text-black flex justify-center items-center">
                      <span className="material-symbols-outlined f-24 ml-2">
                        {" "}
                        info{" "}
                      </span>
                    </span>
                  </h5>
                </div>
                {/* selector-block */}
              </div>
            </div>
            // <form className="creator-form mt-4">
            //   <div className="selector-block-wrap flex-col mb-6">
            //     <div className="selector-block-wrap mb-6">
            //       <div
            //         className="selector-block"
            //         onClick={openAnotherFilePicker}
            //       >
            //         <img
            //           className="mb-2"
            //           src={
            //             require("../../images/upload-your-own-img.svg").default
            //           }
            //           alt="Vector"
            //         />
            //         <h5>Upload your own</h5>

            //         <button
            //           type="button"
            //           className="btn btn-flat btn-text-icon text-red"
            //           onClick={(e) => {
            //             e.stopPropagation();
            //             showDetails !== "uploadDetails"
            //               ? setShowDetails("uploadDetails")
            //               : setShowDetails("");
            //           }}
            //         >
            //           {showDetails === "uploadDetails"
            //             ? "Hide details"
            //             : "Learn more"}{" "}
            //           <span className="material-symbols-outlined f-20 ml-2 arrow">
            //             {" "}
            //             {showDetails === "uploadDetails"
            //               ? "arrow_upward"
            //               : "arrow_downward"}{" "}
            //           </span>
            //         </button>

            //         <div
            //           className={`learn-more-text mt-4 ${
            //             showDetails === "uploadDetails" ? "show" : ""
            //           }`}
            //         >
            //           <h6 className="mb-4">Perfect for:</h6>
            //           <ul className="list-wrapper text-left">
            //             <li className="list-item">
            //               <span className="material-symbols-outlined check-icon">
            //                 task_alt
            //               </span>{" "}
            //               <span>
            //                 <span className="f-14 font-normal">
            //                   <span className="font-bold">Businesses</span> to
            //                   show their product
            //                 </span>
            //               </span>
            //             </li>
            //             <li className="list-item">
            //               <span className="material-symbols-outlined check-icon">
            //                 task_alt
            //               </span>{" "}
            //               <span>
            //                 <span className="f-14 font-normal">
            //                   Making super personalized videos
            //                 </span>
            //               </span>
            //             </li>
            //             <li className="list-item">
            //               <span className="material-symbols-outlined check-icon">
            //                 task_alt
            //               </span>{" "}
            //               <span>
            //                 <span className="f-14 font-normal">
            //                   Adding your{" "}
            //                   <span className="font-bold">
            //                     family and friends
            //                   </span>
            //                 </span>
            //               </span>
            //             </li>
            //           </ul>
            //         </div>
            //         {/* <input
            //                 className="btn btn-gray btn-sm"
            //                 type="file"
            //                 id='anotherfileInput'
            //                 onChange={(event) =>
            //                   handleAddFileChange(event)
            //                 }
            //                 style={{ display: "none" }}
            //               /> */}
            //       </div>

            //       <div
            //         className="selector-block"
            //         onClick={() => {
            //           setAddAnotherImagePopup(false);
            //           setShowStockDataPopup(true);
            //         }}
            //       >
            //         <img
            //           className="mb-2"
            //           src={
            //             require("../../images/choose-from-stock-img.svg")
            //               .default
            //           }
            //           alt="Vector"
            //         />
            //         <h5 className="mb-4">Choose cool stock videos</h5>

            //         <button
            //           type="button"
            //           className="btn btn-flat btn-text-icon text-red"
            //           onClick={(e) => {
            //             e.stopPropagation();
            //             showDetails !== "stockDetails"
            //               ? setShowDetails("stockDetails")
            //               : setShowDetails("");
            //           }}
            //         >
            //           {showDetails === "stockDetails"
            //             ? "Hide details"
            //             : "Learn more"}{" "}
            //           <span className="material-symbols-outlined f-20 ml-2 arrow">
            //             {" "}
            //             {showDetails === "stockDetails"
            //               ? "arrow_upward"
            //               : "arrow_downward"}{" "}
            //           </span>
            //         </button>

            //         <div
            //           className={`learn-more-text mt-4 ${
            //             showDetails === "stockDetails" ? "show" : ""
            //           }`}
            //         >
            //           <h6 className="mb-3">Perfect for:</h6>
            //           <ul className="list-wrapper text-left">
            //             <li className="list-item">
            //               <span className="material-symbols-outlined check-icon">
            //                 task_alt
            //               </span>{" "}
            //               <span>
            //                 <span className="f-14 font-normal">
            //                   <span className="font-bold">Businesses</span> to
            //                   show their product
            //                 </span>
            //               </span>
            //             </li>
            //             <li className="list-item">
            //               <span className="material-symbols-outlined check-icon">
            //                 task_alt
            //               </span>{" "}
            //               <span>
            //                 <span className="f-14 font-normal">
            //                   Making super personalized videos
            //                 </span>
            //               </span>
            //             </li>
            //             <li className="list-item">
            //               <span className="material-symbols-outlined check-icon">
            //                 task_alt
            //               </span>{" "}
            //               <span>
            //                 <span className="f-14 font-normal">
            //                   Adding your{" "}
            //                   <span className="font-bold">
            //                     family and friends
            //                   </span>
            //                 </span>
            //               </span>
            //             </li>
            //           </ul>
            //         </div>
            //       </div>
            //     </div>

            //     <div
            //       className="selector-block"
            //       onClick={() => {
            //         // setAddAnotherImagePopup(false)
            //         setShowAiScript(true);
            //         // if (ifImgIndex) {

            //         //   handleReSingleAiImageGenetrate()

            //         // } else {
            //         // handleSingleAiImageGenetrate()
            //         // }

            //       }}
            //     >
            //       <img
            //         className="mb-2"
            //         src={require("../../images/choose-from-ai-img.svg").default}
            //         alt="Vector"
            //       />
            //       <h5>Generate from AI</h5>

            //       <button
            //         type="button"
            //         className="btn btn-flat btn-text-icon text-red"
            //         onClick={(e) => {
            //           e.stopPropagation();
            //           showDetails !== "aiDetails"
            //             ? setShowDetails("aiDetails")
            //             : setShowDetails("");
            //         }}
            //       >
            //         {showDetails === "aiDetails"
            //           ? "Hide details"
            //           : "Learn more"}{" "}
            //         <span className="material-symbols-outlined f-20 ml-2 arrow">
            //           {" "}
            //           {showDetails === "aiDetails"
            //             ? "arrow_upward"
            //             : "arrow_downward"}{" "}
            //         </span>
            //       </button>

            //       <div
            //         className={`learn-more-text mt-4 ${
            //           showDetails === "aiDetails" ? "show" : ""
            //         }`}
            //       >
            //         <h6 className="mb-3">Perfect for:</h6>
            //         <ul className="list-wrapper text-left">
            //           <li className="list-item">
            //             <span className="material-symbols-outlined check-icon">
            //               task_alt
            //             </span>{" "}
            //             <span>
            //               <span className="f-14 font-normal">
            //                 <span className="font-bold">
            //                   Use for science fiction/cartoon types of videos
            //                 </span>
            //               </span>
            //             </span>
            //           </li>
            //         </ul>
            //       </div>
            //     </div>
            //   </div>
            // </form>
          )}
          {showAiScript && !isLoading && (
            <form className="creator-form" onSubmit={handlePromptformSubmit}>
              <div className="mb-6">
                <label for="insert-script" className="label mb-2">
                  Describe your image how you want it
                  <br />
                  {/* <span className="f-13 text-gray">
               This script will be said inside your AI video. You can edit it
                below before continuing.
      
              </span> */}
                </label>

                <div className="ai-script-card bg-light-gray p-3 border-light rounded">
                  {/* <div className="flex items-center justify-center mb-2">
                <span className="material-symbols-outlined f-24 gradient-text mr-2">
                  {" "}
                  task_alt{" "}
                </span>
                <span className="f-14 font-bold">Read it below:</span>
              </div> */}
                  <textarea
                    id="insert-script"
                    name="aiText"
                    // value={aiText}
                    ref={textareaRef}
                    rows="4"
                    className="input-control white border-light"
                    placeholder="Write description..."
                  // onChange={(e) => {
                  //   e.stopPropagation();
                  //   setAiText(e.target.value )
                  //   }
                  // }
                  ></textarea>
                </div>
              </div>

              <div className="loaderbtnscript">
                <button
                  type="submit"
                  className="btn btn-gradient"
                // disabled={aiText ? false : true}
                // onClick={() => {
                //   setAddAnotherImagePopup(false)
                //   setShowAiScript(false)
                //   handleSingleAiImageGenetrate()
                // }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          {isLoading && !showAiScript && (
            <div className="choose-img-inputs ai-generated-img-loader">
              <div className="w-full ai-generated-img-inner">
                <div className="flex justify-center p-4 pb-8">
                  <div className="spinner-box">
                    <div className="configure-border-1">
                      <div className="configure-core"></div>
                    </div>
                    <div className="configure-border-2">
                      <div className="configure-core"></div>
                    </div>
                  </div>
                </div>
                <p className="font-bold mb-3 text-center">
                  We're creating 1 cool AI image for your video. Give us 20
                  seconds
                </p>

                <div className="flex items-center w-full">
                  <div className="progress-bar mr-4">
                    <div
                      className="progress"
                      style={{ width: `${uploadCustomProgress}%` }}
                    ></div>
                  </div>
                  <h4 className="">{uploadCustomProgress.toFixed(0)}%</h4>
                </div>
              </div>
            </div>
          )}
        </>
      </ModalView>
      <ModalView isOpenPop={isModalPopOpen} increaseWidth={"680px"}>
        <>
          {/* <div className="custom-modal md"> */}
          <div id="tsparticles" />
          <div className="text-center mb-6">
            <img
              className="mx-auto mb-4"
              src={require("../../images/check-gradient.svg").default}
              alt="Check"
            />
            <h2 className="mb-2">AI has created your video! Review it now:</h2>
            <p className="mb-0">
              <span className="font-bold">Important:</span> Open our popular
              editor to customize it yourself
            </p>
          </div>
          <div className="mb-6">
            <video
              loop=""
              autoPlay=""
              className="downloaded-video mx-auto"
              style={{ maxWidth: videoSize == 0 ? "auto" : "200px" }}
              controls
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className="btn btn-gradient btn-text-icon"
              onClick={closePopModal}
            >
              <span className="material-icons mr-2"> edit </span> Open editor
            </button>
          </div>
          {/* </div> */}
        </>
      </ModalView>
      <ModalView isOpenPop={editPopup}>
        <>
          <div>
            <div class="head text-center">
              <img
                className="logo mx-auto mb-4"
                src="images/check-gradient.svg"
                alt="Check"
              />
              <h2 class="text-red mb-2" style={{ textAlign: "center" }}>
                Edit your video before re-rendering
              </h2>
            </div>

            <div class="body">
              <div
                class="mb-0"
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  class="btn btn-gradient"
                  onClick={() => setEditPopup(false)}
                >
                  Got it!
                </button>
              </div>
            </div>
          </div>
        </>
      </ModalView>
      <ModalView isOpenPop={videoLoading}>
        <>
          <div>
            <>
              <div className="mb-6">
                <img
                  src={require("../../images/logo.png")}
                  className="mx-auto"
                  style={{ height: 60 }}
                  alt="Yatter AI"
                />
              </div>
              <h3 className="mb-6 text-center">
                Your customized video is almost ready...
              </h3>
              <div className="flex items-center w-full mb-4">
                <div className="progress-bar mr-4">
                  <div
                    className="progress"
                    style={{ width: `${countdown}%` }}
                  />
                </div>
                <h4>{countdown.toFixed(0)}%</h4>
              </div>

              <div class="flex items-center justify-center mb-6">
                <span class="text-gray mr-1">Estimated time:</span>
                {timeRemaining !== null && (
                  <span class="text-black font-semibold">{timeRemaining}</span>
                )}
              </div>

              <div className="danger-info">
                <span className="material-icons icon">info</span>
                Do not close this tab! You will lose your new video forever!
              </div>
              <div
                className="flex justify-center mt-4"
                onClick={() => {
                  if (cancelToken) {
                    cancelToken.cancel(); // Cancel the ongoing API request
                  }
                  setVideoLoading(false);
                  clearInterval(intervalRef.current);
                  setCountdown(0);
                  setTimeRemaining("0:00");
                }}
              >
                <button type="button" className="btn btn-gradient">
                  Cancel
                </button>
              </div>
            </>
          </div>
        </>
      </ModalView>
      <ModalView isOpenPop={videoDownloadPopup} increaseWidth={"680px"}>
        <>
          {/* <div className="custom-modal md"> */}
          {/* <div id="tsparticles" /> */}
          <div className="text-center mb-6">
            <img
              className="mx-auto mb-4"
              src={require("../../images/check-gradient.svg").default}
              alt="Check"
            />
            <h2 className="mb-2">Your new video is ready! Export it now:</h2>
            <p className="mb-0">
              Your new video has been personalized in a matter of minutes!
            </p>
          </div>
          <div className="mb-6">
            <video
              className="downloaded-video mx-auto"
              style={{ maxWidth: videoSize == 0 ? "auto" : "200px" }}
              controls
            >
              {/* <source src={require("../../images/short-1.mp4")} type="video/mp4" /> */}
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className="btn btn-gray btn-text-icon"
              onClick={downloadFile}
            >
              <span className="material-icons mr-2"> download </span> Download
              now
            </button>
          </div>

          {/* </div> */}
        </>
      </ModalView>
      <FreeTrialPopup
        isOpenPop={isFreeTrialPopupOpen}
        // isOpenPop={true}
        setIsOpenPop={setIsFreeTrialPopupOpen}
        videoUrl={videoUrl}
      // setIsFreeTrialSuccessPopupOpen={setIsFreeTrialSuccessPopupOpen}
      />
    </>
  );
};

export default Rerender;
