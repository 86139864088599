import React, { createContext, useState, useEffect } from "react";
import { VideoGenerationContext } from "./VideoGenerationContext.js";
import { getUSerVideos } from "./Api/getUserVideo/getUserVideo.js";
import { toast } from "react-toastify";

export const VideoGenerationProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [videoGenerations, setVideoGenerations] = useState({});
  const [isGenerating, setIsGenerating] = useState({}); // Track active generation flags
  const [initialVideos, setInitialVideos] = useState([]);
  const [videosData, setVideosData] = useState({});
  const [loading, setLoading] = useState(false);
  const resetContext = () => {
    setVideoGenerations({});
    setIsGenerating({});
    setInitialVideos([]);
    setVideosData({});
    setLoading(false);
  };
  const updateInitialVideos = (newVideos) => {
    setInitialVideos([...newVideos]); // Create a new array with the updated videos
  };
  const updateVideoProgress = (videoId, progress, timeRemaining) => {
    setVideoGenerations((prev) => ({
      ...prev,
      [videoId]: {
        ...prev[videoId],
        progress,
        timeRemaining,
      },
    }));
  };
  const clearIntervalForVideo = (videoId) => {
    if (intervalIds[videoId]) {
      clearInterval(intervalIds[videoId]);
      delete intervalIds[videoId]; // Remove from interval storage
      updateVideoProgress(videoId, 100, "00:00");
    }
  };
  // No API call here (removed from initiateVideoGeneration)
  const fetchVideos = async () => {
    try {
      const data = await getUSerVideos({ user_id: user?.ID });
      if (data?.data) {
        const reversedVideoPaths = Object.values(data?.data).reverse();
        console.log({ userVideos: data?.data, reversedVideoPaths });
        setVideosData(reversedVideoPaths);
        if (reversedVideoPaths?.length > 4) {
          setLoading(true);
        }
      } else {
        // navigate("/");
        console.log({ error: data });
      }
    } catch (error) {
      toast.error("Error fetching videos:", error);
      console.log({ error });
    }
  };

  useEffect(() => {
    user && fetchVideos();
  }, [user]);

  const initiateVideoGeneration = (videoId) => {
    setIsGenerating((prev) => ({ ...prev, [videoId]: true }));
    console.log(
      "videoID",
      videoId,
      "videoGenerations[videoId]",
      videoGenerations[videoId]
    );
    // Create interval here (conditional ensures only one per video)
    // if (!isGenerating[videoId]) {
    const totalDuration = 120000;
    let elapsedTime = 0;
    const intervalId = setInterval(() => {
      // Simulate progress update (replace with logic to fetch progress from your server)
      // const newProgress = videoGenerations[videoId].progress + 5; // Adjust increment as needed
      console.log("videoId intervel", videoId, videoGenerations )
      elapsedTime += 1000;
      const calculatedProgress = (elapsedTime / totalDuration) * 100;
      const newTimeRemaining =
        totalDuration - calculatedProgress * (totalDuration / 100);
      updateVideoProgress(videoId, calculatedProgress, newTimeRemaining);

      if (calculatedProgress === 100) {
        // Check for completion
        clearInterval(intervalId);
        setIsGenerating((prev) => ({ ...prev, [videoId]: false }));
      }
    }, 1500);
    intervalIds[videoId] = intervalId;
    // }
  };
  const intervalIds = {};

  return (
    <VideoGenerationContext.Provider
      value={{
        videoGenerations,
        setVideoGenerations,
        isGenerating,
        initiateVideoGeneration,
        clearIntervalForVideo,
        initiateVideoGeneration,
        initialVideos,
        setInitialVideos,
        videosData,
        setVideosData,
        loading,
        setLoading,
        resetContext,
        setUser,
        updateInitialVideos,
        fetchVideos,
      }}
    >
      {children}
    </VideoGenerationContext.Provider>
  );
};
