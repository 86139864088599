import React from 'react'

const oneDayPopup = ({ onClick, onPayment }) => {

    const days = localStorage.getItem('days')

    return (
        // <div >
        <div class="custom-modal success-modal md">
            <button type="button" class="btn-close-icon" onClick={onClick}><span class="material-icons"> close </span></button>
            <div>
                <div class="text-center mb-6">
                    <h1 class="mb-4">Your free trial expires in {days} days</h1>
                    <h4 class="font-medium mb-4">You will lose access to all current and future videos.</h4>
                </div>

                <div class="text-center">
                    <h4 class="font-medium mb-4">Upgrade for only <strike>$14.99</strike> <span class="font-semibold">$9.99/month</span></h4>
                </div>

                <div class="text-center mb-7" style={{ display: "flex", justifyContent: "center" }}>
                    <button type="button" class="btn btn-gradient premium-btn" style={{ display: "flex", alignItems: "center" }} onClick={onPayment}>
                        <span class="icon"><img src="images/premium.svg" alt="Premium" style={{ width: 30, height: 30 }} /></span>
                        Upgrade in 1 minute
                    </button>
                </div>

                <div class="p-4 bg-light-gradient mb-6 rounded-xl">
                    <h5 class="f-18 mb-4">Premium offers the easiest way to make videos:</h5>
                    <ul class="list-wrapper text-left">
                        <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Make beautiful videos <span class="font-bold">without any watermarks</span></span></li>
                        <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Access <span class="font-bold">unlimited AI images</span></span></li>
                        <li class="list-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Make quick videos <span class="font-bold">without years of experience</span></span></li>
                    </ul>
                </div>

                <div class="text-center f-14">
                    Any questions? Send us an email at <a class="font-semibold" href="mailto:info@yatter.ai">info@yatter.ai</a>
                </div>
            </div>
        </div >
    )
}

export default oneDayPopup