import React, { useContext, useEffect, useState } from "react";
import { signup } from "../../Api/Auth/signup";
import { login } from "../../Api/Auth/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { googleAuth } from "../../Api/googleAuth/googleAuth";
import { useGoogleLogin } from "@react-oauth/google";
import { loginWithGoogle } from "../../Api/googleAuth/loginWithGoogle";
import { cursortowait, cursortodefault } from "../../services/chatGPTService";
import mixpanel from "mixpanel-browser";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import { VideoGenerationContext } from "../../VideoGenerationContext";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

export default function Signup() {
  const navigate = useNavigate();
  const { setUser } = useContext(VideoGenerationContext);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
    // phone: "",
  });
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: "customer",
    // phone: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [user, setUser] = useState([]);
  // const [profile, setProfile] = useState([]);
  // console.log("Google User::", profile);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = async (event) => {
    setSelectedOption(event.target.value);
    setFormErrors({
      ...formErrors,
      selectedOption: "",
    });
  };

  function navigateAndRefresh() {
    navigate("/login");
    window.location.reload();
  }

  // Event handler for form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (formData.username.trim() === "") {
      errors.username = "Email is required.";
    }
    // if (formData.phone.trim() === "") {
    //   errors.phone = "Phone number is required.";
    // }

    if (formData.password.trim() === "") {
      errors.password = "Password is required.";
    }

    if (selectedOption.trim() === "") {
      errors.selectedOption = "Please select where you heard about us";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      // Call the signup function with the form data
      cursortowait();
      const response = await signup(formData);
      // Handle successful signup (you can redirect to login page or show a success message)
      console.log("Signup successful:", response);
      if (response?.message === "User already exists.") {
        toast.error("User already exists.");
      } else if (response?.data) {
        document.body.style.cursor = "default";
        toast.success("User registered successfully");
        //navigate("/login");
        const response = await login(formData);
        console.log("response", response);
        if (response?.data?.data) {
          await mixpanel.identify(response?.data?.data.ID);
          await mixpanel.people.set({
            $email: response?.data?.data?.user_email,
            $name: response?.data?.data?.user_email,
            "User Id": response?.data?.data.ID,
            Referred: selectedOption,
          });
          await mixpanel.track("Sign Up", {
            // Email: response?.data?.data.user_email,
            "Sign Up Platform": "Website Form",
            "User Id": response?.data?.data.ID,
            Referred: selectedOption,
          });
          const userData = { ...response.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          localStorage.removeItem("video_id");
          localStorage.removeItem("video_url");
          await getUSerVideoCountHandle({ userid: userData?.ID });
          setTimeout(() => {
            navigate("/dashboard");
            toast.success("Login success");
            console.log("Login success");
            cursortodefault();
          }, 2000);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const googleSignup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      cursortowait();
      console.log({ codeResponse });
      // if (selectedOption.trim() === "") {
      //   return toast.error("Please select where you heard about us");
      // }
      const data = await googleAuth(codeResponse);
      if (data && data?.email) {
        console.log({ profile: data });
        const body = { email: data?.email, name: data?.name };
        const response = await loginWithGoogle(body);
        if (response?.data?.data) {
          const userData = { ...response.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          await mixpanel.identify(userData?.data?.ID);
          // await mixpanel.getPeople().set('Email', userData?.data?.user_email);
          await mixpanel.people.set({
            $email: userData?.data?.user_email,
            $name: userData?.data?.user_email,
            "User Id": userData?.data?.ID,
          });
          await mixpanel.track("Sign Up", {
            Email: userData?.data?.user_email,
            "Sign Up Platform": "Google",
            "User Id": userData?.data?.ID,
          });
          await getUSerVideoCountHandle({ userid: userData?.ID });
          setTimeout(() => {
            navigate("/dashboard");
            toast.success("Login success");
            console.log("Login success");
            cursortodefault();
          }, 2000);
        } else if (response?.data?.error) {
          //toast.error(response?.data?.error);
          toast.error("Something went wrong.");
          console.log({ error: response });
        }
      }
    },
    onError: (error) => toast.error(error),
  });

  // useEffect(() => {
  //   const googleUserAuth = async () => {
  //     try {
  //     } catch (error) {
  //       toast.error(error);
  //     }
  //   };
  //   googleUserAuth();
  // }, []);
  // Event handler to update form data when input fields change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };
  return (
    <div className="authentication-wrap">
      {/* <div className="mb-6 text-center">
        <img
          className="logo mx-auto"
          src="../../images/logo.png"
          alt="Yatter"
        />
      </div> */}
      <div className="mb-6 text-center">
        <img
          className="logo mx-auto"
          src="../../images/logo.png"
          alt="Yatter"
        />
      </div>

      <div className="authentication-block">
        <video className="video-carousel" autoPlay muted loop>
          <source
            src={require("../../images/yatter-video-carousel-3.mp4")}
            type="video/mp4"
          />
        </video>
        <div className="authentication-card text-center">
          <div className="head">
            <h1 className="text-red uppercase">Signup</h1>
            <h4>
              Sign Up to create professional quality videos under 3 mins.
              Guaranteed.
            </h4>
          </div>

          <div className="body">
            <div>
              <button
                type="button"
                className="btn btn-black-ouline btn-text-icon w-full rounded-btn"
                onClick={() => googleSignup()}
                // disabled
              >
                <img
                  className="mr-2"
                  src={require("../../images/google.svg").default}
                  alt="Google"
                />{" "}
                Sign up with Google
              </button>
            </div>

            <div className="or-divider">
              <span>OR</span>
            </div>

            <form action="#">
              <div className="input-group">
                <input
                  type="email"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  className="input-control"
                  placeholder="Enter your email"
                  required
                />
                {formErrors.username && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-1.25rem",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {formErrors.username}
                  </p>
                )}
              </div>
              {/* <div className="input-group">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="input-control"
                  placeholder="Enter your phone number"
                  required
                />
                {formErrors.phone && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-1.25rem",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {formErrors.phone}
                  </p>
                )}
              </div> */}
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="input-control"
                  placeholder="Enter your password"
                  required
                />
                {formErrors.password && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "-1.25rem",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {formErrors.password}
                  </p>
                )}
                <button
                  type="button"
                  className="btn-icon show-pwd"
                  onClick={togglePasswordVisibility}
                >
                  <span className="material-icons text-gray f-20">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              </div>

              <div className="input-group text-left">
                <label className="f-14 font-bold mb-2 inline-block">
                  Where did you hear about us?
                </label>
                <div className="">
                  <div className="flex items-center">
                    <input
                      id="google-ads"
                      type="radio"
                      value="google-ads"
                      name="source"
                      className="w-4 h-4 bg-gradient rounded"
                      checked={selectedOption === "google-ads"}
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="google-ads" className="ms-2">
                      Inside Google Ads
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="friend-colleague"
                      type="radio"
                      value="friend-colleague"
                      name="source"
                      className="w-4 h-4 bg-gradient rounded"
                      checked={selectedOption === "friend-colleague"}
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="friend-colleague" className="ms-2">
                      From a friend or colleague
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="youtube-shorts"
                      type="radio"
                      value="youtube-shorts"
                      name="source"
                      className="w-4 h-4 bg-gradient rounded"
                      checked={selectedOption === "youtube-shorts"}
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="youtube-shorts" className="ms-2">
                      Inside YouTube shorts
                    </label>
                  </div>
                </div>
              </div>

              {formErrors?.selectedOption && (
                <p
                  style={{
                    color: "red",
                    marginTop: "-1.25rem",
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {formErrors.selectedOption}
                </p>
              )}

              <div className="text-center mb-5">
                <p className="f-14 font-medium">
                  By registering you agree to our{" "}
                  <a
                    href="https://yatter.ai/privacy-policy/"
                    target="_blank"
                    className="font-bold text-red"
                  >
                    privacy policy
                  </a>
                  .
                </p>
              </div>

              <div className="input-group mb-0">
                <button
                  type="button"
                  className="btn btn-gradient"
                  onClick={handleFormSubmit}
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="text-center">
        <p className="f-18 mb-0 font-medium">
          Already have an account?{" "}
          <button className="font-bold text-red" onClick={navigateAndRefresh}>
            Login
          </button>
        </p>
      </div>

      <div className="perks-wrap">
        <div className="perk-block">
          <span className="material-icons icon gradient-text f-24 mr-2">
            {" "}
            thumb_up_alt{" "}
          </span>
          <span>
            Selected by hundreds of{" "}
            <span className="font-bold">video experts</span>
          </span>
        </div>
        <div className="perk-block">
          <span className="material-icons icon gradient-text f-24 mr-2">
            {" "}
            star{" "}
          </span>
          <span>
            <span className="font-bold">4.9 stars</span> based on 1,309 reviews
          </span>
        </div>
      </div>
    </div>
  );
}
