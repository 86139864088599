import React from 'react'
import Layout from '../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom';

const GetNewImages = () => {

    const navigate = useNavigate();
    const getformData = JSON.parse(localStorage.getItem("getFormData"));
    const subformData = getformData?.subformData
    // console.log('new_image', getformData);
    // console.log('new_image1', subformData);

    const navigateToImage = () => {
        navigate("/get-images", {
            state: {
                subFormData: subformData,
                // call: true,
            },
        });
        localStorage.setItem("generateVideo", true);
    }
    const handleGenerate = () => {
        navigate("/dashboard", {
            state: {
                subFormData: subformData,
                call: true,
            },
        });
        localStorage.setItem("generateVideo", true);
    }


    return (
        <Layout HeaderName="Make An AI Video">
            <div class="page-container">
                <div class="page-wrapper">

                    <div class="container-xl mb-8">
                        <div class="ai-icon-animation mx-auto mb-2">
                            <img class="ai-icon-1" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                            <img class="ai-icon-2" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                            <img class="ai-icon-3" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                            <img class="ai-icon-4" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                        </div>

                        <h4 class="text-center mb-8">Want to include your own images/videos?</h4>

                        <div class="container-sm">
                            <div class="selector-block-wrap">

                                <div class="card-wrap selector-block" onClick={navigateToImage}>
                                    <img class="mb-2" src={require('../../images/upload-your-own-img.svg').default} alt="Vector" />
                                    <h5>Upload your own</h5>
                                </div>

                                <div class="card-wrap selector-block" onClick={() => handleGenerate()}>
                                    <img class="mb-2" src={require("../../images/choose-from-ai-img.svg").default} alt="Vector" />
                                    <h5>Generate from AI</h5>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default GetNewImages