export const Footer = ({style}) => {
    return (
      <footer style={style}>
        <div className="footer-wrap">
          <div className="left">
            <p>© 2024 Yatter. All rights reserved</p>
          </div>
        </div>
      </footer>
    );
  };